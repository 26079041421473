<template>
<div>
      <v-toolbar dense height="28" flat color="blue-grey lighten-5">
      <v-toolbar-title><v-icon class="mr-2">ballot</v-icon>Nowe szkolenie</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>

    </v-toolbar>
<v-layout align-center>
    <v-flex md4 xs12 sm6>

    <v-card>
    <v-card-text class="mt-3">
 <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="name"
      :rules="nameRules"
      :counter="50"
      label="Nazwa szkolenia"
      required
    ></v-text-field>
    <v-text-field
      v-model="scope"
      :rules="scopeRules"
      label="Zakres szkolenia"
      required
    ></v-text-field>
    <v-btn
        class="success"
      :disabled="!valid"
      @click="submit"
    >
      Dodaj szkolenie
    </v-btn>
    <v-btn to="/courses" class="error">Anuluj</v-btn>
  </v-form>
</v-card-text>
    </v-card>

    </v-flex>
</v-layout>
</div>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    valid: true,

    name: '',
    nameRules: [
      v => !!v || 'Podaj nazwę szkolenia',
      v => (v && v.length >= 5) || 'Nazwa szkolenia powinna mieć minimum 5 znaków'
    ],
    scope: '',
    scopeRules: [
      v => !!v || 'Podaj zakres szkolenia',
      v => (v && v.length >= 5) || 'Nazwa szkolenia powinna mieć minimum 5 znaków'
    ],
    checkbox: false
  }),

  methods: {
    submit () {
      if (this.$refs.form.validate()) {
        axios({
          url: this.$ROOT_PATH + '/courses/new',
          method: 'post',
          data: {
            name: this.name,
            scope: this.scope
          },
          headers: {
            'X-API-Token': this.$store.state.userCredentials.token
          }
        })
          .then(response => {
            this.$router.push('/courses')
          })
          .catch(error => {
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          })
          .then(() => {

          })
      }
    }
  }
}
</script>
