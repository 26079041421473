<template>
  <div>
    <v-layout v-show="true">
      <v-flex>
  <v-toolbar dense height="28" flat color="blue-grey lighten-5">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip>
          <v-icon>supervised_user_circle</v-icon> Lista użytkowników systemu</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>

      <v-tooltip left>
        <v-btn class="success" @click="usersAdd.show=true" icon slot="activator"><v-icon>add_circle</v-icon></v-btn>
        <span>Dodaj nowego użytkownika</span>
      </v-tooltip>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="clientsItems"
      :loading="loadingItems"
      :rows-per-page-items="[10,50,100]"
      rows-per-page-text="Liczba wierszy na stronę"
      class="elevation-1 mt-3"
    >
      <template slot="items" slot-scope="props">
        <td class="text-xs-left body-2"><strong> {{ props.item.name }} </strong> {{ props.item.isadmin ? '(admin)':''}}  <!--router-link :to="'/users/edit/'+ props.item.id " tag="a">{{ props.item.name }}</router-link--></td>
      <td class="text-md-right layout px-0">
          <v-btn icon color="red" small dark class="mr-0" @click="deleteUserShowDlg(props.item.id)" v-if="clientsItems.length > 1 && (!props.item.isadmin || (props.item.isadmin && clientsItems.filter((c) => c.isadmin).length > 1))" ><v-icon dark small>delete</v-icon></v-btn>
          <!--v-btn flat icon small @click="alert('Funkcja tymczasowo zablokowana.')"><v-icon flat small color="grey ">delete</v-icon></v-btn--></td>
        <td class="text-xs-left table-row-nowrap"><v-icon small class="mr-2">email</v-icon> <a :href="'mailto:'+ props.item.email ">{{ props.item.email }}</a></td>
        <td><v-icon class="mr-2" small>contact_phone</v-icon>  {{ props.item.phone }}</td>
        <td>{{ props.item.created_at }}</td>

      </template>
      <template slot="no-data">
      {{ this.loadingItemsText }}
      </template>
      <template slot="pageText" slot-scope="props">
         {{ props.pageStart }} - {{ props.pageStop }} z {{ props.itemsLength }}
      </template>
    </v-data-table>

      <v-card>
        <v-card-text>
              <v-data-table
      :items="history"
      hide-actions
      hide-headers
      item-key="login_history_id"
      style="max-width:800px;"
    >
      <template slot="items" slot-scope="props">
             <td>
        {{props.item.created_at}}
      </td>
     <td>
        {{props.item.email}}
      </td>
           <td>
        {{props.item.name}}
      </td>
      </template>
      </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
    </v-layout>

  <v-dialog v-model="userRemove.show" peristent max-width="500px">
 <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">supervised_user_circle</v-icon>Usuń użytkownika</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
Czy na pewno chcesz usunąć tego użytkownika z systemu? Po usunięciu użytkownika nie będzie możliwości zalogowania się na to konto.<br/><br/>
     <u>Konto pocztowe nie zostanie usunięte.</u>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat @click.native="userRemove.show = false"  color="green darken-1"> Anuluj </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat @click="deleteUser()" color="red darken-1">Usuń użytkownika <v-icon class="ml-2">delete</v-icon></v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

   <v-dialog v-model="usersAdd.show" persistent max-width="500px">
      <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">supervised_user_circle</v-icon>Dane użytkownika</span>
        </v-card-title>
              <v-divider></v-divider>
        <v-card-text>
 <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="name"
      :rules="nameRules"
      label="Imię i nazwisko"
      required
    ></v-text-field>
    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="Login adresu e-mail"
      suffix="@odosc.pl"
      required
    ></v-text-field>
    <v-text-field
      v-model="phone"
      label="Numer telefonu"
      required
    ></v-text-field>
          <v-text-field
            v-model="password"
            required
            name="login"
            :append-icon="passwordShow ? 'visibility_off' : 'visibility'"
            :rules="passwordRules"
            :type="passwordShow ? 'text' : 'password'"
            label="Hasło"
            mask
            @click:append="passwordShow = !passwordShow"
          ></v-text-field>

    <!--v-checkbox
      v-model="admin"
      label="Uprawnienia administrator systemu"
      required
    ></v-checkbox-->
 </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat @click.native="usersAdd.show = false"  color="red darken-1">Anuluj</v-btn>
                    <v-spacer></v-spacer>
          <v-btn flat @click="submit" :disabled="!valid"  color="green darken-1"> Dodaj użytkownika <v-icon class="ml-2">add_circle</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.type"
      :timeout="2000"
    >
      {{ snackbar.text }}
      <v-btn
        dark
        flat
        @click="snackbar.show = false"
      >
        X
      </v-btn>
    </v-snackbar>

  </div>
</template>
<script>

import axios from 'axios'

export default {
  data: () => ({
    valid: true,
    phone: '',
    password: '',
    passwordShow: false,
    passwordRules: [
      v => !!v || 'Podaj hasło dla nowego użytkownika',
      v => (/[0-9]+/.test(v) && /[a-z]+/.test(v) && /[A-Z]+/.test(v)) || 'Hasło powinno zawierać minimum 6 znaków - jedną literę (małą i dużą) oraz liczbę'
    ],
    name: '',
    nameRules: [
      v => !!v || 'Wpisz imię i nazwisko nowego użytkownika',
      v => (v && v.length >= 10) || 'Imię i nazwisko musi mieć conajmniej 10 znaków'
    ],
    email: '',
    emailRules: [
      v => !!v || 'Login adresu e-mail jest wymagany',
      v => /[a-zA-Z\.\-]+/.test(v) || 'Login adresu e-mail jest nieprawidłowy'
    ],
    admin: false,
    usersAdd: {
      show: false
    },

    userRemove: {
      show: false,
      users_id: 0
    },
    history: [],
    snackbar: {
      text: '',
      show: false,
      color: 'info'
    },
    clientsItems: [],
    loadingItemsText: '',
    loadingItems: true,
    dialog: false,
    headers: [
      {
        text: 'Użytkownik',
        align: 'left',
        value: 'name'
      },
      {
        text: '',
        align: 'center',
        width: '10px',
        sortable: false
      },
      {
        text: 'E-mail',
        align: 'left',
        value: 'email'
      },
      {
        text: 'Numer telefonu',
        align: 'left',
        value: 'phone'
      },
      {
        text: 'Utworzone',
        value: 'created_at',
        sortable: false

      }
    ]

  }),

  created () {
    this.initialize()
  },

  methods: {
    showSnakbar (text, type) {
      this.snackbar = { show: true, text: text, type: type}
    },
    initialize () {
      this.loadingItems = true
      axios({
        url: this.$ROOT_PATH + '/users',
        method: 'get',
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      })
        .then(response => {
          this.loadingItemsText = 'Brak danych do wyświetlenia'
          this.clientsItems = response.data
        })
        .catch(error => {
          this.loadingItemsText = 'Błąd wczytywania danych'
          this.showSnakbar(this.loadingItemsText, 'error')
          console.log(error)
        })
        .then(() => {
          this.loadingItems = false
        })

      axios({
        url: this.$ROOT_PATH + '/users/history',
        method: 'get',
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      })
        .then(response => {
          this.loadingItemsText = 'Brak danych do wyświetlenia'
          this.history = response.data
        })
        .catch(error => {
          this.loadingItemsText = 'Błąd wczytywania danych'
          console.log(error)
        })
        .then(() => {
          this.loadingItems = false
        })
    },
    alert (text) {
      alert(text)
    },
    deleteUserShowDlg (users_id) {
      this.userRemove.show = true
      this.userRemove.users_id = users_id
    },
    deleteUser () {
      axios({
        url: this.$ROOT_PATH + '/users/delete/' + this.userRemove.users_id,
        method: 'get',
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      })
        .then(response => {
          this.initialize()
          this.showSnakbar('Użytkownik został usunięty.', 'success')
        })
        .catch(error => {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          this.showSnakbar('Nie masz uprawnień do usuwania użytkowników.', 'error')
        })
        .then(() => {
          this.userRemove.show = false
        })
    },

    submit () {
      if (this.$refs.form.validate()) {
        axios({
          url: this.$ROOT_PATH + '/auth/signup',
          method: 'post',
          data: {
            email: this.email + '@odosc.pl',
            name: this.name,
            password: this.password,
            admin: this.admin,
            phone: this.phone
          },
          headers: {
            'X-API-Token': this.$store.state.userCredentials.token
          }
        })
          .then(response => {
            this.initialize()
            this.showSnakbar('Użytkownik ' + this.email + ' został dodany prawidłowo', 'success')
            this.usersAdd.show = false
          })
          .catch(error => {
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          })
          .then(() => {

          })
      }
    }
  }
}
</script>

<style scoped>
.table-row-nowrap {
      white-space: nowrap;
}

</style>
