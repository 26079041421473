<template>

    <v-layout v-scroll>
    <v-flex>
              <v-toolbar dense height="28" flat color="blue-grey lighten-5" class="mb-4">
          <v-toolbar-title>
            <v-tooltip right>
              <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
              <span>Powrót do poprzedniej strony</span>
              </v-tooltip>
              <v-icon class="mr-2">school</v-icon>Edytujesz webinar</v-toolbar-title>
          <v-divider
            class="mx-2"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
                  <!--v-select
              :items="languages"
              box
              label="Wybierz wersje językową"
            ></v-select-->
                <v-tooltip left>
                    <v-btn @click="appendPage(false)" class="success" icon  slot="activator"><v-icon>add_circle</v-icon></v-btn>
                    <span>Dodaj stronę na początku</span>
               </v-tooltip>
                <v-divider vertical inset class="mx-2"></v-divider>
                <!--v-tooltip left>
                    <v-btn @click="saveSettings()" class="info" icon slot="activator"><v-icon>settings</v-icon></v-btn>
                    <span>Ustawienia</span>
                </v-tooltip-->
                <v-tooltip left>
                    <v-btn icon @click="showContent = !showContent" :color="!showContent ? 'grey' : 'info'"  slot="activator"><v-icon>storage</v-icon></v-btn>
                    <span>Zwiń/Rozwiń treść stron</span>
                </v-tooltip>
                <v-divider vertical inset class="mx-2"></v-divider>
                <v-tooltip left>
                    <v-btn disabled class="error" icon @click="deleteCourseDlg.show = true" slot="activator"><v-icon>delete</v-icon></v-btn>
                    <span>Usuń ten webinar</span>
                </v-tooltip>
        </v-toolbar>
    
      <v-layout>
                <v-flex d-flex xs4 sm2 md2 class="text-xs-right">
            <v-card>
              <v-card-text class="logo-background">
            <h2 class="display-2 mt-1 blue-grey--text"> {{ timeOfReading }} min.</h2>
           potrzeba na ten webinar<br/> (+ dodane video)
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex d-flex xs12 sm10 md10 child-flex>
        <v-card>
            <v-card-title class="ma-2">
    <div>
    <h6 class="headline mb-2">
        <span class="font-weight-bold">
      NR {{ course.webinars_id }}</span> / <span>{{ course.title }}</span>
     </h6>
    <v-divider></v-divider>
     <span class="font-italic mt-2">Zakres webinaru</span><br/>
        <span class="body-2"> {{ course.scope }} </span>
     </div>
            </v-card-title>
        </v-card>
        </v-flex>
    </v-layout>
       <v-progress-linear v-if="loadingItems"
            height="2"
          color="primary"
          indeterminate
        ></v-progress-linear>
        <v-alert
        :value="coursePagesFiltred.length < 1 && loadingItems === false"
        type="info"
        v-if="!loadingItems">
         Ten webinar nie posiada żadnych stron. Dodaj strony do tego webinaru.
      </v-alert>
            <draggable :list="coursePages" @end="onPagePositionChange">
                  <transition-group name="list-complete">
                <v-card class="list-complete-item ma-3 course-page" width="800" v-for="(page, index) in coursePages" :id="'page-'+index" :key="page.webinars_pages_id">
              <v-card-title class="pa-1 pl-4 mb-0" color="grey"><h3> <v-icon>description</v-icon>{{ index+1 }}. {{ page.title }}</h3> <v-spacer></v-spacer>
                <v-chip v-if="page.type === 'movie'" color="primary" class="white--text">
                  Materiał filmowy
                </v-chip>
                <v-tooltip top>
                <v-btn flat icon small @click="showPageEditDialog(page.webinars_pages_id)" color="green" slot="activator"><v-icon>edit</v-icon></v-btn>
                <span>Edytuj treść webinaru</span>
            </v-tooltip>
            <v-tooltip top>
                <v-btn flat icon small @click="deletePage(page.webinars_pages_id)" color="red" slot="activator"><v-icon>delete</v-icon></v-btn>
                <span>Usuń tą stronę</span>
            </v-tooltip>
              </v-card-title>
                  <v-card-text v-if="showContent" class="mt-0 pa-3 pt-0">
                      <div style="text-align:justify;text-justify:inter-word;max-witdh:800px;">
                      <span v-if="page.type == 'text'" v-html="page.text"></span>
                      <span v-if="page.type == 'movie'">
                        <video :id="'video-' + page.webinars_pages_id" width="100%" fill-height controls height="400px">
                        <source :src="$BASE_PATH + '/storage/movies/' + page.text" type="video/webm">
                      </video></span>
                      </div>
                  </v-card-text>
                </v-card>
                </transition-group>
    
                 </draggable>
    
                 <v-tooltip left>
                    <v-btn
                        v-model="offsetTop"
                        color="green"
                        @click="appendPage(true)"
                        dark fixed icon large bottom right
                        slot="activator">
                        <v-icon>add_circle</v-icon>
                    </v-btn>
                    <span>Dodaj stronę na końcu</span>
                 </v-tooltip>
    
          <v-snackbar
          v-model="snackbar.show" :color="snackbar.type" :timeout="2000">
          {{ snackbar.text }}
          <v-btn dark flat @click="snackbar.show = false">
            X
          </v-btn>
        </v-snackbar>
    
          <v-snackbar
          v-model="snackbarGrabbing" right bottom color="info" multi-line :timeout="5000">
        <v-icon medium class="mr-4">info</v-icon>
          <strong>Złap stronę klikając na nią i przeciągnij w górę lub w dół aby zmienić jej położenie na liście.</strong>
          <v-btn dark flat @click="snackbarGrabbing = false">X</v-btn>
        </v-snackbar>
    
        </v-flex>
       <v-dialog v-model="coursePageEditDialog.show" persistent max-width="800px">
          <v-card>
            <v-card-title primary-title>
              <span class="title"><v-icon>edit</v-icon> Edytujesz stronę webinaru</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="course-slide-content">
              <form id="webinarmovie">
                <v-text-field
                    class="body-2"
                    label="Tytuł strony"
                    box
                    name="title"
                    v-model="coursePageEditDialog.title"
                ></v-text-field>
                <quill-editor v-if="coursePageEditDialog.type == 'text'" v-model="coursePageEditDialog.text"
                          :options="editorOption">
                </quill-editor>
                  <div  v-if="coursePageEditDialog.type == 'movie'">
                  <input type="hidden" name="webinars_pages_id" :value="coursePageEditDialog.webinars_pages_id" />
                  <uploadbtn name="files" accept=".webm" required></uploadbtn>
                </div>
                  <v-progress-linear v-model="coursePageEditDialog.progress" size="5" color="primary"></v-progress-linear>       
              </form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions  style="clear:both" class=" mt-13">
              <v-btn color="red darken-1" :disabled="coursePageEditDialog.uploading" flat @click.native="coursePageEditDialog.show = false">Anuluj</v-btn>
                 <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat @click.native="coursePageEditDialog.type !== 'movie' ? updatePage() : uploadMovie()" :loading="coursePageEditDialog.uploading"> Zapisz zmiany <v-icon class="ml-2">save</v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

            </v-layout>
    </template>
    
    <script>
    import axios from 'axios'
    import draggable from 'vuedraggable'
    
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import { quillEditor } from 'vue-quill-editor'
    import uploadbtn from '../../components/upload-btn.vue'

    
    export default {
      data: () => ({
        showContent: true,
        offsetTop: 0,
    
        deleteCourseDlg: {
          show: false
        },

        coursePageQuestionsDialog: {
          show: false,
          selected: [],
          loading: false,
          courses_pages_id: -1,
          headers: [
            {
              text: '',
              sortable: false,
              value: false,
              width: 50
            },
            {
              text: 'Treść twierdzenia',
              value: false,
              sortable: false,
              name: 'question',
              align: 'left'
            }, {
              value: false,
              sortable: false,
              width: 50
            }
          ],
          items: [],
          lastItemId: -9999
        },
        loadingItems: false,
        editorOption: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'color': [] }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              ['clean'],
              ['image']
            ],
            history: {
              delay: 1000,
              maxStack: 50,
              userOnly: false
            }
          }
        },
        coursePageEditDialog: {
          show: false,
          courses_pages_id: -1,
          loading: false,
          text: '',
          title: '',
          type: 'text',
          progress: 0,
          uploading: false,
          loading: false
        },
        snackbarGrabbing: true,
        snackbar: {
          text: '',
          show: false,
          color: 'info'
        },
        coursePages: [],
        egzams: [],
        courses: [],
        course: { name: 'Ładowanie danych...', scope: '' },
        egzam: {},
        webinarEditDlg: {
          type: 'text',
          show: false,
          uploading: false,
          progress: 0
        },
      }),
      created () {
        this.loadPages(this.$route.params.id)
      },
      computed: {
        timeOfReading: function () {
          var extraTime = 15000
          // policzyc tagi img i dodac odpowiednia ilosc czasu usredniona
          var totalText = ''
          for (var i = 0; i < this.coursePagesFiltred.length; i++) {
            if (this.coursePagesFiltred[i].text) {
        //      totalText += this.coursePagesFiltred[i].text.replace(/(\<([^>]+)\>)/gi, ' ')
            }
          }
          totalText = totalText.replace(/\s\s/, ' ')
          return (((totalText.length) * 70) + extraTime) / 1000
        },
        coursePagesFiltred: function () {
          return this.coursePages
        }
      },
      components: {
        draggable,
        uploadbtn,
        quillEditor
      },
    
      methods: {
        showPageEditDialog (webinars_pages_id) {
          let coursePage = this.coursePages[this.coursePages.findIndex((i) => i.webinars_pages_id === webinars_pages_id)]
          this.coursePageEditDialog.text = coursePage.text
          this.coursePageEditDialog.title = coursePage.title
          this.coursePageEditDialog.type = coursePage.type
          this.coursePageEditDialog.webinars_pages_id = coursePage.webinars_pages_id
          this.coursePageEditDialog.show = true
        },

        filter (item, queryText, itemText) {
          if (item.header) return false
          const hasValue = val => val != null ? val : ''
          const text = hasValue(itemText)
          const query = hasValue(queryText)
          return text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
        },

        showSnakbar (text, type) {
          this.snackbar.show = false
          this.snackbar = { show: true, text: text, type: type}
        },
        deleteCourse (course_id) {
          axios({
            url: this.$ROOT_PATH + '/webinar/delete/' + course_id,
            method: 'get',
            headers: {'X-API-Token': this.$store.state.userCredentials.token}
          })
            .then(response => {
    
            })
            .catch(error => {
              this.deleteCourseDlg.show = false
              this.showSnakbar('Webinar nie zostało usunięte. Nie posiadasz takich uprawnień.', 'error')
            })
        },

        loadPages (courses_id) {
          this.loadingItems = true
          axios({
            url: this.$ROOT_PATH + '/webinars/' + courses_id,
            method: 'get',
            headers: {
              'X-API-Token': this.$store.state.userCredentials.token
            }
          })
            .then(response => {
              this.coursePages = response.data.pages
              this.course = response.data.webinar
              this.loadingItems = false
            })
            .catch(error => {
              console.log(error.response)
            }).then(() => this.loadingItems = false)
        },

        onPagePositionChange () {
          let payload = []
          for (let i = 0; i < this.coursePages.length; i++) {
            payload.push({ webinars_pages_id: this.coursePages[i].webinars_pages_id, position: i })
          }
          axios({
            url: this.$ROOT_PATH + '/webinars/updatepositions',
            method: 'post',
            headers: {'X-API-Token': this.$store.state.userCredentials.token},
            data: { positions: payload }
          })
            .then(response => {
               this.showSnakbar('Pozycje stron zostały zaktualizowane pomyślnie!', 'success')
            })
            .catch(error => {
              this.showSnakbar('Błąd! Nie zaaktualizowano pozycji stron.', 'error')
            })
        },

        uploadMovie () {
      this.coursePageEditDialog.uploading = true
      let formData = new FormData(document.querySelector('#webinarmovie'))
      axios.post(this.$ROOT_PATH + '/webinar/setmovie', formData, {
        headers: {'X-API-Token': this.$store.state.userCredentials.token, 'Content-Type': 'multipart/form-data'},
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            this.coursePageEditDialog.progress = progressEvent.loaded / progressEvent.total * 100
            console.log(progressEvent.loaded)
          }
        }
      })
        .then(response => {
          let page = this.coursePages.find((i) => i.webinars_pages_id === parseInt(this.coursePageEditDialog.webinars_pages_id))
          var myVideo = document.getElementById('video-' + page.webinars_pages_id)
          myVideo.load()
          page.text = response.data.filename
          page.title = response.data.title
          this.coursePageEditDialog.uploading = false
          this.coursePageEditDialog.show = false
          console.log(response.data)
        })
        .catch(error => {
          this.coursePageEditDialog.uploading = false
          console.log(error)
          this.showSnakbar('Wystapił błąd podczas importowania pliku', 'error')
        })
    },


        updatePage () {
          this.coursePageEditDialog.loading = true
          let courseText = this.coursePageEditDialog.text.replace(/(<br>)/img, '').replace(/(<\/p>)/img, '<br>').replace(/(<p[^>]+?>|<p>)/img, '')
          axios({
            url: this.$ROOT_PATH + '/webinars/pages/update',
            method: 'post',
            headers: {'X-API-Token': this.$store.state.userCredentials.token},
            data: {
              webinars_pages_id: this.coursePageEditDialog.webinars_pages_id,
              text: courseText,
              title: this.coursePageEditDialog.title
            }
          })
            .then(response => {
              this.coursePageEditDialog.loading = false
              let coursePagesIndex = this.coursePages.findIndex((i) => i.webinars_pages_id === this.coursePageEditDialog.webinars_pages_id)
              this.coursePages[coursePagesIndex].title = this.coursePageEditDialog.title
              this.coursePages[coursePagesIndex].text = courseText
              this.coursePageEditDialog.show = false

              this.showSnakbar('Strona szkolenia została zaktualizowana.', 'success')
            })
            .catch(error => {
              this.coursePageEditDialog.loading = false
              console.log(error.response)
              this.showSnakbar('Błąd! Dane strony nie zostały zaaktualizowane.', 'error')
            })
        },
        appendPage (to_end) {
          axios({
            url: this.$ROOT_PATH + '/webinars/pages/new',
            method: 'post',
            headers: {'X-API-Token': this.$store.state.userCredentials.token},
            data: {
              webinars_id: this.$route.params.id,
              position: this.coursePages.length + 1,
              type: 'text',
              text: 'Uzupełnij dane strony szkolenia.',
              title: 'Nowa strona'
            }
          })
            .then(response => {
              let course = response.data
              let newCoursePage = { webinars_pages_id: course.webinars_pages_id, position: course.position, text: course.text, title: course.title }
              if (to_end) {
                this.coursePages.push(newCoursePage)
                this.$scrollTo('#page-' + (this.coursePages.length - 2), { offset: 300 })
              } else {
                this.coursePages = [newCoursePage].concat(this.coursePages)
              }
              this.onPagePositionChange()
              this.showSnakbar('Dodano nową stronę dla tego webinaru.', 'success')
            })
            .catch(error => {
              console.log(error)
              this.showSnakbar('Błąd! Nowa strona nie została dodana.', 'error')
            })
        },
        deletePage (pageId) {
          axios({
            url: this.$ROOT_PATH + '/webinars/pages/delete',
            method: 'post',
            headers: {'X-API-Token': this.$store.state.userCredentials.token },
            data: { webinars_pages_id: pageId }
          })
            .then(response => {
              this.coursePages.splice(this.coursePages.findIndex((i) => i.webinars_pages_id === pageId), 1)
              this.showSnakbar('Strona została usunięta z powodzeniem.', 'success')
              this.onPagePositionChange()
            })
            .catch(error => {
              this.showSnakbar('Błąd! Strona nie zostałą usunięta.', 'error')
            })
        }
      }
    }
    </script>
    <style>
    .table-questions-shortcolumm {
        width:30px;
    }
    .quill-editor {
        height: 25rem;
        margin-bottom:40px;
    }
    @media only screen and (max-width: 600px) {
      .ql-video,
      .course-page img {
          width:100% !important;
          max-width:900px !important;
      }
    }
    .ql-video {
        width:100%;
        max-width:800px;
        max-height:800px;
        height:400px;
    }
    .course-page {
        margin:10px auto !important;
        max-width:1000px;
    }
    .course-page img {
        margin:10px auto !important;
        border:1px solid #666 !important;
        max-width:800px;
    }
    .list-complete-item {
      cursor: grabbing;
      transition: all 0.3s;
    }
    .list-complete-enter, .list-complete-leave-active {
      opacity: 0;
      display: absolute;
    }
    </style>
    