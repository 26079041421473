<template>
    <v-layout>
    <v-toolbar dense height="28" flat color="blue-grey lighten-5">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip>
          <v-icon class="mr-2">supervised_user_circle</v-icon>Ustawienia użytkownika</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-btn class="error" icon><v-icon>delete</v-icon></v-btn>
    </v-toolbar>

    </v-layout>
</template>
<script>

export default {
  data: () => ({
  })
}

</script>
