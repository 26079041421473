<template>
<div>
  <v-toolbar dense height="28" flat color="blue-grey lighten-5">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip>
          <v-icon class="mr-2">ballot</v-icon>Edytujesz egzamin</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-tooltip left>
          <v-btn @click="appendQuestionDialog.show = true" slot="activator" class="success" icon><v-icon>add_circle</v-icon></v-btn>
          <span>Dodaj nowe pytanie</span>
      </v-tooltip>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-tooltip left>
          <v-btn @click="showSettingDlg()" slot="activator" class="info" icon><v-icon>settings</v-icon></v-btn>
          <span>Ustawienia</span>
      </v-tooltip>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-tooltip left>
          <v-btn :disabled="true" class="error" icon slot="activator"><v-icon>delete</v-icon></v-btn>
          <span>Usuń ten egzamin</span>
       </v-tooltip>
    </v-toolbar>
    <v-layout>
            <v-flex d-flex xs4 sm2 md2 class="text-xs-right">
        <v-card class="mt-3">
          <v-card-text class="logo-background">
        <h2 class="display-2 mt-1 blue-grey--text">{{ passedCount }}%</h2>
        szacowany średni wynik egzaminu na podstawie odpowiedzi
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 sm9 md7 child-flex>
    <v-card class="mt-3">
        <v-card-title class="ma-2">
<div>
<h6 class="headline mb-2">
  <v-chip :color="category.color" label text-color="white">
    <span>
      {{ category.name }}
    </span>
    </v-chip>
    <span class="font-weight-bold">
  NR {{ egzam.egzams_id }}</span> / <span>{{ egzam.name }}</span>

</h6>
<v-divider></v-divider>
<span class="font-italic mt-1">Zakres egzaminu</span><Br/>
<span class="body-2"> {{ egzam.scope }} </span>
 </div>
        </v-card-title>
    </v-card>
    </v-flex>
            <v-flex d-flex xs12 sm3 md4 child-flex>
              <v-card class="mt-3">
                <v-card-text>
                  <v-flex xs12 sm8 d-flex>
                    <v-select
                      v-model="language"
                      :items="languagesItems"
                      class="ml-3"
                      label="Wersja językowa"
                      item-text="state"
                      item-value="abbr"
                      prepend-inner-icon="language"
                      hide-details
                    >
                    <template slot="selection" slot-scope="{ item, parent, selected }">
                      <v-list-tile-content>
                          <span class="title">{{ item.state }}</span>
                      </v-list-tile-content>
                      <v-spacer></v-spacer>
                      <country-flag :country="(item.abbr.toLowerCase() === 'en' ? 'gb' : item.abbr)" shadow class="ma-0" />
                    </template>
                    <template slot="item" slot-scope="{ index, item }">
                      <v-list-tile-content>
                          <span class="title">{{ item.state }}</span>
                      </v-list-tile-content>
                      <v-spacer></v-spacer>
                      <country-flag :country="(item.abbr.toLowerCase() === 'en' ? 'gb' : item.abbr)" shadow class="ma-0" />
                    </template>
                  </v-select>
                  </v-flex>
      <v-text-field
        class="elevation-0 ml-3"
        v-model="search"
        append-icon="search"
        label="Wyszukaj pytania po wybranej frazie..."
        hide-details
      ></v-text-field>

          </v-card-text>
      </v-card>

</v-flex>
</v-layout>
<v-progress-linear v-model="passedCount" height="10" class="ma-0" :color="getAccuracyColor(passedCount)"></v-progress-linear>
    <v-card class="pa-2">
        <v-card-text>
    <v-data-table
      :headers="headers"
      :items="questionsFiltred"
      :loading="loading"
      :search="search"
      :disable-initial-sort="true"
      :rows-per-page-items="[50,100]"
      rows-per-page-text="Liczba wierszy na stronę"
      class="elevation-0"
    >
      <template slot="items" slot-scope="props">
        <td class="text-xs-left">
            <!--v-btn flat @click="deleteUser()" color="red darken-1">Usuń użytkownika <v-icon class="ml-2">delete</v-icon></v-btn-->
        <!--v-btn icon small dark color="red darken-1"> <v-icon icon  small>delete</v-icon></v-btn-->
        <v-btn icon small dark color="yellow darken-1" @click="showPageClaimsDialog(props.item.egzams_questions_id)"> <v-icon icon small>edit</v-icon></v-btn>
     <span class="ml-2 mb-1 body-2">{{ props.item.text }} </span></td>
     <td class="body-2">
         <v-chip color="green" text-color="white">
      <v-avatar class="green darken-4">{{ parseInt((props.item.acounter-props.item.answersCounter+1)/props.item.answersCounter+1) }}</v-avatar>
      odpowiedzi
      </v-chip>
     </td>
    <td class="text-lg-right body-2"><strong>{{props.item.correct_precent}}%</strong></td>
    <td><v-progress-linear v-model="props.item.correct_precent" height="11" :color="getAccuracyColor(props.item.correct_precent)"></v-progress-linear></td>
      </template>
      <template slot="no-data">
    Brak pytań w bazie w tej wersji językowej.
      </template>
      <template slot="pageText" slot-scope="props">
         {{ props.pageStart }} - {{ props.pageStop }} z {{ props.itemsLength }}
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning" class="pa-3">
        Nie znaleziono pytania pasującego do "{{ search }}"
      </v-alert>
    </v-data-table>
        </v-card-text>
    </v-card>
  <v-dialog v-model="settingsDlg.show" persistent max-width="590px">
      <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">settings</v-icon>Ustawienia egzaminu</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3">
          <v-text-field
          v-model="settingsDlg.name"
          label="Nazwa egzaminu (widoczna tylko w systemie)"
          required
          box
        ></v-text-field>
        <v-text-field
          v-model="settingsDlg.scope"
          label="Zakres egzaminu (widoczny w dokumentacji)"
          required
          box
        ></v-text-field>
      <v-layout row>
      <v-flex xs4>
          <v-slider
            v-model="settingsDlg.answers_precentange"
            :max="100"
            :min="10"
            thumb-label="always"
            class="align-center ma-2"
            always-dirty
            step="5"
            tick-size="2"
            ticks="always"
          >
          <template v-slot:prepend>
            {{ settingsDlg.answers_precentange }}%
          </template>
          </v-slider>
    </v-flex>
      <v-flex xs8>
        <v-subheader>Procentowa ilość pytań egzaminacyjnych, które zostaną wylosowane z całej puli pytań dla zdjącego egzamin</v-subheader>
      </v-flex>
</v-layout><v-divider></v-divider>
      <v-layout row>
      <v-flex xs4>
          <v-text-field
            value="8"
            v-model="settingsDlg.min_answers"
            :min="5"
            :max="questionsFiltred.length"
            suffix="pytań"
            mask="#"
            type="number"
            single-line
          ></v-text-field>
      </v-flex>
      <v-flex xs8>
        <v-subheader>Minimalna ilość pytań na egzaminie<br/>(jeżeli procentowa ilość pytań jest mniejsza)</v-subheader>
           <span class="body-2 pa-3">{{ questionsToAsk }} pytań zostanie zadanych zdającemu</span>
      </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row  class="mt-4">

      <v-flex xs4>
  <v-combobox
    v-model="model"
    :filter="filter"
    :hide-no-data="!search_category"
    :items="items"
    :search-input.sync="search_category"
    hide-selected
    label="Podstawowa"
    solo
    no-data-text="Usuń aby utworzyć"
    class="mb-0"
    @keyup.enter.prevent="none()"
  >
    <template slot="no-data">
      <v-list-tile v-if="model === null">
        <v-chip
          :color="colors[0]"
          text-color="white"
          label
          small>
          {{ search }}
        </v-chip>
        <span class="subheading">Tworzysz nową</span>
      </v-list-tile>
    </template>
    <template slot="selection" slot-scope="{ item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        :color="item.color"
        :selected="selected"
        label
        text-color="white"
      >
        <span class="pr-2">
          {{ item.name }}
        </span>
      </v-chip>
    </template>
    <template slot="item" slot-scope="{ index, item }">
      <v-list-tile-content>
        <v-chip
          :color="item.color"
          label
          text-color="white">
          {{ item.name }}
          </v-chip>
      </v-list-tile-content>
      <v-spacer></v-spacer>
      <v-list-tile-action @click.stop>
        <v-btn
          icon
           v-if="editing !== item"
          @click.stop.prevent="showDeleteCategorySnakbar(item); editing = item">
          <v-icon>close</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click.stop.prevent="deleteCategory(index, item); editing = null">
          <v-icon>check</v-icon>
        </v-btn>
      </v-list-tile-action>
    </template>
  </v-combobox>
  </v-flex>
        <v-flex xs8>
          <v-subheader>  Przypisz kategorie do tego egzaminu. Możesz także utworzyć nową kategorie wpisując jej nazwę i wciskając enter.</v-subheader>
      </v-flex>
  </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red darken-1" flat @click.native="settingsDlg.show = false">Anuluj</v-btn>
                    <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="saveSettings()"> Zapisz ustawienia<v-icon class="ml-2">save</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  <v-dialog v-model="appendQuestionDialog.show" persistent max-width="750px">
      <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">question_answer</v-icon>Dodaj nowe pytanie do tego egzaminu</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
         <span class="subheader mb-2"></span> Pytanie w języku: <span class="font-weight-bold">{{ this.languagesItems.find((i) => i.abbr.toLowerCase() === this.language.toLowerCase()).state }}</span></span>
 <v-form ref="form" v-model="appendQuestionDialog.valid" lazy-validation>
    <v-textarea
      v-model="appendQuestionDialog.text"
      :rules="appendQuestionDialog.questionRules"
      rows="1"
      required
      label="Treść pytania"
      auto-grow
      box
      :counter="255"
    ></v-textarea>

 </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red darken-1" flat @click.native="appendQuestionDialog.show = false">Anuluj</v-btn>
                    <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="appendQuestion" :disabled="!appendQuestionDialog.valid"> Dodaj pytanie <v-icon class="ml-2">add_circle</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   <v-dialog v-model="coursePageQuestionsDialog.show" persistent max-width="800px">
      <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="ml-2">question_answer</v-icon> Edytuj pytanie</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

    <v-textarea
      v-model="coursePageQuestionsDialog.question"
      :rules="appendQuestionDialog.questionRules"
      box
      rows="1"
      required
      label="Pytanie egzaminacyjne"
      auto-grow
      :counter="255"
    ></v-textarea>
            <v-alert v-if="coursePageQuestionsDialog.items.length > 0" :value="true" color="warning" icon="info" class="pa-2 pl-3">
                Na liście zaznacz wybrane odpowiedzi które są poprawne.
            </v-alert>
    <v-data-table
      :headers="coursePageQuestionsDialog.headers"
      :items="coursePageQuestionsDialog.items"
      v-model="coursePageQuestionsDialog.selected"
      :loading="coursePageQuestionsDialog.loading"
      hide-actions
      hide-headers
      item-key="egzams_answers_id"
    >
      <template slot="items" slot-scope="props">
     <td class="table-questions-shortcolumm">
        <v-checkbox
          v-model="props.selected"
          primary
          hide-details></v-checkbox>
      </td>
      <td style="width:50px;">
         <v-progress-circular
      :rotate="360"
      :size="35"
      :width="6"
      :value="100-props.item.correct_precent"
      :color="getAccuracyColor(props.item.correct_precent)"
    >
      {{ props.item.correct_precent }}
    </v-progress-circular></td>
        <td>
          <v-edit-dialog
            class="pa-3"
            :full-width="true"
            :return-value.sync="props.item.text"
            lazy
          > {{ props.item.text }}
            <v-text-field
              slot="input"
              clearable
              :rules="appendQuestionDialog.answerRules"
              v-model="props.item.text"
              label="Edytuj odpowiedź"
              :counter="255"
            ></v-text-field>
          </v-edit-dialog>
        </td>
        <td class="table-questions-shortcolumm align-right">
            <v-btn dark icon small @click="deleteAnswer(props.item.egzams_answers_id)" color="red darken-1" ><v-icon icon small>delete</v-icon></v-btn>
        </td>
      </template>
      <template slot="no-data">

            <v-alert  v-if="!coursePageQuestionsDialog.loading" :value="true" color="error" icon="error" class="pa-2 pl-3 mt-4 mb-4">
                  Do tego pytania nie zostały dodane jeszcze żadne odpowiedzi.
            </v-alert>
      </template>
    </v-data-table>
        </v-card-text>
           <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red darken-1" flat @click.native="closePageQuestionsDialog" :disabled="coursePageQuestionsDialog.items.length < 2">Anuluj</v-btn>
             <v-btn color="red darken-1" flat @click="deleteQuestion" > Usuń to pytanie<v-icon class="ml-2">delete</v-icon></v-btn>
      <v-spacer></v-spacer>
          <v-btn color="info" flat @click="appendAnswer"> Dodaj odpowiedź<v-icon class="ml-2">add_circle</v-icon></v-btn>
          <v-btn color="green darken-1" flat @click="updateEgzamQuestion" :disabled="coursePageQuestionsDialog.items.length < 2" :loading="loadingItems">Zapisz zmiany <v-icon class="ml-2">save</v-icon> </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="deleteCategorySnackbar.show"
        color="orange"
        :timeout="5000">{{ deleteCategorySnackbar.text }}
    </v-snackbar>
</div>

</template>
<script>

import axios from 'axios'

export default {
  data () {
    return {

      editing: null,
      items: [],
      model: null,
      search_category: null,
      addCategories: [],
      removeCategories: [],
      search: '',
      loading: false,
      loadingItems: false,
      questionsItems: [],
      egzam: { name: 'Ładowanie danych...', scope: 'Ładowanie danych...', categories_id: 0 },
      categories: [],

      deleteCategorySnackbar: {
        show: false,
        text: ''
      },
      languagesItems: [
        { state: 'Polski', abbr: 'PL' },
        { state: 'Angielski', abbr: 'EN' }
      ],
      language: 'PL',

      colors: [
        '#64B5F6', // 'blue',
        '#E57373', // 'red',
        '#4DB6AC', // 'green',
        '#FFB74D', // orange',
        '#7986CB', // 'indigo',
        '#4DB6AC', // teal',
        '#9575CD', // purple
        '#4DD0E1', // cyam
        '#A1887F' // brown
      ],

      appendQuestionDialog: {
        show: false,
        text: '',
        questionRules: [
          v => !!v || 'Wpisz treść pytania egzaminacyjnego.',
          v => (v && v.length >= 10) || 'Pytanie egzaminacyjne nie powinno być krótsze niż 10 znaków.',
          v => (v && v.length <= 255) || 'Pytanie nie może być dłuższe niż 255 znaków.'
        ],
        answerRules: [
          v => !!v || 'Wpisz treść odpowiedzi.',
          v => (v && v.length >= 2) || 'Odpowiedź nie powinna być krótsza niż 2 znaków.',
          v => (v && v.length <= 255) || 'Odpowiedź nie może być dłuższa niż 255 znaków.'
        ],
        valid: false
      },
      settingsDlg: {
        show: false,
        answers_precentange: 20,
        min_answers: 5,
        questionsToAsk: 0
      },
      changeCategoryDlg: {
        show: false
      },

      coursePageQuestionsDialog: {
        show: false,
        selected: [],
        loading: false,
        egzams_questions_id: -1,
        headers: [
          {
            text: '',
            sortable: false,
            value: false,
            width: '30px'
          },
          {
            text: '',
            sortable: false,
            value: false,
            width: '30px'
          },
          {
            text: 'Treść odpowiedzi',
            value: false,
            sortable: false,
            align: 'left',
            name: 'question'
          },
          {
            value: false,
            sortable: false,
            align: 'right',
            width: '30px'
          }
        ],
        items: [],
        question: '',
        lastItemId: -9999
      },

      headers: [
        {
          text: 'Pytanie egzaminacyjne',
          align: 'left',
          value: 'text'
        },
        {
          text: 'Udzielone odpowiedzi',
          value: 'answersCounter',
          sortable: false,
          width: '200px'
        }, {
          value: false,
          sortable: false,
          align: 'right',
          width: '20px'
        },
        {
          text: 'Trafność odpowiedzi',
          value: 'correct_precent',
          sortable: false,
          width: '80px'
        }
      ]
    }
  },
  created () {
    this.loadCategories()
    this.loadQuestions(this.$route.params.id)
  },
  computed: {
    passedCount: function () {
      var questions = this.questionsFiltred
      var counter = 0
      for (var i = 0; i < questions.length; i++) {
        counter += questions[i].correct_precent
      }
      var passedCount = parseInt(counter / questions.length)
      return passedCount > 0 ? passedCount : 0
    },
    questionsToAsk: function () {
      var qtoa = this.questionsFiltred.length * (this.settingsDlg.answers_precentange / 100)
      if (qtoa < this.settingsDlg.min_answers) {
        qtoa = this.settingsDlg.min_answers
      }
      return parseInt(qtoa)
    },
    questionsFiltred: function () {
      return this.questionsItems.filter((i) => i.language.toLowerCase() === this.language.toLowerCase())
    },
    category: function () {
      var index = this.items.findIndex((c) => parseInt(c.categories_id) === parseInt(this.egzam.categories_id))
      if (index < 0) {
        return {
          categories_id: 0,
          name: 'Podstawowa',
          color: 'grey'
        }
      }
      return this.items[index]
    }
  },
  watch: {
    model (val, prev) {
      if (typeof val === 'string' || typeof val === 'object') {
        var category = this.items.find((i) => this.egzam.categories_id === i.categories_id)
        if (typeof category !== 'undefined') {
        //  val = [category]
        }
        val = [val]
      }
      if ((prev !== null && val !== null && val !== prev) || val === null || typeof val === 'undefined') return
      this.model = val.map(v => {
        if (typeof v === 'string' && typeof this.items !== 'undefined' &&
              this.items.findIndex(i => i.name.toLowerCase() === v.toLowerCase()) < 0) {
          v = {
            name: v,
            color: this.colors.shift(),
            categories_id: 0,
            egzams_count: 0
          }
          this.items.push(v)
        }
        return v
      })[0]
    }
  },

  methods: {
    none () {
      return true
    },
    showDeleteCategorySnakbar (item) {
      if (item.egzams_count > 0) {
        this.deleteCategorySnackbar.text = 'Aktualnie do tej kategorii są przypisane egzaminy (' + item.egzams_count + ') i zostaną z niej usunięte.'
        this.deleteCategorySnackbar.show = true
      }
    },
    deleteCategory (index, item) {
      if (item.categories_id > 0) {
        this.removeCategories.push(item)
      }
      this.items = this.items.filter(v => v.name !== item.name)
      this.colors.unshift(item.color)
    },
    filter (item, queryText, itemText) {
      if (item.header) return false
      const hasValue = val => val != null ? val : ''
      const text = hasValue(itemText)
      const query = hasValue(queryText)
      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    showSettingDlg () {
      this.settingsDlg.answers_precentange = this.egzam.answers_precentange
      this.settingsDlg.min_answers = this.egzam.min_answers
      this.settingsDlg.name = this.egzam.name
      this.settingsDlg.scope = this.egzam.scope
      this.settingsDlg.show = true
    },
    getAccuracyColor (precent) {
      if (precent >= 80) { return 'green' }
      if (precent >= 70) { return 'yellow darken-3' }
      if (precent >= 60) { return 'orange darken-2' }
      if (precent >= 50) { return 'red lighten-2' }
      return 'red darken-1'
    },
    appendAnswer () {
      this.coursePageQuestionsDialog.items.push(
        {
          text: 'Wpisz treść odpowiedzi (możesz edytować ten tekst klikając na niego)',
          egzams_answers_id: this.coursePageQuestionsDialog.lastItemId++,
          egzams_questions_id: this.coursePageQuestionsDialog.egzams_questions_id,
          answersCounter: 0
        }
      )
    },
    loadCategories () {
      axios({url: this.$ROOT_PATH + '/categories', method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token }})
        .then(response => {
          this.items = response.data
          if (this.items.length > 0) {
            this.items.forEach(i => {
              this.colors = this.colors.filter(c => i.color !== c)
            })
            this.model = this.items.find(i => this.egzam.categories_id === i.categories_id)
          }
        })
    },
    saveSettings () {
      axios({
        url: this.$ROOT_PATH + '/egzams/settings/save',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token},
        data: {
          egzams_id: this.$route.params.id,
          answers_precentange: this.settingsDlg.answers_precentange,
          min_answers: this.settingsDlg.min_answers,
          category_name: (typeof this.model !== 'undefined' && this.model !== null ? this.model.name : -1),
          categories: this.items,
          remove_categories: this.removeCategories,
          name: this.settingsDlg.name,
          scope: this.settingsDlg.scope
        }
      })
        .then(response => {
          console.log(this.items)
          this.egzam.name = response.data.name
          this.egzam.scope = response.data.scope
          this.egzam.categories_id = response.data.categories_id
          this.egzam.answers_precentange = this.settingsDlg.answers_precentange
          this.egzam.min_answers = this.settingsDlg.min_answers
          this.settingsDlg.show = false
          this.showSnakbar('Ustawienia egzaminu zostały zapisane z powodzeniem.', 'success')
          this.items = response.data.categories
          this.removeCategories = []
          this.items.forEach(i => {
            this.colors = this.colors.filter(c => i.color !== c)
          })
        })
        .catch(error => {
          console.log(error)
          this.showSnakbar('Wystapił błąd poczas zapisu ustawień.', 'error')
        })
    },
    deleteQuestion () {
      axios({
        url: this.$ROOT_PATH + '/egzams/questions/delete',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token},
        data: {
          egzams_questions_id: this.coursePageQuestionsDialog.egzams_questions_id
        }
      })
        .then(response => {
          this.coursePageQuestionsDialog.show = false
          this.questionsItems.splice(this.questionsItems.findIndex((c) => c.egzams_questions_id === this.coursePageQuestionsDialog.egzams_questions_id), 1)
          this.showSnakbar('Pytanie zostało usunięte z powodzeniem.', 'success')
        })
        .catch(error => {
          console.log(error)
          this.showSnakbar('Błąd! Pytanie nie zostało usunięte.', 'error')
        })
    },
    appendQuestion () {
      axios({
        url: this.$ROOT_PATH + '/egzams/questions/new',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token},
        data: {
          egzams_id: this.$route.params.id,
          text: this.appendQuestionDialog.text,
          language: this.language
        }
      })
        .then(response => {
          console.log(response.data)
          this.questionsItems.push({
            egzams_id: response.data.egzams_id,
            egzams_questions_id: response.data.egzams_questions_id,
            text: response.data.text,
            language: response.data.language.toLowerCase(),
            answersCounter: 0
          })
          this.appendQuestionDialog.show = false
          this.showPageClaimsDialog(response.data.egzams_questions_id)
        })
        .catch(error => {
          console.log(error)
          this.showSnakbar('Błąd! Nowa strona nie została dodana.', 'error')
        })
    },
    closePageQuestionsDialog () {
      this.coursePageQuestionsDialog.show = false
      this.coursePageQuestionsDialog.items = []
      this.coursePageQuestionsDialog.selected = []
    },
    deleteAnswer (egzams_answers_id) {
      this.coursePageQuestionsDialog.items.splice(this.coursePageQuestionsDialog.items.findIndex((i) => i.egzams_answers_id === egzams_answers_id), 1)
    },
    showPageClaimsDialog (egzams_questions_id) {
      this.coursePageQuestionsDialog.question = this.questionsItems.find((c) => c.egzams_questions_id === egzams_questions_id).text
      this.coursePageQuestionsDialog.egzams_questions_id = egzams_questions_id
      this.loadAnswers(egzams_questions_id)
      this.coursePageQuestionsDialog.show = true
    },
    updateEgzamQuestion (egzams_questions_id) {
      this.loadingItems = true
      let egzamQuestionAnswers = this.coursePageQuestionsDialog.items
      for (let i = 0; i < egzamQuestionAnswers.length; i++) {
        egzamQuestionAnswers[i].is_true = (this.coursePageQuestionsDialog.selected.findIndex((c) => c.egzams_answers_id === egzamQuestionAnswers[i].egzams_answers_id) != -1)
      }
      this.coursePageQuestionsDialog.loading = true
      axios({
        url: this.$ROOT_PATH + '/egzams/questions/updateanswers',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token},
        data: {
          question: this.coursePageQuestionsDialog.question,
          answers: egzamQuestionAnswers,
          egzams_questions_id: this.coursePageQuestionsDialog.egzams_questions_id,
          egzams_id: this.$route.params.id
        }
      })
        .then(response => {
          this.questionsItems.find((c) => c.egzams_questions_id === this.coursePageQuestionsDialog.egzams_questions_id).text = this.coursePageQuestionsDialog.question
          this.closePageQuestionsDialog()
          this.loadingItems = false
          this.showSnakbar('Pytanie zostało zaaktualizowane.', 'success')
        })
        .catch(error => {
          console.log(error)
          this.loadingItems = false
          this.showSnakbar('Błąd! Twierdzenia nie zostały zapisane.', 'error')
        }).then(() => {
          this.coursePageQuestionsDialog.loading = false
        })
    },
    loadAnswers (egzams_questions_id) {
      this.coursePageQuestionsDialog.loading = true
      axios({url: this.$ROOT_PATH + '/egzams/questions/answers/' + egzams_questions_id, method: 'get', headers: {'X-API-Token': this.$store.state.userCredentials.token}})
        .then(response => {
          let egzamQuestionAnswers = response.data
          for (let i = 0; i < egzamQuestionAnswers.length; i++) {
            if (egzamQuestionAnswers[i].is_true != false) {
              this.coursePageQuestionsDialog.selected.push(egzamQuestionAnswers[i])
            }
          }
          this.coursePageQuestionsDialog.items = egzamQuestionAnswers
          this.coursePageQuestionsDialog.loading = false
        })
        .catch(error => {
          console.log(error)
          this.showSnakbar('Błąd! Nie można pobrać twierdzeń dla tej strony.', 'error')
        }).then(() => {
          this.coursePageQuestionsDialog.loading = false
        })
    },

    loadQuestions (egzams_id) {
      this.loading = true
      axios.all([ axios({url: this.$ROOT_PATH + '/egzams/questions/' + egzams_id, method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token }}),
        axios({url: this.$ROOT_PATH + '/egzams/' + egzams_id, method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token }}),
        axios({url: this.$ROOT_PATH + '/categories', method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token }})
      ])
        .then(axios.spread((firstResponse, secondResponse, thirdResponse) => {
          this.loading = false
          this.questionsItems = firstResponse.data
          this.egzam = secondResponse.data
          this.items = thirdResponse.data
          this.settingsDlg.answers_precentange = this.egzam.answers_precentange
          this.settingsDlg.min_answers = this.egzam.min_answers

          this.model = this.items.find((i) => this.egzam.categories_id === i.categories_id)
          this.colors = this.colors.filter((c) => this.items.findIndex(i => i.color === c) < 0)
        }))
        .catch(error => {
          this.loading = false
        })
    },
    showSnakbar (text, type) {
      this.snackbar = { show: true, text: text, type: type}
    }
  }
}
</script>
<style scoped>
.logo-background {
      background:url('https://a.odosc.pl/v1/assets/firm-paper2.jpg') top left no-repeat;background-size:70%;
}
.table-row-nowrap {
      white-space: nowrap;
}
</style>
