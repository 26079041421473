import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import './plugins/vuetify'
import router from './router'
import store from './store'
import CountryFlag from 'vue-country-flag'

// import VueI18n from 'vue-i18n'
// import DaySpanVuetify from 'dayspan-vuetify'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.min.css'
import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css'

// Vue.use(VueMobileDetection)
// Vue.use(require('chartist-vuejs'))
Vue.use(require('vue-scrollto'), {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
/*
const messages = {
  en: {
    identify: {
      employeeLoginInfoTitle: 'Login of person attempting course (given by employer)',
      employeePasswordInfoTitle: 'Course password',
      btnBack: 'Back',
      btnForward: 'Next'
    }
  },
  pl: {
    identify: {
      employeeLoginInfoTitle: 'Login pracownika przystępującego do szkolenia (otrzymany od pracodawcy)',
      employeePasswordInfoTitle: 'Hasło autoryzujące',
      btnBack: 'Wstecz',
      btnForward: 'Dalej'
    }
  }
}
/*
const i18n = VueI18n.createI18n({
  locale: 'pl', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages // set locale messages
})
Vue.use(i18n)
*/

Vue.component('country-flag', CountryFlag)
// Vue.use(DaySpanVuetify)

Vue.config.productionTip = false

Vue.prototype.$ACTIVITY_TIMER = 0
Vue.prototype.$VERSION = '3.1.0'
Vue.prototype.$BASE_PATH = (location.hostname === 'localhost' ? 'http://dev.a.odosc.pl' : 'https://szkolenia.odosc.pl')
Vue.prototype.$BASE_PATH = 'https://szkolenia.odosc.pl'
Vue.prototype.$ROOT_PATH = Vue.prototype.$BASE_PATH + '/v1'
Vue.prototype.getRootPath = () => Vue.prototype.$ROOT_PATH

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
