<template>
  <div>
    <v-layout v-show="true">
      <v-flex>
  <v-toolbar dense height="28" flat color="blue-grey lighten-5">
      <v-toolbar-title>
        <v-tooltip right>
        <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
        <span>Powrót do poprzedniej strony</span>
        </v-tooltip>
        <v-icon class="mr-2">assignment_ind</v-icon>Lista klientów</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-tooltip left>
      <v-btn @click="addCustomerDlg.show = true" class="success" icon slot="activator"><v-icon>add_circle</v-icon></v-btn>
      <span>Dodaj nowego klienta</span>
      </v-tooltip>

    </v-toolbar>

    <v-card class="elevation-0">
      <v-card-text class="mt-3 pa-0">
<v-layout>
  <v-flex md7>
      <v-tabs v-model="tab">
    <v-tab @click="initialize('permament')">
      Stali klienci
    </v-tab>
    <v-tab @click="initialize('rest')">
      Pozostali
    </v-tab>
    <v-tab @click="initialize('all')">
      Wszyscy
    </v-tab>
  </v-tabs>
  </v-flex>
  <v-flex md5>
      <v-text-field
        class="mr-3"
        v-model="search"
        append-icon="search"
        label="Wyszukaj firmę po nazwie, nr nip, adresie e-mail itd."
        hide-details
      ></v-text-field>
  </v-flex>
</v-layout>
      </v-card-text>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="clientsItems"
      :loading="loadingItems"
      :search="search"
      disable-initial-sort
      :rows-per-page-items="[100,500]"
      rows-per-page-text="Liczba wierszy na stronę"
      class="elevation-1 mt-1"
      hide-slider
    >
      <template slot="items" slot-scope="props">
        <tr>
          <td>
            <v-tooltip right> <v-btn slot="activator" small icon color="yellow darken-1" dark class="mr-2 ml-2n" @click="showEditDialog(props.item.customers_id)">
            <v-icon dark small>edit</v-icon></v-btn> <span>Edytuj tego klienta</span></v-tooltip>
            <v-avatar class="mr-2" v-if="props.item.groups" :color="props.item.groups ? 'primary lighten-3' : 'grey'" size="22"><v-icon small dark>{{ props.item.groups ? 'people' : 'person' }}</v-icon></v-avatar>
            <router-link :to="'/customers/edit/'+ props.item.customers_id " tag="a" class="body-2">{{ truncateCustomerName(props.item.name).name }}</router-link>
            &nbsp; <v-chip small v-if="props.item.gen_period_egzams" color="blue lighten-1" class="mr-1 ml-0" text-color="white">
              <v-avatar class="blue darken-2 font-weight-bold" small><v-icon>check</v-icon></v-avatar>egzaminy</v-chip>
            <v-chip small v-if="props.item.failed_counter > 0" color="red lighten-1" class="mr-1 ml-0" text-color="white">
                <v-avatar class="red darken-2 font-weight-bold" small>{{ parseInt(props.item.failed_counter) }}</v-avatar>
                nie zdał{{ props.item.failed_counter > 1 ? 'o' : '' }}</v-chip>

          </td>
          <td class="text-xs-center">
            <v-chip color="red lighten-1" small text-color="white" v-if="props.item.total_stuck > 0">
              <v-avatar class="red darken-2"><v-icon small color="white">notification_important</v-icon></v-avatar>
              <span class="font-weight-bold">{{ props.item.total_stuck }}</span>
            </v-chip>
          </td>
          <td class="text-xs-center body-2 font-weight-bold">{{ props.item.total_counter }} </td>
          <td class="text-xs-left body-2"><v-progress-linear v-if="props.item.total_counter > 0" v-model="props.item.passed_precent" height="10" :color="getPassedColor(props.item.passed_precent)"></v-progress-linear></td>
          <td class="text-xs-left table-row-nowrap"><v-icon class="mr-2" small>email</v-icon><a :href="'mailto:'+props.item.email">{{ props.item.email }}</a></td>
          <td class="text-xs-left table-row-nowrap"><v-icon class="mr-2" small>{{ (inputPhoneMask(props.item.phone).length === 11 ? 'smartphone' : 'local_phone') }}</v-icon>{{  formatPhone(props.item.phone) }}</td>
        </tr>
      </template>
      <template slot="no-data">
      {{ this.loadingItemsText }}
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning" class="pa-1">
        Nie znaleziono żadnej firmy pasującej do "{{ search }}"
      </v-alert>
      <template slot="pageText" slot-scope="props">
         {{ props.pageStart }} - {{ props.pageStop }} z {{ props.itemsLength }}
      </template>
    </v-data-table>
    </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.type"
      :timeout="6000">{{ snackbar.text }}
      <v-btn dark flat @click="snackbar.show = false">X</v-btn>
    </v-snackbar>
    <v-dialog v-model="showDialog" persistent max-width="620px" height="500px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">assignment_ind</v-icon>Edytujesz klienta {{ company.groups ? '(grupa)' : '' }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-tabs v-model="editDialogTab" class="grey lighten-3 mb-2" centered>
            <v-tab v-for="tab in editDlgTabs" :key="tab.id" :href="'#'+tab.id">{{ tab.name }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="editDialogTab" class="pa-3">
      <v-tab-item value="tab-1">
    <v-text-field
      v-model="company.name"
      :label="company.groups ? 'Nazwa grupy' : 'Nazwa firmy'"
            :rules="noEmptyRules"
      required
    ></v-text-field>
    <v-text-field
        v-if="!company.groups"
      v-model="company.nip"
      label="Numer Identyfikacji Podatkowej"
            :rules="noEmptyRules"
      required
    ></v-text-field>
    <v-text-field
      v-model="company.alt_email"
      label="Ogólny adres-email"
            :rules="emailRules"
      required
    ></v-text-field>
    <v-text-field
    v-if="!company.groups"
      v-model="company.address"
      label="Ulica"
            :rules="noEmptyRules"
      required
    ></v-text-field>
    <v-text-field
      v-model="company.city"
          v-if="!company.groups"
      label="Miasto"
            :rules="noEmptyRules"
      required
    ></v-text-field>
      </v-tab-item>
      <v-tab-item value="tab-2">
              <v-alert :value="true" icon="info" class="info pa-2 mb-4 pl-3">
            Dane osoby która odpowiedzialna jest za szkolenia RODO.
        </v-alert>
    <v-text-field
      v-model="company.contact_person"
      label="Imię i nazwisko"
      required
      :rules="noEmptyRules"
    ></v-text-field>
    <v-text-field
      v-model="company.email"
      label="Adres e-mail"
      required
      :rules="emailRules"
    ></v-text-field>
    <v-text-field
      v-model="company.phone"
      label="Telefon"
      required
      :mask="inputPhoneMask(company.phone)"
      :rules="phoneRules"
    ></v-text-field>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-alert :value="true" icon="info" class="info pa-2 mb-4 pl-3">
            Wybierz procentowy próg poprawnych odpowiedzi dla tego klienta po uzyskaniu którego osoba zdająca egzamin go zaliczy.
        </v-alert>
        <span class="subheading">Ustaw wymagany próg procentowy:</span>
        <v-radio-group v-model="company.level" column required>
              <v-radio
                label="Bardzo wysoki poziom (90%)"
                color="green"
                :value="90"
              ></v-radio>
              <v-radio
                label="Wysoki poziom (80%)"
                color="yellow darken-2"
                :value="80"
              ></v-radio>
              <v-radio
                label="Średni poziom (70%)"
                color="orange darken-1"
                :value="70"
              ></v-radio>
              <v-radio
                label="Niski poziom (60%)"
                color="red lighten-1"
                :value="60"
              ></v-radio>
        </v-radio-group>
      </v-tab-item>
      <v-tab-item value="tab-4">
              <v-alert :value="true" icon="info" class="info pa-2 mb-4 pl-3">
            Domena w której będą generowane loginy importowanych pracowników.
        </v-alert>
    <v-text-field
      v-model="company.domain"
      label="Domena "
      :rules="domainRules"
      required
    ></v-text-field>
    <v-divider></v-divider>
    <v-alert :value="true" icon="info" class="info pa-2 mb-4 pl-3">
            Hasło autoryzujące wygenerowane loginy dla szkoleń {{ company.groups ? 'w tej grupie' : 'tego klienta' }}.
        </v-alert>
          <v-text-field
            v-model="company.password"
            required
            :append-icon="passwordShow ? 'visibility_off' : 'visibility'"
            :rules="passwordRules"
            :type="passwordShow ? 'text' : 'password'"
            label="Hasło"
            mask
            @click:append="passwordShow = !passwordShow"
          ></v-text-field>
      </v-tab-item>
      <v-tab-item value="tab-5">

         <v-switch class="ml-4"
      v-model="company.own_numbering"
      label="Własna numeracja dokumentów klienta"
      @click="company.add_up=company.own_numbering" :disabled="company.own_numbering > 0 && !(company.add_up > 0)"
    ></v-switch>

              <v-alert :value="true" icon="info" class="info pa-2 mb-1 pl-3">
                Numer startowy od którego będzie zaczynać się numeracja upoważnień
        </v-alert>
    <v-text-field
      v-model="company.starting_number"
      label="Numer początkowy nowych upoważnień "
      :rules="(company.own_numbering ? noEmptyRules : noRules)"
      :disabled="!company.own_numbering || (company.own_numbering&&company.starting_number > 0 && !company.add_up)"
      :required="company.own_numbering"
    ></v-text-field>
    <v-divider></v-divider>
     <v-alert :value="true" icon="info" class="info pa-2 mb-1 pl-3">
            Szablon numeru upoważnienia<br/>
              # - w szablonie oznacza numer upoważnienia<br/>
              % - w szablonie oznacza aktualny rok
    </v-alert>
    <v-text-field
      v-model="company.number_template"
      label="Szablon numeru upoważnienia"
      :rules="(company.own_numbering ? templateRules : noRules)"
      :disabled="!company.own_numbering"
      :required="company.own_numbering"
    ></v-text-field>
      </v-tab-item>
      <v-tab-item value="tab-6">
         <v-switch class="ml-4"
      v-model="company.gen_period_egzams"
      label="Automatyczne generowanie egzaminów okresowych"
    ></v-switch>
      <v-layout>
        <v-flex md6 class="pa-2">
          Co ile miesięcy mają być generowane egzaminy okresowe u  {{ company.groups ? 'tej grupy' : 'tego klienta' }}.
      <v-slider
        v-model="company.egzams_cycle"
        :tick-labels="cyclesLabels"
        :max="90"
        :min="30"
        :step="30"
        ticks="always"
        tick-size="2"
        :value="60"
         always-dirty
        thumb-labels="always"
        :disabled="!company.gen_period_egzams"
      ></v-slider>
        </v-flex>
        <v-flex md6 class="pa-2">
Po ilu tygodniach egzamin okresowy wygaśnie (nie będzie dostępny).
      <v-slider
        v-model="company.egzams_expiry"
        :tick-labels="expiryLabels"
        :max="28"
        :min="14"
        :step="7"
        ticks="always"
        tick-size="2"
        :value="28"
         always-dirty
        thumb-labels="always"
        :disabled="!company.gen_period_egzams"
      ></v-slider>
        </v-flex>
      </v-layout>
        <v-text-field class="ml-4 mr-4"
            v-model="company.egzams_period_email"
            :value="company.egzams_period_email"
            label="E-mail na który będą wysyłane powiadomienia o egzaminach"
            :disabled="!company.gen_period_egzams"
          ></v-text-field>
      </v-tab-item>
    </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red darken-1" flat @click="showDialog = false" :disabled="!valid">Anuluj</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="saveCompanyInfo" :disabled="!valid"> Zapisz zmiany <v-icon class="ml-2">save</v-icon></v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="addCustomerDlg.show" persistent max-width="560px" height="500px">
    <v-card>

              <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">assignment_ind</v-icon>Dodajesz klienta</span>
        </v-card-title>
        <v-divider></v-divider>
    <v-card-text class="mt-1">
 <v-form ref="form" v-model="addCustomerDlg.valid" lazy-validation>
    <v-text-field
      v-model="addCustomerDlg.name"
      :rules="nameRules"
      :counter="50"
      :label="addCustomerDlg.groups ? 'Nazwa grupy klientów' : 'Nazwa firmy'"
      box
      required
    ></v-text-field>
    <v-switch v-model="addCustomerDlg.groups" label="Grupa klientów"></v-switch>
    <v-text-field
      v-model="addCustomerDlg.nip"
      :counter="10"
      :rules="nipRules"
      v-if="!addCustomerDlg.groups"
      box
      label="Numer Identyfikacji Podatkowej (NIP)"
      required
    ></v-text-field>

  </v-form>
</v-card-text>
<v-divider></v-divider>
<v-card-actions>
    <v-btn @click="addCustomerDlg.show = false" color="red darken-1" flat>Anuluj</v-btn>
    <v-spacer></v-spacer>
    <v-btn
      flat
      :disabled="!addCustomerDlg.valid"
      @click="submit"
      color="green darken-1"
    >
      Dodaj {{ addCustomerDlg.groups ? 'grupe' : 'klienta' }} <v-icon class="ml-2">add_circle</v-icon>
    </v-btn>
</v-card-actions>
    </v-card>

    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    tab: 0,
    cyclesLabels: [
      'co jeden',
      'co dwa',
      'co trzy'
    ],
    expiryLabels: [
      'po dwóch',
      'po trzech',
      'po czterech'
    ],
    addCustomerDlg: {
      valid: true,
      show: false,
      nip: '',
      name: '',
      groups: false
    },
    noRules: [],
    nameRules: [
      v => !!v || 'Nazwa firmy jest wymagana',
      v => (v && v.length >= 5) || 'Nazwa firmy musi mieć minimum 5 znaków'
    ],
    noEmptyRules: [
      v => !!v || 'To pole jest wymagane'
    ],
    domainRules: [
      v => !!v || 'To pole jest wymagane',
      v => /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/gi.test(v) || 'Nieprawidłowy format domeny (np. domena.pl)'
    ],
    phoneRules: [
      v => /[0-9]+/.test(v) || 'Błędny numer telefonu',
      v => v.replace(/\s/, '').length === 9 || 'Numer telefonu musi mieć 9 liczb'
    ],
    emailRules: [
      v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g.test(v) || 'Podaj poprawny adres e-mail.'
    ],
    templateRules: [
      v => !!v || 'To pole jest wymagane',
      v => !/\s/g.test(v) || 'Szablon nie może zawierać spacji',
      v => /UP/.test(v) || 'Szablon musi zawierać frazę "UP"',
      v => /\#/.test(v) || 'Szablon musi zawierać znak #',
      v => /UP\/\#/.test(v) || 'Szablon numeracji nie może być taki sam jak domyślny'
 //    v => /\%/.test(v) || 'Szablon musi zawierać znak %', 
    ],
    nipRules: [
      v => !!v || 'NIP jest wymagany',
      v => (v && v.length === 10 && /[0-9]+/.test(v)) || 'NIP powinien mieć zawierać 10 liczb'
    ],
    passwordShow: false,
    passwordRules: [
      v => !!v || 'Podaj hasło autoryzujące dla pracowników tej firmy.',
      v => (/[0-9]+/.test(v) && /[a-z]+/.test(v) && /[A-Z]+/.test(v)) || 'Hasło powinno zawierać minimum 6 znaków, jedną literę (małą i dużą) oraz liczbę'
    ],
    valid: false,
    showDialog: false,
    editDialogTab: 'tab-1',
    editDlgTabs: [
      {id: 'tab-1', name: 'Ogólne'},
      {id: 'tab-2', name: 'Kontakt'},
      {id: 'tab-3', name: 'Poziom'},
      {id: 'tab-4', name: 'Ustawienia'},
      {id: 'tab-5', name: 'Upoważnienia'},
      {id: 'tab-6', name: 'Egzaminy'}
    ],
    company: {
      name: '',
      city: '',
      nip: '',
      address: '',
      alt_email: '',
      contact_person: '',
      email: '',
      phone: '',
      level: 80,
      own_numbering: false,
      starting_number: '',
      number_template: '',
      egzams_cycle: '',
      egzams_expiry: '',
      gen_period_egzams: false,
      egzams_period_email: ''
    },
    search: '',
    snackbar: {
      text: '',
      show: false,
      color: 'info'
    },
    loadingItemsText: '',
    loadingItems: true,
    dialog: false,
    headersOrigin: [
      {
        text: 'Nazwa firmy',
        align: 'left',
        value: 'name'
      },
      {
        text: 'Opóźnień',
        value: 'total_stuck',
        name: 'total_stuck',
        width: '30px',
        align: 'center',
        sortable: true
      },
      {
        text: 'Szkoleń',
        value: 'total_counter',
        name: 'total_counter',
        width: '30px',
        align: 'center',
        sortable: true
      },
      {
        text: 'Ukończonych',
        value: 'passed_precent',
        name: 'passed_precent',
        width: '120px'

      },
      {
        text: 'Kontaktowy adres e-mail',
        value: 'email',
        width: '180px'
      },
      {
        text: 'Telefon kontaktowy',
        width: '100px',
        value: 'phone'
      }
    ],
    clientsItems: []
  }),
  created () {
    this.initialize('permament')
  },
  computed: {
    filteredItems () {
      return this.clientsItems.filter((i) => {
        if (this.tab === 2) {
          return i
        }
        return false
      })
    },
    headers () {
      return this.headersOrigin
    }
  },
  methods: {
    inputPhoneMask (phone) {
      if (!phone) return ''
      phone = phone.replace('+48', '')
      phone = phone.replace(/[^0-9]+/ig, ' ').replace(/\s/g, '')
      if (phone.match(/(50|51|53|57|60|66|69|72|73|78|79|88)[0-9]{1}[\s]{0,1}[0-9]{3}[\s]{0,1}[0-9]{3}/ig)) {
        return '### ### ###'
      } else {
        return '## ### ## ##'
      }
    },
    formatPhone (phone) {
      phone = phone.replace(/[^0-9]+/ig, ' ').replace(/\s/g, '')
      var mask = this.inputPhoneMask(phone)
      var n = 0
      var formatted = ''
      for (var i = 0; i < mask.length; i++) {
        if (mask[i] === '#' && phone[n]) {
          formatted = formatted + phone[n]
          n++
        } else {
          formatted = formatted + mask[i]
        }
      }
      return formatted
    },
    truncateCustomerName (name) {
      name = name.toUpperCase()
      var nc = name.replace(/I|1|\.|,|'|"|\/|\\|\(|\)/g, '')
      var ratio = ((nc.length * 16) + ((name.length - nc.length) * 3)) / (name.length * 16)
      var characters = (this.$vuetify.breakpoint.width - 700) / (ratio * 16)
      var truncate = (characters < name.length + 1)
      return {
        original: name,
        name: (truncate ? name.substr(0, characters - 2) + '...' : name),
        isTruncated: truncate
      }
    },
    getPassedColor (precent) {
      if (precent >= 90) { return 'green' }
      if (precent >= 75) { return 'yellow darken-3' }
      if (precent >= 60) { return 'orange darken-2' }
      if (precent >= 45) { return 'red lighten-2' }
      return 'red darken-1'
    },
    showSnackbar (text, type) {
      this.snackbar = {
        show: true,
        text: text,
        type: type
      }
    },
    showEditDialog (customers_id, add_up) {
      this.company = this.clientsItems.find((c) => c.customers_id === customers_id)
      //   this.company.level = "80"
      this.company.add_up = add_up
      this.showDialog = true
      this.editDialogTab = '#tab-1'
    },
    submit () {
      if (this.$refs.form.validate()) {
        axios({
          url: this.$ROOT_PATH + '/customers/new',
          method: 'post',
          data: {
            name: this.addCustomerDlg.name,
            nip: this.addCustomerDlg.nip,
            groups: this.addCustomerDlg.groups
          },
          headers: { 'X-API-Token': this.$store.state.userCredentials.token }
        })
          .then(response => {
            this.clientsItems.push({
              customers_id: response.data.customers_id,
              name: response.data.name,
              nip: response.data.nip,
              city: '',
              address: '',
              alt_email: '',
              contact_person: '',
              email: '',
              phone: '',
              level: '',
              password: '',
              domain: '',
              starting_number: '',
              number_template: '',
              own_numbering: false,
              egzams_cycle: 30,
              egzams_expiry: 14,
              egzams_period_email: '',
              gen_egzams_period: 0,
              groups: this.addCustomerDlg.groups
            })
            this.addCustomerDlg.name = this.addCustomerDlg.nip = ''
            this.addCustomerDlg.show = false
            this.showSnackbar('Klient został dodany prawidłowo. Uzupełnij dane firmy.', 'success')
            this.showEditDialog(response.data.customers_id, true)
            this.initialize('rest')
          })
          .catch(error => {
            console.log(error)
            this.showSnackbar('Wystąpił błąd. (kod ' + error.response.data.status + ')')
          })
          .then(() => {

          })
      }
    },
    saveCompanyInfo () {
      axios({
        url: this.$ROOT_PATH + '/customers/change',
        method: 'post',
        data: {
          customers_id: this.company.customers_id,
          name: this.company.name,
          city: this.company.city,
          nip: this.company.nip,
          address: this.company.address,
          alt_email: this.company.alt_email,
          contact_person: this.company.contact_person,
          email: this.company.email,
          phone: this.company.phone,
          level: this.company.level,
          password: this.company.password,
          domain: this.company.domain,
          starting_number: this.company.starting_number,
          number_template: this.company.number_template,
          own_numbering: this.company.own_numbering,
          egzams_cycle: this.company.egzams_cycle,
          egzams_expiry: this.company.egzams_expiry,
          gen_period_egzams: this.company.gen_period_egzams,
          egzams_period_email: this.egzams_period_email
        },
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      })
        .then(response => {
          // this.initialize()
          this.showSnackbar("Dane '" + this.company.name + "' zostały zapisane prawidłowo", 'success')
          this.showDialog = false
        })
        .catch(error => {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        })
        .then(() => {

        })
    },
    initialize (filter) {
      this.loadingItems = true
      axios({
        url: this.$ROOT_PATH + '/customers/' + filter,
        method: 'get',
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      })
        .then(response => {
          this.loadingItemsText = 'Brak danych do wyświetlenia'
          this.clientsItems = response.data
        })
        .catch(error => {
          this.loadingItemsText = 'Błąd wczytywania danych'
        })
        .then(() => {
          this.loadingItems = false
        })
    }
  }
}
</script>

<style scoped>
.table-row-nowrap {
      white-space: nowrap;
}

</style>
