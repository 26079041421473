<template>

        <v-flex>
           <v-toolbar dense height="28" flat color="blue-grey lighten-5" class="mb-4">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip>
          <v-icon class="mr-2">school</v-icon>Szkolenia RODO</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-tooltip left>
     <v-btn class="mb-2 success" @click="showNewCourseDlg()" icon slot="activator"><v-icon>add_circle</v-icon></v-btn>
      <span>Dodaj nowe szkolenie</span>
      </v-tooltip>
    </v-toolbar>
   <v-progress-linear v-if="loadingItems"
        height="2"
      color="primary"
      indeterminate
    ></v-progress-linear>
        <v-alert v-if="courses.length === 0 && loadingItems === false" :value="true" icon="info" class="info">
           Aktualnie nie ma żadnych szkoleń w bazie danych. Kliknij znak "+" w prawym górnym rogu aby dodać nowe.
        </v-alert>
  <v-layout wrap>
    <v-flex md7 lg4 v-for="item in courses" :key="item.courses_id" md4 class="pa-2">
        <v-card class="card-course" :style="'border-top-color: ' + getCategoryColor(item.category_color)">
            <v-card-title class="headline">
               <span><strong>NR {{ item.courses_id }}</strong>&nbsp;/&nbsp;{{ item.name }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
<v-layout>
  <v-flex xs7 md7>             <span class="font-italic">Zakres szkolenia</span><br/>
    <span class="body-2"> {{ item.scope }} </span></v-flex>
  <v-flex xs5 md5 class="ml-2">    <span class="font-italic">Egzamin</span><br/>
    <span v-if="item.egzams_id < 1">nie ustawiono</span>
    <a :href="'/#/egzams/edit/' + item.egzams_id" class="body-2" v-if=" item.egzams_id > 0">NR {{ item.egzams_id }} / {{ egzams.find((e) => parseInt(item.egzams_id) === parseInt(e.egzams_id)) ? egzams.find((e) => parseInt(item.egzams_id) === parseInt(e.egzams_id)).name : '' }} </a></v-flex>
</v-layout>
            </v-card-text>
              <v-divider></v-divider>
            <v-card-actions class="pa-2">
              <v-chip :color="item.category_color ? item.category_color : 'grey'" label text-color="white">
                <span>
                  {{ (item.category_name ? item.category_name : 'Podstawowa') }}
                </span>
              </v-chip>
              <v-spacer></v-spacer>
               <v-btn class="primary lighten-1" :to="'/courses/edit/'+ item.courses_id " depressed>Edytuj szkolenie<v-icon class="ml-2">edit</v-icon></v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
  </v-layout>
    <v-snackbar
      v-model="snackbars.errorShow"
      color="error"
      :timeout="2000">Błąd wczytywania danych! Sprawdź połączenie z internetem
      <v-btn dark flat @click="snackbars.errorShow = false">X</v-btn>
    </v-snackbar>

<v-dialog v-model="egzamsLinkDialog.show" persistent max-width="600px" lazy>
    <v-card>
      <v-card-title primary-title class="title">Przypisz egzamin do szkolenia</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
    <v-select
      :items="egzams"
      label="Egzamin: "
      v-model="egzamsLinkDialog.egzams_id"
      prepend-icon="ballot"
      :menu-props="{ auto: true }"
      item-value="egzams_id"
      item-text="name"
      no-data-text="Dodaj najpierw egzamin by móc przypisać go do szkolenia."
    ></v-select>
    <v-divider></v-divider>
    </v-card-text>
    <v-card-actions>
    <v-btn @click="egzamsLinkDialog.show = false" flat color="red darken-1">Anuluj</v-btn>
    <v-spacer></v-spacer>
        <v-btn flat
        :loading="loadingItems"
        color="green darken-1"
        @click="linkEgzam()">
      Przypisz egzamin  <v-icon flat class="ml-2">save</v-icon>
    </v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="newCourseDlg.show" persistent max-width="600px">
 <v-form ref="form" v-model="newCourseDlg.valid" lazy-validation>
    <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="mr-2">school</v-icon> Dodajesz szkolenie</span>
        </v-card-title>
<v-divider></v-divider>
    <v-card-text class="mt-1">
    <v-text-field
      v-model="newCourseDlg.name"
      :rules="newCourseDlg.nameRules"
      :counter="50"
      box
      label="Nazwa szkolenia (widoczna tylko w systemie)"
      required
    ></v-text-field>
    <v-text-field
      v-model="newCourseDlg.scope"
      :rules="newCourseDlg.scopeRules"
      box
      label="Zakres szkolenia (widoczny w dokumentacji)"
      required
    ></v-text-field>

    <v-select
    :items="[{ courses_id: 0, name: '--- nie kopiuj żadnego szkolenia ---' }].concat(this.courses)"
    label="Skopiuj strony szkolenia: "
    v-model="newCourseDlg.copy_courses_id"
    prepend-icon="school"
    :menu-props="{ auto: true }"
    item-value="courses_id"
    item-text="name"
    no-data-text="Brak szkoleń"
  ></v-select>
</v-card-text>
<v-divider></v-divider>
<v-card-actions>
      <v-btn @click="newCourseDlg.show = false" color="red darken-1" flat>Anuluj</v-btn>
<v-spacer></v-spacer>
        <v-btn
      color="green darken-1"
      :disabled="!newCourseDlg.valid"
      @click="submit" flat
    >
      Dodaj szkolenie
      <v-icon class="ml-2">add_circle</v-icon>
    </v-btn>
</v-card-actions>
    </v-card>
 </v-form>
  </v-dialog>

  </v-flex>
</template>
<script>

import axios from 'axios'
export default {
  data () {
    return {

      snackbar: {
        text: '',
        show: false,
        color: 'info'
      },
      valid: false,
      egzams: [],
      egzamsLinkDialog: {
        show: false,
        egzams_id: -1,
        courses_id: -1
      },
      newCourseDlg: {
        show: false,
        valid: true,
        copy_courses_id: 0,
        name: '',
        nameRules: [
          v => !!v || 'Podaj nazwę szkolenia',
          v => (v && v.length >= 5) || 'Nazwa szkolenia powinna mieć minimum 5 znaków'
        ],
        scope: '',
        scopeRules: [
          v => !!v || 'Podaj zakres szkolenia',
          v => (v && v.length >= 5) || 'Nazwa szkolenia powinna mieć minimum 5 znaków'
        ]
      },
      loadingItems: false,
      snackbars: {
        errorShow: false
      },
      search: '',
      headers: [
        { text: 'ID',
          value: 'courses_id',
          sortable: false
        },
        {
          text: 'Nazwa szkolenia',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        { text: 'Zakres szkolenia', value: 'scope', sortable: true },
        { text: 'Egzamin', value: 'name', sortable: false },
        { text: '', value: 'name', sortable: false }
      ],
      courses: []

    }
  },
  computed: {
    selectCopyCourse: function () {
      var headers = [{ state: 'Żaden', abbr: 0 }]
      this.courses.forEach((i) => {
        headers.push({
          state: i.name,
          abbr: i.courses_id
        })
      })
      return headers
    }
  },
  created () {
    this.loadCourses()
  },
  methods: {
    getCategoryColor (color) {
      if (color && color.length > 2) {
        return color
      }
      return '#888'
    },
    showNewCourseDlg (copy_courses_id) {
      this.newCourseDlg.show = true
      this.newCourseDlg.copy_courses_id = 0
    },

    submit () {
      if (this.$refs.form.validate()) {
        axios({
          url: this.$ROOT_PATH + '/courses/new',
          method: 'post',
          data: {
            name: this.newCourseDlg.name,
            scope: this.newCourseDlg.scope,
            copy_courses_id: this.newCourseDlg.copy_courses_id
          },
          headers: { 'X-API-Token': this.$store.state.userCredentials.token }
        })
          .then(response => {
            this.newCourseDlg.name = this.newCourseDlg.scope = ''
            this.newCourseDlg.show = false
            this.newCourseDlg.copy_courses_id = 0
            this.courses.push({
              courses_id: response.data.courses_id,
              name: response.data.name,
              scope: response.data.scope
            })
          })
          .catch(error => {
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
            this.newCourseDlg.copy_courses_id = 0
          })
      }
    },

    showEgzamLinkDialog (egzams_id, courses_id) {
      this.egzamsLinkDialog.courses_id = courses_id
      this.egzamsLinkDialog.egzams_id = egzams_id
      this.egzamsLinkDialog.show = true
    },
    showSnakbar (text, type) {
      this.snackbar.show = false
      this.snackbar = { show: true, text: text, type: type }
    },
    linkEgzam () {
      this.loadingItems = true
      axios({
        url: this.$ROOT_PATH + '/courses/linkegzam',
        method: 'post',
        headers: { 'X-API-Token': this.$store.state.userCredentials.token },
        data: {
          courses_id: this.egzamsLinkDialog.courses_id,
          egzams_id: this.egzamsLinkDialog.egzams_id
        }
      })
        .then(response => {
          this.egzamsLinkDialog.show = false
          this.courses.find((c) => c.courses_id === response.data.courses_id).egzams_id = response.data.egzams_id
          this.showSnakbar('Egzamin został przypisany do wybranego szkolenia', 'success')
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
        .then(() => {
          this.loadingItems = false
        })
    },

    loadCourses () {
      this.loadingItems = true
      axios.all([
        axios({url: this.$ROOT_PATH + '/egzams', method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token }}),
        axios({url: this.$ROOT_PATH + '/courses', method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token }})
      ])
        .then(axios.spread((firstResponse, secondResponse) => {
          this.courses = secondResponse.data
          this.egzams = firstResponse.data
          this.loadingItems = false
        }))
        .catch(error => {
          this.showSnakbar('Błąd! Wczytanie szkolenia nie powiodło się.', 'error')
          this.loadingItems = false
        })
    }
  }
}
</script>
<style scoped>
.course-content-text img {
    width:100% !important;
    max-width:900px !important;
    margin:10px auto !important;
    border:1px solid #666 !important;

}
.table-courses-shortcolumm {
    width:30px;
}
.table-row-nowrap {
      white-space: nowrap;
}
.card-course {
  border-top:7px solid #999;
}
.v-card__actions {
  background-color:#f9f9f9;
}
</style>
