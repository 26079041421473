<template>
<div>

    <v-toolbar dense height="28" class="mb-3" flat color="blue-grey lighten-5">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip>
          <v-icon class="mr-2">assignment_ind</v-icon>Konto {{ customer.groups ? 'grupowe' : 'klienta' }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-tooltip left><v-btn :color="btnPermamentColor" icon @click="markAsPermament()" slot="activator"><v-icon color="white">check</v-icon></v-btn><span>Oznacz jako stałego klienta</span></v-tooltip>
<v-divider vertical inset class="mx-2"></v-divider>
      <v-tooltip left><v-btn class="info"  @click="exportSelectDlg.show = true" :disabled="countExportData === 0"
      icon slot="activator"><v-icon color="white">assignment_returned</v-icon></v-btn><span>Eksport danych</span></v-tooltip>
      <v-tooltip left><v-btn class="info" icon @click="importSelectDlg.show = true" slot="activator"><v-icon>backup</v-icon></v-btn><span>Import danych</span></v-tooltip>
<v-divider vertical inset class="mx-2"></v-divider>
      <v-tooltip left><v-btn class="error" icon @click="customerRemoveDlg.show = true" slot="activator"><v-icon>delete</v-icon></v-btn><span>Usuń {{ customer.groups ? 'tą grupę' : 'tego klienta' }}</span></v-tooltip>
    </v-toolbar>

 <v-layout row wrap>

      <v-flex d-flex xs6 sm2 md2 class="text-xs-right">
        <v-card class="ma-0 pa-0">
          <v-card-text class="logo-background" right>
        <h2 class="display-3 mt-3 blue-grey--text">{{ passedCount }}%</h2>
        <span class="font-weight-bold body-2 mb-2">{{ (this.clientsItems.length > 0 ? this.clientsItems.filter((c) => c.status === 1 || c.status === 3).length : 0) }}</span>{{ '/' + this.clientsItems.length }}<br/>
        szkoleń pracowników<br />{{ customer.groups ? 'w grupie' : 'klienta' }} zostało<br />
        ukończonych
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 sm10 md10 child-flex>
        <v-card>
            <v-card-text>
              <h3 class="title"><v-icon>contacts</v-icon> {{ customer.name }} </h3>
              <v-layout>
                <v-flex sm6 md6>
                <p>
                  <br /><strong> Wskaźnik postępu: </strong><v-chip v-if="passedRatio" class="font-weight-bold" :color="getPassesColor(passedRatio*100)" text-color="white" small>{{ Math.round(passedRatio*14) }} {{ Math.round(passedRatio)*14 === 1 ? "dzień" : "dni" }}</v-chip>
                  <v-chip small color="red lighten-1 font-weight-bold" text-color="white" v-if="employessDelayed > 0">
                  <v-avatar class="red darken-1" small><v-icon small color="white">notification_important</v-icon></v-avatar>
                  <span class="font-weight-bold">{{ employessDelayed }}</span>
                  </v-chip> <span v-if="courseTime > 0" >(śr. na szkolenie: <span class="font-weight-bold">{{ courseTime }}</span> min.)</span>
                </p>
                <br/> <strong> Os. kontakt.: </strong> {{ customer.contact_person }}
                <br/> <strong> Telefon: </strong> {{ customer.phone }}
                <br/> <strong> E-mail: </strong> <a :href="'mailto: ' + customer.email "> {{ customer.email }} </a>
                <br/> <span v-if="!customer.groups"><strong> Miasto: </strong> {{ customer.city }} </span>
                </v-flex>
                <v-flex sm6 md6>
                  <vue-chartist :data="chartData" :options="options" type="Bar" class="pa-0 ma-0 mt-4"></vue-chartist>
                </v-flex>
              </v-layout>
            </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-progress-linear v-model="passedCount" height="10" class="ma-0" :color="getPassedColor(passedCount)" :intermediate="loadingItems"></v-progress-linear>
  <v-tabs
    left
    class="mt-1" :disabled="loadingItems">
    <v-tab
        :key="1"
        :href="'#tab-1'">
     <v-icon class="mr-2">school</v-icon>  Wszystkie szkolenia
    </v-tab>
    <v-tab
        :key="2"
        :href="'#tab-2'">
   <v-icon class="mr-2">ballot</v-icon>   Egzaminy okresowe
    </v-tab>
    <v-tab
        :key="3"
        :href="'#tab-3'">
    <v-icon class="mr-2">leaderboard</v-icon>  Statystyka {{ customer.groups ? 'grupy' : 'klienta' }}
    </v-tab>
    <v-spacer></v-spacer>
    <v-text-field
    class="elevation-0"
    v-model="search"
    append-icon="search"
    label="Wyszukaj pracownika po imieniu, nazwisku oraz emailu..."
    hide-details
  ></v-text-field>
    <v-tabs-items>
      <v-tab-item
        :key="1"
        :value="'tab-1'"
      >
    <v-data-table
      :headers="headers"
      :items="clientsItems"
      :loading="loadingItems"
      :search="search"
      :rows-per-page-items="[50,100]"
      :custom-sort="customSort"
      rows-per-page-text="Liczba wierszy na stronę"
      class="elevation-1 mt-1"
    >
      <template slot="items" slot-scope="props">
      <tr>
        <td class="text-xs-left body-2 table-row-nowrap" :style="'background-color:#eee;border-left:4px solid '+ getStatusColor(props.item.status)">
        <abbr :title="props.item.courses_name ? props.item.courses_name : 'Szkolenie stacjonarne'">
          <strong> {{props.item.courses_id > 0 ? '#'+props.item.courses_id+'&#160;' : ''}}{{props.item.courses_ext_id > 0 ? '#'+props.item.courses_ext_id+'&#160;' : ''}}{{ ((props.item.course_minutes > 2 && 120 > props.item.course_minutes  && props.item.status === 0) ? 'Trwa...' : getStatusText(props.item.status)) }} </strong>
        </abbr>
        <span v-if="props.item.course_minutes > 0"> ({{ props.item.course_minutes }}m.)</span>
        </td>
        <td class="text-xs-left body-2 font-weight-bold"> <span v-if="props.item.status != 0">{{ props.item.resoult }}% </span></td>
        <td class="text-xs-left table-row-nowrap font-weight-bold">{{ props.item.up_number}}</td>
        <td class="text-xs-left table-row-nowrap body-2">
            <v-chip small color="red lighten-1 font-weight-bold mr-2" text-color="white" v-if="props.item.remider_count > 2 && props.item.status !== 1 && props.item.status !== 3">
              <v-avatar class="red darken-1" small><v-icon small color="white">notification_important</v-icon></v-avatar>
              <span class="font-weight-bold">{{ props.item.remider_count }}</span>
            </v-chip>
 <v-menu :close-on-content-click="true" :nudge-width="200" :nudge-height="300" transition="slide-x-transition" v-model="props.item.menu"
      :close-delay="1" right class="pa-0"><a slot="activator" color="indigo" dark>{{ (props.item.name.length ? props.item.name : '*Brak*').toUpperCase() }}</a>
      <v-card class="pa-0">
        <v-list>
          <v-list-tile dark>
            <v-list-tile-content>
              <v-list-tile-title class="font-weight-bold">{{(props.item.name.length ? props.item.name : '*Brak*').toUpperCase() }}</v-list-tile-title>
              <v-list-tile-sub-title class="employee-position-label">{{ props.item.position.substr(0,30) + (props.item.position.length > 30 ? '...' : '') }}</v-list-tile-sub-title>
            </v-list-tile-content>
          <v-list-tile-action>
           <strong :style="'color: ' + getStatusColor(props.item.status) ">{{ getStatusText(props.item.status) }}</strong> <br/>
            </v-list-tile-action>
          </v-list-tile>
          <!--v-list-tile>
                 <v-list-tile-content>
            <v-list-tile-title>
                 </v-list-tile-content>
          </v-list-tile--->
          <v-divider></v-divider>
                    <v-list-tile v-if="props.item.status === 0  || props.item.status === 2">
            <v-list-tile-action>
<v-btn @click="sendEmail(props.item.employess_id);" color="green darken-2" slot="activator" icon dark ripple>
                <v-icon dark>send</v-icon>
              </v-btn>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Wyślij e-mail</v-list-tile-title>
              <v-list-tile-sub-title>Ponownie wyślij wiadomość e-mail do szkolenia</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-if="props.item.status === 1" >
                      <v-list-tile-action>
<v-btn dark @click="showCustomerRollbackDlg(props.item.employess_id)" color="orange darken-2" slot="activator" icon ripple>
              <v-icon dark>remove_circle_outline</v-icon></v-btn>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Cofnij upoważnienie</v-list-tile-title>
              <v-list-tile-sub-title>Upoważnienie pracownika zostanie cofnięte</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile>
            <v-list-tile-action>
<v-btn @click="showEmployeeEditDlg(props.item.employess_id)" color="orange darken-2" slot="activator" icon dark ripple>
                <v-icon dark>edit</v-icon></v-btn>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Edytuj pracownika</v-list-tile-title>
              <v-list-tile-sub-title>Zmień dane tego pracownika</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-action>
<v-btn @click="deleteEmployee(props.item.employess_id)" color="red darken-2" slot="activator" icon dark ripple>
                <v-icon dark>delete</v-icon></v-btn>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Usuń pracownika</v-list-tile-title>
              <v-list-tile-sub-title>Ten pracownik nie będzie mógł odbyć szkolenia</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-menu>
<span v-if="props.item.login_at.substr(0,4) !== '0000' && props.item.egzam_ended_at.substr(0,4) === '0000' &&  props.item.login_timeout_minutes > 1">
      <v-icon dark color="orange">campaign</v-icon>
      ({{ props.item.login_timeout_minutes > 60 ? Math.round(props.item.login_timeout_minutes/60,0) + 'g.' : props.item.login_timeout_minutes + 'm.' }} temu)
</span>
        </td>
        <td class="text-xs-left table-row-nowrap"><v-icon class="mr-2" small>email</v-icon><a :href="'mailto:'+props.item.email">{{ props.item.email }}</a></td>
        <td class="text-xs-left">{{ props.item.position }}</td>
      </tr>
      </template>
      <template slot="no-data">
      {{ this.loadingItemsText }}
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning" class="pa-1">
        Nie znaleziono żadnej firmy pasującej do "{{ search }}"
      </v-alert>
      <template slot="pageText" slot-scope="props">
         {{ props.pageStart }} - {{ props.pageStop }} z {{ props.itemsLength }}
      </template>
    </v-data-table>
  </v-tab-item>

        <v-tab-item
        :key="2"
        :value="'tab-2'">
    <v-alert v-if="customer.gen_period_egzams"
      :value="true"
      type="info"
      class="pa-2 mt-2">
      Egzaminy okresowe są generowane co określony w ustawieniach klienta cyklicznie czas (<strong>{{ customer.egzams_cycle }}</strong> dni)
    </v-alert>
    <v-alert v-else
      :value="true"
      type="warning"
      class="pa-2 mt-2">
      Egzaminy okresowe sa WYLACZONE - mozesz to zmienic w ustawieniach {{ customer.groups ? 'grupy' : 'klienta' }}
    </v-alert>
<v-card>
  <v-card-text>
  <v-layout>
  <v-flex md4 class="mr-2">
        <v-select
          :disabled="customer.gen_period_egzams !== 1"
          v-model="customer.egzams_id"
          :items="egzamsSelect('base')"
          label="Egzamin podstawowy"
          item-text="state"
          item-value="abbr"
          @change="savePeriodEgzam('base', customer.egzams_id)"
        ></v-select>
  </v-flex>
  <v-flex md4 class="mr-2">
        <v-select
          :disabled="customer.gen_period_egzams !== 1"
          v-model="customer.egzams_ext_id"
          :items="egzamsSelect('extended')"
          label="Egzamin rozszerzający"
          item-text="state"
          item-value="abbr"
          @change="savePeriodEgzam('extended', customer.egzams_ext_id)"
        ></v-select>
  </v-flex>
  <v-flex md4>
  <span class="font-italic caption">Ustaw egzamin podstawowy i/lub egzamin rozrzerzający, z których zostaną wygenerowane pytania egzaminu okresowego w następnym cyklu.</span>
  </v-flex>
</v-layout>
</v-card-text>
</v-card>
<v-container class="ml-10 mr-10" style="max-width: 1200px;">
    <v-timeline dense clipped class="ml-8">
<v-timeline-item
          v-for="egzam in periodEgzams"
          :key="egzam.egzams_period_id"
          small
          color="BLUE">
          <v-card><v-card-text>
          <v-layout>
            <v-flex xs1 md1><v-progress-circular
      :rotate="360"
      :size="50"
      :width="8"
      :value="((egzam.employee_count/clientsItems.length)*100)"
      color="teal"
      class="mt-1">{{ value }}</v-progress-circular></v-flex>
            <v-flex xs2 md2 class="justify-xs-center pt-1"><span :class="(parseInt(egzams.days_to_expiry) > 0 ? 'display-1' : 'body-2 lighten-2') + ' font-weight-light'">
              <span class="display-1">{{ egzam.employee_count }}</span><br /> pracownik{{ egzam.employee_count > 1 ? 'ów' : ''}}</span>
            </v-flex>
            <v-flex xs2 md2 class="justify-xs-center pt-1">
                <span class="display-1"><span class="grey--text" v-if="parseInt(egzam.days_to_expiry) < 0">wygasł</span>
                <span v-if="parseInt(egzam.days_to_expiry) > 0">{{ parseInt(egzam.days_to_expiry) }} {{ (egzam.days_to_expiry > 1 ? ' dni' : ' dzień') }}<br />{{ (egzam.days_to_expiry > 1 ? 'pozostało' : 'pozostał') }} </span></span>
            </v-flex>
            <v-flex xs5 md5 class="pr-3 pl-1" style="border-left:1px solid #eee"><span class="title">{{ egzam.base_name }}{{ egzam.egzams_ext_id > 0 ? ', ' + egzam.ext_name : '' }}</span>
            <br/><a target="_blank" :href="`https://szkolenia.odosc.pl/#/e/` + egzam.customers_id + `/` + egzam.egzams_period_id">https://szkolenia.odosc.pl/#/e/{{ egzam.customers_id }}/{{ egzam.egzams_period_id }}</a>
            <br/>Utworzony <span class="font-weight-bold">{{ egzam.date.substr(0,10) }}</span></v-flex>
            <v-flex xs1 md2 text-xs-right><v-btn n class="primary" :href="$ROOT_PATH + `/customers/period/export/` + egzam.egzams_period_id + '/' + customer.token" flat>Eksportuj wyniki</v-btn></v-flex>
          </v-layout>
          </v-card-text></v-card>
        </v-timeline-item>
    </v-timeline>
  </v-container>
      </v-tab-item>
              <v-tab-item
        :key="2"
        :value="'tab-3'">

      </v-tab-item>
</v-tabs-items>
  </v-tabs>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.type"
      :timeout="4000"
    >
      {{ snackbar.text }}
      <v-btn
        dark
        flat
        @click="snackbar.show = false"
      >
      </v-btn>
    </v-snackbar>
<v-dialog v-model="customerRollbackDlg.show" persistent max-width="500px">
    <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon>remove_circle_outline</v-icon> Cofnij upoważnienie do przetwarzania danych</span>
        </v-card-title>
                <v-divider></v-divider>
        <v-card-text>
    <v-alert
      :value="true"
      color="info"
      icon="info"
      class="pa-2 pl-3 mb-3">Wybierz datę od kiedy zostało cofnięte upoważnienie do przetwarzania danych osobowych dla tego pracownika.
    </v-alert>
      <v-date-picker
        class="elevation-0"
        color="blue-grey lighten-3"
        required
        full-width
        landscape
        show-current
        :first-day-of-week="1"
        locale="pl-PL"
        v-model="customerRollbackDlg.rollback_at"
        year-icon="mdi-calendar-blank"
      ></v-date-picker>
        </v-card-text>
        <v-divider></v-divider>
      <v-card-actions>
           <v-btn color="red darken-1"  flat @click.native="customerRollbackDlg.show = false">Anuluj</v-btn>
        <v-spacer></v-spacer>
          <v-btn color="green darken-1"  @click="onRollBack()" flat>Cofnij upoważnienie<v-icon class="ml-2">remove_circle_outline</v-icon></v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>

<v-dialog v-model="customerImportDialog.show" persistent max-width="600px">
    <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon>backup</v-icon>  Import osób do szkoleń online</span>
        </v-card-title>
                <v-divider></v-divider>
        <v-card-text>
    <v-alert
      :value="true"
      color="info"
      icon="info"
      class="pa-2 pl-3 mt-1">
      Wybierz szkolenie do którego będą przypisani importowani pracownicy
    </v-alert>

    <v-select
      :items="[{ name: 'Żadne', courses_id: 0 }].concat(courses.filter((i) => i.categories_id === 0))"
      label="Szkolenie podstawowe:"
      v-model="customerImportDialog.courses_id"
      prepend-icon="school"
      :menu-props="{ auto: true }"
      item-value="courses_id"
      item-text="name"
      class="mt-2"
      :disabled="customerImportDialog.onylExtendedCourse"
      required
      no-data-text="Brak szkoleń. Dodaj szkolenie, aby móc importować pracowników firmy."
    ></v-select>
   <v-switch
      v-model="customerImportDialog.onylExtendedCourse" class="ma-0 pa-0 ml-3"
        label="Tylko szkolenie rozszerzające"
    ></v-switch>
        <v-select
      :items="[{ name: 'Żadne', courses_id: 0 }].concat(courses.filter((i) => i.categories_id !== 0))"
      label="Szkolenie rozszerzające:"
      v-model="customerImportDialog.courses_ext_id"
      prepend-icon="school"
      :menu-props="{ auto: true }"
      item-value="courses_id"
      item-text="name"
      required
      no-data-text="Brak szkoleń rozszerzających. Przypisz branże do wybranego szkolenia."
    ></v-select>

    <v-alert
      :value="true"
      color="warning"
      icon="warning"
      class="pa-2 pl-3">Importowany plik musi być w formacie CSV
    </v-alert>

<form id="employessImportFile">
      <input type="hidden" name="courses_id"      :value="customerImportDialog.courses_id" />
      <input type="hidden" name="courses_ext_id"  :value="customerImportDialog.courses_ext_id" />
      <input type="hidden" name="customers_id"    :value="customer.customers_id" />
      <input type="hidden" name="only_extended"   :value="customerImportDialog.onylExtendedCourse" />
      <uploadbtn accept=".csv, *.xls" required></uploadbtn>
      <!--v-text-field prepend-icon="alternate_email" single-line label="Domena w jakiej mają być generowane fikcyjne adresy e-mail"></v-text-field-->
</form>
<a @click="csvRequirmentsDlg.show = true">Zobacz wymagania dotyczące importowanego pliku CSV</a>
        </v-card-text>
         <v-divider></v-divider>
      <v-card-actions>
           <v-btn color="red darken-1" :disabled="loadingItems === true" flat @click.native="customerImportDialog.show = false">Anuluj</v-btn>
        <v-spacer></v-spacer>
          <v-btn color="green darken-1"  @click="onSubmit()" :disabled="((customerImportDialog.courses_id === 0 && !customerImportDialog.onylExtendedCourse) && (customerImportDialog.courses_ext_id === 0  && customerImportDialog.onylExtendedCourse)) || loadingItems" :loading="loadingItems" flat>Importuj <v-icon class="ml-2">save</v-icon></v-btn>
      </v-card-actions>
      </v-card>

</v-dialog>

<v-dialog v-model="importSelectDlg.show" persistent max-width="650px">
  <v-card>
    <v-card-title primary-title>
      <span class="title"><v-icon>backup</v-icon> Wybierz rodzaj importu</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
  <v-layout row wrap>
    <v-flex xs12 sm6 class="my-2 px-1">
      <v-card align="center">
        <v-card-title primary-title align="center" class="my-2 py-2" >
          <v-icon large>earth</v-icon>
          <h3 class="headline">Szkolenia stacjonarne</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text align="center">
          Importuj dane osób przeszkolonych podczas szkolenia stacjonarnego z Ochrony Danych Osobowych,
          którym zostało wydane upoważnienie i/lub zaświadczenie do przetwarzania danych osobowych.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions  class="text-xs-center">
          <v-spacer></v-spacer>
          <v-btn @click="importSelectDlg.show = false; showImportStationaryDlg()" color="blue" flat>Importuj</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 sm6 class="my-2 px-1">
      <v-card>
        <v-card-title primary-title align="center" class="my-2 py-2">
          <v-icon large>bank</v-icon>
          <h3 class="headline">Szkolenia online</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text align="center">
          Importuj dane osób do przeszkolenia online z Ochrony Danych Osobowych. Po przejściu szkolenia i zdaniu egzaminu osobom tym
          zostanie wydane upoważnienie i/lub zaświadczenie do przetwarzania danych osobowych.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-xs-center">
          <v-spacer></v-spacer>
          <v-btn @click="importSelectDlg.show = false; showImportDlg()" color="blue" flat>Importuj</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
      </v-card-text>
    </v-card>
 </v-dialog>
<v-dialog v-model="customerImportStationaryDialog.show" persistent max-width="680px">
    <v-card>
      <v-card-title primary-title>
        <span class="title"><v-icon>backup</v-icon> Import szkoleń stacjonarnych</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
    <v-textarea
        v-model="customerImportStationaryDialog.scope"
        :rules="customerImportStationaryDialog.questionRules"
        label="Zakres szkolenia" box rows="2" required auto-grow :counter="255"
    ></v-textarea>
<v-layout>
  <v-flex xs12 sm7 class="my-2 px-1">
    <v-alert
      :value="true"
      color="warning"
      icon="warning"
      class="pa-2 pl-3">Importowany plik musi być w formacie CSV
    </v-alert>
    <form id="employessImportFile2">
      <input type="hidden" name="test_confirmation" :value="customerImportStationaryDialog.testConfirmation" />
      <input type="hidden" name="allowance_type" :value="customerImportStationaryDialog.allowanceType" />
      <input type="hidden" name="customers_id" :value="customer.customers_id" />
      <input type="hidden" name="course_date" :value="customerImportStationaryDialog.courseDate" />
      <input type="hidden" name="scope" :value="customerImportStationaryDialog.scope" />
      <uploadbtn accept=".csv, *.xls" required></uploadbtn>
    </form>
    <a @click="csvRequirmentsDlg.show = true">Zobacz wymagania dotyczące importowanego pliku CSV</a>
    <v-checkbox label="Zaświadczenie o odbyciu szkolenia" v-model="customerImportStationaryDialog.testConfirmation" :checked="true"></v-checkbox>
    <v-radio-group class="mt-0 pt-0" mandatory v-model="customerImportStationaryDialog.allowanceType">
      <v-radio label="Bez upoważnienia" value="0"></v-radio>
      <v-radio label="Upoważnienie zwykłe" value="1" ></v-radio>
      <v-radio label="Upoważnienie zmieniające" value="2" ></v-radio>
    </v-radio-group>
  </v-flex>
  <v-flex xs12 sm5 class="my-2 px-1">
    <v-alert
      :value="true"
      color="info"
      icon="info"
      class="pa-2 pl-3">Data odbytego szkolenia
    </v-alert>
    <v-date-picker
        class="elevation-0"
        color="blue-grey lighten-3"
        required
        no-title
        full-width
        :first-day-of-week="1"
        locale="pl-PL"
        v-model="customerImportStationaryDialog.courseDate" >
    </v-date-picker>
  </v-flex>
</v-layout>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="red darken-1" :disabled="loadingItems === true" flat @click.native="customerImportStationaryDialog.show = false">Anuluj</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1"  @click="onSubmitStationary()" :disabled="loadingItems === true || (customerImportStationaryDialog.testConfirmation < 1 && customerImportStationaryDialog.allowanceType < 1)" :loading="loadingItems" flat>Importuj <v-icon class="ml-2">save</v-icon></v-btn>
    </v-card-actions>
  </v-card>

</v-dialog>

  <v-dialog v-model="customerRemoveDlg.show" peristent max-width="520px">
 <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">assignment_ind</v-icon>Usuń {{customer.groups ? 'grupę' : 'klienta' }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
Czy na pewno chcesz usunąć {{ customer.groups ? 'tą grupę' : 'tego klienta' }} z systemu? Spowoduje to usunięcie wszystkich danych związanych z pracownikami i ewidencją z wyłączeniem wydanych dokumentów (upoważnienia i zaświadczenia). <br/><br/>
     <u>Nie będzie możliwości przywrócenia danych.</u>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat @click.native="customerRemoveDlg.show = false"  color="green darken-1"> Anuluj </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat @click="deleteCustomer($route.params.id)" color="red darken-1">Usuń {{ customer.groups ? 'grupę' : 'tego klienta' }}<v-icon class="ml-2">delete</v-icon></v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

  <v-dialog v-model="exportSelectDlg.show" peristent max-width="420px">
 <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">assignment_returned</v-icon>Exportuj dane z systemu</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text align="center">
  <!--v-icon size="68">assignment_returned</v-icon>

  <v-select
  v-model="exportValue"
  :items="exportTypes"
  item-value="value"
  item-text="state"
  label="Eksport"
></v-select-->
  <v-layout>
  <v-flex xs12 sm6 class="my-2 px-3">
      <v-tooltip left><v-btn class="info" :href="$ROOT_PATH + '/customers/raport/export/' + this.$route.params.id + '/' +  this.customer.token" :disabled="countExportData === 0"
      icon slot="activator"><v-icon class="big">assignment_returned</v-icon></v-btn><span>Eksportuj raport do Excela</span></v-tooltip><br/> <p class="font-weight-medium">Eksportuj raport do Excela</p>
  </v-flex>
  <v-flex xs12 sm6 class="my-2 px-3">
      <v-tooltip left><v-btn class="success" :href="$ROOT_PATH + '/customers/export/' + this.$route.params.id + '/' +  this.customer.token" :disabled="countExportData === 0"
      icon slot="activator"><v-icon class="big">assignment_returned</v-icon></v-btn><span>Eksportuj ewidencję do Excela</span></v-tooltip><br/>  <p class="font-weight-medium">Eksportuj ewidencję do Excela</p>
  </v-flex>
  </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
               <v-spacer></v-spacer>
          <v-btn flat @click.native="exportSelectDlg.show = false"  color="green darken-1"> Zamknij </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>

  <v-dialog v-model="employeeEditDlg.show" peristent max-width="450px">

 <v-card>

        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">edit</v-icon>Edytuj pracownika</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
      :value="true"
      color="warning"
      icon="warning"
      class="pa-2 pl-3">Zmienione zostaną także dane w wystawionych już dokumentach tego pracownika.
    </v-alert>
          <v-text-field
        class="mt-3"
        v-model="employeeEditDlg.fullname"
        label="Imię i nazwisko:"
      ></v-text-field>
      <v-text-field
        class=""
        v-model="employeeEditDlg.position"
        label="Stanowisko:"
      ></v-text-field>
      <v-text-field :disabled="employeeEditDlg.status != 0"
        class=""
        v-model="employeeEditDlg.email"
        label="E-mail:"
      ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
        <v-card-actions>
        <v-btn flat @click.native="employeeEditDlg.show = false"  color="red darken-1"> Zamknij </v-btn>
               <v-spacer></v-spacer>
   <v-btn @click="saveEmployee()" flat color="green darken-1">Zapisz <v-icon>save</v-icon></v-btn>
      </v-card-actions>
          </v-card>
    </v-dialog>
    <v-dialog v-model="importDoublesDlg.show" peristent max-width="420px">
 <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">assignment_returned</v-icon>Plik zawiera duplikaty</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text align="center">
    <v-alert
      :value="true"
      color="error"
      icon="error"
      class="pa-2 pl-3">
        W systemie istnieją już podani poniżej pracownicy oczekujący na ukończenie wybranego szkolenia.  </v-alert>
<v-layout>
    <v-data-table
      :items="importDoublesDlg.duplicated"
      hide-actions
      hide-headers
      item-key="egzams_answers_id"
    >
      <template slot="items" slot-scope="props">
     <td class="table-questions-shortcolumm">
        {{props.item.email}}
      </td>
      </template>
      </v-data-table>
</v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
               <v-spacer></v-spacer>
          <v-btn flat @click.native="importDoublesDlg.show = false"  color="green darken-1"> Zamknij </v-btn>

        </v-card-actions>
      </v-card>
  </v-dialog>
  <v-dialog v-model="downloadFilesDlg.show" peristent max-width="420px">
 <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">assignment_returned</v-icon>Pobieranie dokumentów</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text align="center">
Czy chcesz pobrać wszystkie dokumenty do druku wystawione dla zaimportowanych pracowników?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>

          <v-btn flat @click.native="downloadFilesDlg.show = false"  color="red darken-1"> Anuluj </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="downloadFiles()" :loading="downloadFilesDlg.downloading"  color="green darken-1"> Pobierz dokumenty</v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>

    <v-dialog v-model="csvRequirmentsDlg.show" peristent max-width="500px">
 <v-card>
        <v-card-title primary-title>
          <span class="title">Importowany plik CSV - wymagania</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-alert
      :value="true"
      color="info"
      icon="info"
      class="pa-2 pl-3">
        Importowany plik w formacie CSV musi posiadać kodowanie UTF-8, separatorem kolumn jest przecinek lub średnik. Plik powinien zawierać kolumny w podanej kolejności dotyczące poszczególnych pracowników.  </v-alert>
<p class="mt-2">Kolejność kolumn w pliku CSV:
<ol><li>Imię i nazwisko</li>
<li>Stanowisko pracownika</li>
<li>Adres e-mail (jeżeli nie posiada pozostawić puste)</li>
</ol></p>
<p class="mt-2">
Jeżeli adres e-mail nie został podany zostanie wygenerowany automatycznie (fałszywy) w domenie firmowej klienta.
</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>

          <v-btn flat @click.native="csvRequirmentsDlg.show = false"  color="green darken-1"> Zamknij </v-btn>
          <v-spacer></v-spacer>
      </v-card-actions>
      </v-card>
  </v-dialog>

    </div>
</template>

<script>
import axios from 'axios'
import uploadbtn from '../../components/upload-btn.vue'
import VueChartist from 'v-chartist'
import '../../../node_modules/chartist/dist/chartist.min.css'
import moment from 'moment'

export default {
  data () {
    return {
      on: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      showRollback: false,
      panel: [true],
      exportTypes: [
        { state: 'Eksportuj ewidencję', value: 'ewidencja' },
        { state: 'Eksportuj raport', value: 'raport' },
        { state: 'Eksportuj opóźnione', value: 'opoznione' }
      ],
      exportValue: '',
      data: {
        labels: [],
        series: [],
        datasets: [
          {
            label: '',
            backgroundColor: '#f87979',
            data: []
          }
        ]
      },
      options: {
        // distributeSeries: false
      },
      courses: [],
      egzams: [],
      customer: {
        name: 'Ładowanie danych...'
      },
      employeeEditDlg: {
        fullname: '',
        position: '',
        email: '',
        status: -1,
        employee_id: 0,
        show: false
      },
      csvRequirmentsDlg: {
        show: false
      },
      importSelectDlg: {
        show: false
      },
      exportSelectDlg: {
        show: false
      },
      importDoublesDlg: {
        show: false,
        duplicated: []
      },
      customerImportDialog: {
        show: false,
        counter: 0,
        courses_ext_id: 0,
        showInfo: false,
        courses_id: 0,
        onylExtendedCourse: false,
        backwardPreviousAllowance: false
      },
      downloadFilesDlg: {
        show: false,
        downloading: false,
        tokens: ''
      },
      customerImportStationaryDialog: {
        show: false,
        counter: 0,
        scope: 'Ochrona danych osobowych na podstawie RODO oraz ustawy o ochronie danych osobowych.',
        showInfo: false,
        courses_id: 0,
        allowanceType: 0,
        testConfirmation: 0,
        courseDate: new Date().toISOString().substr(0, 10),
        questionRules: [
          v => !!v || 'Wpisz zakres szkolenia.',
          v => (v && v.length >= 10) || 'Zakres szkolenia nie powinien być krótszy niż 10 znaków.',
          v => (v && v.length <= 255) || 'Zakres szkolenia nie może być dłuższy niż 255 znaków.'
        ]
      },

      search: '',
      snackbar: {
        text: '',
        show: false,
        color: 'info'
      },
      customerRollbackDlg: {
        rollback_at: '',
        show: false,
        employess_id: 0
      },
      customerRemoveDlg: {
        show: false,
        customers_id: 0
      },
      loadingItemsText: '',
      loadingItems: true,
      dialog: false,
      periodHeaders: [
        {
          text: 'Data egzaminu',
          value: 'date',
          maxWidth: 50
        },
        {
          text: 'Data wygaśnięcia',
          align: 'left',
          value: 'expiry_date',
          maxWidth: 50
        },
        {
          text: 'Zakres egzaminu okresowego',
          align: 'left',
          value: ''
        },
        {
          text: 'Link',
          align: 'left',
          value: ''
        },
        {
          text: 'Procent',
          align: 'left',
          value: ''
        },
        {
          text: 'Ukończyło prqcowników',
          value: ''
        }
      ],
      headers: [
        {
          text: 'Szkolenie',
          value: 'status',
          width: '150px',
          sortable: true
        },
        {
          text: 'Wynik',
          value: 'resoult',
          maxWidth: 50
        },
        {
          text: 'Upoważnienie',
          align: 'left',
          value: 'up_number',
          maxWidth: 100
        },
        {
          text: 'Pracownik',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Adres e-mail',
          value: 'email'
        },
        {
          text: 'Stanowisko pracy',
          value: 'position'
        }
      ],
      periodEgzams: [],
      clientsItems: []
    }
  },
  components: {
    uploadbtn,
    'vue-chartist': VueChartist
  },

  created () {
    this.initialize()
  },

  computed: {
    courseTime: function () {
      var timecount = 0
      var count = 0
      this.clientsItems.forEach((i) => {
        if (i.course_minutes > 1 && i.course_minutes < 200) {
          timecount += i.course_minutes
          count++
        }
      })
      return Math.round(timecount / count)
    },
    employessDelayed: function () {
      return this.clientsItems.filter((i) => i.remider_count > 2 && i.status !== 1 && i.status !== 3).length
    },
    increaseRatio: function () {
      var increaseRatio = this.clientsItems.filter((e) => {
        var doc = moment(e.created_at)
        var now = moment((new Date()).getDate())
        var duration = moment.duration(doc.diff(now))
        return duration.asDays() < 60
      }).length
      return increaseRatio
    },
    passedHistory: function () {
      return []
    },
    btnPermamentColor: function () {
      return this.customer.permament ? 'orange' : 'grey'
    },
    passedCount: function () {
      return (this.clientsItems.length > 0 ? Math.round((this.clientsItems.filter((e) => (e.status === 1)).length / this.clientsItems.filter((e) => (e.status !== 3)).length) * 100) : 0)
    },
    countExportData: function () {
      return this.clientsItems.filter((e) => (e.status === 1 || e.status === 3)).length
    },
    passedRatio: function () {
      var ratio = this.clientsItems.filter((e) => (e.status !== 1 && e.status !== 3)).length / this.getPassesIn(14)
      ratio = (ratio === Infinity || isNaN(ratio) ? 0 : ratio)
      return Number(ratio).toFixed(3)
    },
    chartData: function () {
      var td = {
        labels: [],
        datasets: [],
        series: [
          [],
          []
        ]
      }
      var d = new Date()
      var passedList = this.clientsItems.filter((e) => e.status === 1)
      var total = 28
      for (var i = 0; i < total; i++) {
        d.setDate(d.getDate() - 1)
        var day = d.getDate()
        var passedCount = passedList.filter(e => (moment(e.hasOwnProperty('up_created_at') ? e.up_created_at : '1999-01-01').format('YYYY-MM-DD') === moment(d).format('YYYY-MM-DD'))).length
        td.labels.push(day)
        td.series[0].push(passedCount)
        td.datasets.push({
          label: day,
          backgroundColor: 'blue',
          data: passedCount
        })
      }
      return td
    },
    periodEgzamsHeaders: function () {
      var headers = []
      this.periodEgzams.forEach((i) => {
        headers.push({ text: i.date })
      })
      return headers
    }
  },

  methods: {
    getPassesIn (days) {
      days = days > 28 ? 28 : days // nie bedzie w innym wypadku dzialac poprawnie
      var d = new Date()
      var passesCount = 0
      var passedList = this.clientsItems.filter((e) => e.status === 1)
      for (var i = 0; i < days; i++) {
        d.setDate(d.getDate() - 1)
        var day = d.getDate()
        day = String(parseInt(day) < 10 ? '0' + day : day)
        passesCount += passedList.filter(e => (e.up_created_at ? e.up_created_at.substr(8, 2) : '-1') === day).length
      }
      return passesCount
    },
    getPassesColor (precent) {
      if (precent >= 120) { return 'red lighten-1' }
      if (precent >= 80) { return 'orange darken-2' }
      if (precent >= 50) { return 'yellow darken-2' }
      return 'green lighten-1'
    },
    getPassedColor (precent) {
      if (precent >= 90) { return 'green' }
      if (precent >= 70) { return 'orange darken-1' }
      if (precent >= 60) { return 'red lighten-2' }
      return 'red darken-1'
    },
    egzamsSelect (type) {
      var headers = [{ state: 'Żaden', abbr: 0 }]
      this.egzams.forEach((i) => {
        if ((type === 'base' && i.categories_id === 0) ||
            (type === 'extended' && i.categories_id > 0)) {
          headers.push({ state: i.name, abbr: i.egzams_id })
        }
      })
      return headers
    },
    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        if (index === 'status') {
          var wages = [1, 2, 0, 3]
          if (!isDesc) {
            return wages[b.status] > wages[a.status] ? -1 : 1
          } else {
            return wages[a.status] > wages[b.status] ? -1 : 1
          }
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },

    showSnakbar (text, type) {
      this.snackbar = { show: false }
      this.snackbar = { show: true, text: text, type: type }
    },

    saveEmployee () {
      axios.all([
        axios({ url: this.$ROOT_PATH + '/customers/employee/save',
          method: 'post',
          data: {
            employee_id: this.employeeEditDlg.employee_id,
            fullname: this.employeeEditDlg.fullname,
            position: this.employeeEditDlg.position,
            email: this.employeeEditDlg.email
          },
          headers: { 'X-API-Token': this.$store.state.userCredentials.token } })
      ])
        .then(axios.spread((firstResponse) => {
          this.showSnakbar('Dane pracownika zostały zapisane.', 'success')
          this.employeeEditDlg.show = false
          let employee = this.clientsItems.find((e) => e.employess_id === firstResponse.data.employee_id)
          employee.name = this.employeeEditDlg.fullname
          employee.position = this.employeeEditDlg.position
          employee.email = this.employeeEditDlg.email
        }))
        .catch(error => {
          console.log(error)
        })
    },

    markAsPermament () {
      axios.all([
        axios({ url: this.$ROOT_PATH + '/customer/permament',
          method: 'post',
          data: {
            customers_id: this.customer.customers_id,
            permament: this.customer.permament === 1 ? 0 : 1
          },
          headers: { 'X-API-Token': this.$store.state.userCredentials.token } })
      ])
        .then(axios.spread((firstResponse) => {
          this.showSnakbar(!this.customer.permament ? 'Oznaczona klienta jako stałą współpracę.' : 'Usunięto klienta z stałych klientów.', 'success')
          this.customer.permament = this.customer.permament === 1 ? 0 : 1
        }))
        .catch(error => {
          console.log(error)
        })
    },

    savePeriodEgzam (type, egzams_id) {
      axios({ url: this.$ROOT_PATH + '/customers/periodegzams/save',
        method: 'post',
        data: {
          customers_id: this.customer.customers_id,
          egzams_id: egzams_id,
          type: type
        },
        headers: { 'X-API-Token': this.$store.state.userCredentials.token }
      }).then(axios.spread((firstResponse) => {
        this.showSnakbar('Ustawienia egzaminu zostały zapisane', 'success')
      })).catch(error => {
        console.log(error)
      })
    },

    raportExport () {
      this.showSnakbar('Pobieranie raportu...', 'success')
      axios.all([
        axios({ url: this.$ROOT_PATH + '/customers/raport/export/' + this.$route.params.id + '/' + this.customer.token,
          method: 'get',
          data: {
            customers_id: this.customer.customers_id
          },
          responseType: 'blob',
          headers: { 'X-API-Token': this.$store.state.userCredentials.token } })
      ])
        .then(axios.spread((firstResponse) => {
          const FileDownload = require('js-file-download')
          FileDownload(firstResponse.data, 'report_' + (new Date()).toLocaleDateString() + '.csv')
        }))
        .catch(error => {
          console.log(error)
        })
    },
    initialize () {
      this.loadingItems = true
      axios.all([
        axios({ url: this.$ROOT_PATH + '/customer/' + this.$route.params.id, method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token } }),
        axios({ url: this.$ROOT_PATH + '/employess/' + this.$route.params.id, method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token } }),
        axios({ url: this.$ROOT_PATH + '/courses', method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token } }),
        axios({ url: this.$ROOT_PATH + '/periodegzams/' + this.$route.params.id, method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token } }),
        axios({ url: this.$ROOT_PATH + '/egzams', method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token } })
      ])
        .then(axios.spread((firstResponse, secondResponse, thirdResponse, fourthResponse, fivethResponse) => {
          this.loadingItemsText = 'Brak danych do wyświetlenia'
          this.customer = firstResponse.data
          this.clientsItems = secondResponse.data
          this.courses = thirdResponse.data
          this.periodEgzams = fourthResponse.data
          this.egzams = fivethResponse.data
        }))
        .catch(error => {
          this.loadingItemsText = 'Błąd wczytywania danych'
          console.log(error)
        })
        .then(() => {
          this.loadingItems = false
        })
    },

    getStatusText (status, course_minutes) {
      if (course_minutes > 3 && course_minutes < 120) return 'Trwa...'
      var statusText = ['Oczekuje', 'Zdany', 'Nie zdany', 'Cofnięte']
      return statusText[status]
    },
    getStatusColor (status) {
      var statusColor = ['#F57C00', '#43A047', '#e51915', '#FED772']
      return statusColor[status]
    },
    deleteEmployee (employeeId) {
      axios({
        url: this.$ROOT_PATH + '/customers/employee/delete',
        method: 'post',
        data: {employess_id: employeeId},
        headers: { 'X-API-Token': this.$store.state.userCredentials.token }
      }).then(response => {
        this.loadingItems = false
        this.clientsItems.splice(this.clientsItems.findIndex((i) => i.employess_id === employeeId), 1)
        this.showSnakbar(response.data.success, 'success')
      }).catch(error => {
        this.loadingItems = false
        this.showSnakbar(error.response.data.error, 'error')
      })
    },

    deleteCustomer (customers_id) {
      axios({
        url: this.$ROOT_PATH + '/customers/delete',
        method: 'post',
        data: {customers_id: customers_id},
        headers: { 'X-API-Token': this.$store.state.userCredentials.token }
      }).then(response => {
        this.customerRemoveDlg.show = false
        //  this.clientsItems.splice(this.clientsItems.findIndex((i) => i.employess_id === employess_id), 1);
        this.showSnakbar(response.data.success, 'success')
        this.$router.push('/customers')
      }).catch(error => {
        this.customerRemoveDlg.show = false
        this.showSnakbar(error.response.data.error, 'error')
      })
    },

    sendEmail (employess_id) {
      this.loadingItems = true
      axios({
        url: this.$ROOT_PATH + '/customers/sendemail',
        method: 'post',
        data: {employess_id: employess_id},
        headers: { 'X-API-Token': this.$store.state.userCredentials.token }
      })
        .then(response => {
          this.loadingItems = false
          this.clientsItems.filter((c) => c.employess_id === response.data.employee_id).status = 0
          this.showSnakbar(response.data.success, 'success')
        })
        .catch(error => {
          this.loadingItems = false
          this.showSnakbar(error.response.data.error, 'error')
        })
    },
    onSubmit () {
      this.loadingItems = true
      let formData = new FormData(document.querySelector('#employessImportFile'))
      axios.post(this.$ROOT_PATH + '/customers/employess/import', formData, {
        headers: {'X-API-Token': this.$store.state.userCredentials.token, 'Content-Type': 'multipart/form-data'}
      })
        .then(response => {
          this.loadingItems = false
          this.customerImportDialog.showInfo = true
          this.customerImportDialog.show = false
          this.customerImportDialog.counter = response.data.counter
          let employess = response.data.employess
          for (let i = 0; i < employess.length; i++) {
            this.clientsItems.push(employess[i])
          }
          this.showSnakbar('Zaimportowano z powodzeniem rekordów: ' + response.data.counter, 'success')
          this.initialize()
        })
        .catch(error => {
          if (error.response.status === 410) {
            this.importDoublesDlg.show = true
            this.importDoublesDlg.duplicated = error.response.data.duplicated
          }
          this.customerImportDialog.show = false
          this.loadingItems = false
          console.log(error.response)
          this.showSnakbar(error.response.data.error ? error.response.data.error : 'Wystapił błąd podczas importowania pliku', 'error')
        })
    },

    onSubmitStationary () {
      this.loadingItems = true
      let formData = new FormData(document.querySelector('#employessImportFile2'))

      axios.post(this.$ROOT_PATH + '/customers/employess/import/stationary', formData, {
        headers: {'X-API-Token': this.$store.state.userCredentials.token, 'Content-Type': 'multipart/form-data'}
      })
        .then(response => {
          this.customerImportStationaryDialog.allowanceType = 0
          this.customerImportStationaryDialog.testConfirmation = 0
          this.loadingItems = false
          this.customerImportStationaryDialog.showInfo = true
          this.customerImportStationaryDialog.show = false
          this.customerImportDialog.counter = response.data.counter
          let employess = response.data.employess
          for (let i = 0; i < employess.length; i++) {
            this.clientsItems.push(employess[i])
          }
          this.showSnakbar('Zaimportowano z powodzeniem rekordów: ' + response.data.counter, 'success')
          this.initialize()
          this.downloadFilesDlg.tokens = response.data.tokens
          this.showDownloadImportedDlg()
        })
        .catch(error => {
          this.loadingItems = false
          console.log(error)
          this.showSnakbar(error.response.data.error ? error.response.data.error : 'Wystapił błąd podczas importowania pliku', 'error')
        })
    },

    showImportStationaryDlg () {
      this.showImportDlg.backwardPreviousAllowance = false
      this.showImportDlg.courses_id = 0
      this.customerImportStationaryDialog.show = true
      this.showImportStationaryDlg.testConfirmation = true
    },

    showImportDlg () {
      this.showImportDlg.backwardPreviousAllowance = false
      this.showImportDlg.courses_id = 0
      this.customerImportDialog.show = true
    },
    showEmployeeEditDlg (employee_id) {
      this.employeeEditDlg.show = true
      this.employeeEditDlg.employee_id = employee_id
      this.employeeEditDlg.fullname = this.clientsItems.find((e) => e.employess_id === employee_id).name
      this.employeeEditDlg.position = this.clientsItems.find((e) => e.employess_id === employee_id).position
      this.employeeEditDlg.email = this.clientsItems.find((e) => e.employess_id === employee_id).email
      this.employeeEditDlg.status = this.clientsItems.find((e) => e.employess_id === employee_id).status
    },

    showCustomerRollbackDlg (employess_id) {
      this.customerRollbackDlg.employess_id = employess_id
      this.customerRollbackDlg.show = true
      this.customerRollbackDlg.rollback_at = new Date().toISOString().substr(0, 10)
    },
    onRollBack () {
      axios.all([
        axios({ url: this.$ROOT_PATH + '/customers/rollback',
          method: 'post',
          data: {
            employess_id: this.customerRollbackDlg.employess_id,
            rollback_at: this.customerRollbackDlg.rollback_at
          },
          headers: { 'X-API-Token': this.$store.state.userCredentials.token } })
      ])
        .then(axios.spread((firstResponse) => {
          this.clientsItems.find((e) => e.employess_id === firstResponse.data.employess_id).status = 3
          this.showSnakbar('Upoważnienie do przetwarzania danych osobowych zostało cofnięte.', 'success')
          this.customerRollbackDlg.employess_id = 0
          this.customerRollbackDlg.show = false
        }))
        .catch(error => {
          console.log(error)
        })
    },

    showDownloadImportedDlg () {
      this.downloadFilesDlg.show = true
    },

    downloadFiles () {
      this.downloadFilesDlg.downloading = true
      this.showSnakbar('Pobieranie dokumentów...', 'success')
      axios({
        url: this.$ROOT_PATH + '/documents/pdf', // your url
        method: 'POST',
        responseType: 'blob', // important
        data: { token: this.downloadFilesDlg.tokens }
      }).then((response) => {
        var fileDownload = require('js-file-download')
        fileDownload(response.data, 'dokumenty_do_druku_' + (new Date()).toLocaleDateString() + '.pdf')
        this.downloadFilesDlg.downloading = false
        this.downloadFilesDlg.show = false
      }).catch(error => {
        this.downloadFilesDlg.downloading = false
        console.log(error)
        this.showSnakbar('Wystapił błąd podczas pobierania pliku', 'error')
      })
    }
  }

}
</script>

<style>
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke:#1E88E5 !important;
}
.logo-background {
      background:url('https://a.odosc.pl/v1/assets/firm-paper2.jpg') 0px 10px no-repeat;background-size:70%;
}
.employee-position-label::first-letter {
  transform: uppercase;
}
.employee-position-label {
  transform: lowercase;
}
.table-row-nowrap {
      white-space: nowrap;
}
.fullname-format::first-letter {
  transform: uppercase;
}
.fullname-format {
  transform: lowercase;
}
</style>
