<template>
<v-layout fill-height column>
    <v-flex>
 <div id="content-top">
<v-card
    class="mx-auto courseBackground"
    max-width="700"
  >
      <div class="text-xs-center pa-10 mt-2">
   <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kancelaria Bezpieczeństwa" width="330" />
    </div>

    <v-card-title class="title font-weight-regular grey--text justify-space-between text-xs-center">
  Egzamin okresowy RODO
    </v-card-title>
        <v-card-text>
                    <v-divider></v-divider>
    <v-window v-model="step" >
      <v-window-item v-for="(question,i) in questions" :key="question.egzams_questions_id" :value="i">
<h4 class="title mt-3 mb-3"> {{ question.text }} </h4>

             <v-checkbox v-for="answer in answers.filter((a) => a.egzams_questions_id === question.egzams_questions_id)"
                        :key="answer.egzams_answers_id"
                        :value="1"
                        v-model="answer.is_true"
                        class="pa-0 ma-2"
                        hide-details
                        :label="answer.text"
                        :change="questionAnswered = true"
            ></v-checkbox>
            <!-- powinno sprawdzac czy jakakolwiek odpowiedz jest zaznaczona -->
      </v-window-item>
    </v-window>
        </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="text-lg-center">
    <span class="body-2">Pytanie <strong>{{ step+1 }}</strong>/{{steps}}</span>
    <v-spacer></v-spacer>
      <v-btn
        v-if="step < steps-1"
        color="primary"
        depressed
        @click="nextStep"
        :loading="loadingItems"
        :disabled="loadingItems"
      >
       Następne<v-icon class="ml-2">chevron_right</v-icon>
      </v-btn>
      <v-btn
        v-if="step === steps-1"
        color="primary"
        depressed
        @click="checkAnswers"
        :loading="loadingItems"
        :disabled="loadingItems"
      >
       Zakończ<v-icon class="ml-2">thumb_up_alt</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
      <v-dialog
        v-model="dialogWelcome"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
      <v-card  class="align-center justify-center egzamIntroContent">
             <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kanelaria Bezpieczeństwa" width="350" class="logo" />

          <!--div style="height:50px" class="mt-3">
             <v-select
             v-model="language"
             :items="languagesItems"
             :label="translation.labelLanguage"
             item-text="state"
             item-value="abbr"
             prepend-icon="language"
             @change="loadEgzamPages($route.params.eid, language)"
           >
           <template slot="selection" slot-scope="{ item, parent, selected }">
             <v-list-tile-content>
                 <span class="title">{{ item.state }}</span>
             </v-list-tile-content>
             <v-spacer></v-spacer>
             <country-flag :country="(item.abbr.toLowerCase() === 'en' ? 'gb' : item.abbr)" shadow class="ma-0" />
           </template>
           <template slot="item" slot-scope="{ index, item }">
             <v-list-tile-content>
                 <span class="title">{{ item.state }}</span>
             </v-list-tile-content>
             <v-spacer></v-spacer>
             <country-flag :country="(item.abbr.toLowerCase() === 'en' ? 'gb' : item.abbr)" shadow class="ma-0" />
           </template>
         </v-select>
          </div-->

             <h1 class="headline mt-4">Egzamin okresowy</h1>
            <p class="mt-2" style="max-width:600px;text-align:center">Dziękujemy za przystąpienie do egzaminu! Jego celem jest okresowe sprawdzenie
             wiedzy na temat RODO. Za każdą poprawną odpowiedź otrzymasz 1 punkt.<br/><br/>
              <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout>
        <v-flex xs12 sm6 md6>
          <v-text-field class="mr-1"
            ref="firstname_input"
            v-model="firstname"
            label="Imię*"
            placeholder=""
            required
            :rules="noEmptyRules"
          ></v-text-field>
        </v-flex>
                <v-flex xs12 sm6 md6>
          <v-text-field
            v-model="lastname"
            label="Nazwisko*"
            placeholder=""
            required
            :rules="noEmptyRules"
          ></v-text-field>
        </v-flex>
        </v-layout>
          <v-text-field
            v-model="section"
            :disabled="firstname.length < 3 || lastname.length < 3 "
            label="Oddział"
            placeholder=""
          ></v-text-field>
        </v-form>
            </p>
            <p class="justify-xs-center">
                  <strong>Pamiętaj!</strong> Więcej niż jedna odpowiedź na pytanie może być prawidłowa. Powodzenia!
                  </p>
                  <p style="max-width:600px;text-align:center" class="align-center text-align-right">
 <v-btn class="success" :disabled="!valid" @click="startEgzam()">Zaczynamy! <v-icon class="ml-2">play_circle_filled</v-icon></v-btn>
                      </p>
      </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFinish"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
      <v-card  class="align-center justify-center">
             <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kanelaria Bezpieczeństwa" width="350" class="mb-4"/>

            <div style="text-align:center;" class="mt-10">
                    <h2 class="headline mt-6 text-xs-center" style="text-align:center;" color="green">Dziękujemy za ukończenie egzaminu!</h2>

                <p style="max-width:600px;text-align:center;color:green" class="align-center mb-6 mt-2">
                    Uzyskałeś/-aś <br />
                    <span class="display-3">{{ resoult }}%</span> <br/>
                    poprawnych odpowiedzi<br /><br />
                </p>
                <p>
                <v-btn @click="showAnswers()" color="primary"><v-icon class="mr-2">question_answer</v-icon> Zobacz odpowiedzi</v-btn> <br/>
                </p>
             </div>
             <!--v-btn to="/identify" color="primary">Następna osoba</v-btn-->
      </v-card>
      </v-dialog>
        <v-dialog v-model="dialogAnswers"
        fullscreen
        transition="dialog-bottom-transition"
        scrollable>
              <v-card style="max-width:800px;margin:0 auto;">
        <v-card-title primary-title>
          <span class="title">Twoje odpowiedzi egzaminacyjne</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
      <div class="mt-5" v-for="(question,i) in questions" :key="question.egzams_questions_id" :value="i" >
<span class="body mb-3"> {{ question.text }} </span>
             <v-checkbox v-for="answer in answers.filter((a) => a.egzams_questions_id === question.egzams_questions_id)"
                        :key="answer.egzams_answers_id"
                        :value="1"
                        v-model="answer.is_true"
                        class="pa-0 ma-2"
                        hide-details
                        disabled
                        :prepend-icon="!isValidAnswer(answer.egzams_answers_id,answer.is_true) ? 'close' : 'check'"
                        :label="answer.text"
                        :color="isValidAnswer(answer.egzams_answers_id,answer.is_true) ? 'black' : 'red'"
            ></v-checkbox>
    <v-divider></v-divider>
      </div>
      </v-card-text>
      <v-card-actions>
      <v-btn color="primary" @click="dialogAnswers = false" flat>Powrót do wyniku egzaminu</v-btn>
      </v-card-actions>
      </v-card>
      </v-dialog>
  </div>
    </v-flex>
</v-layout>

</template>

<script>

import axios from 'axios'
import '../../node_modules/chartist/dist/chartist.min.css'

export default {

  data: () => ({
    firstname: '',
    lastname: '',
    section: '',
    valid: false,
    languagesItems: [
      { state: 'Polski', abbr: 'PL' },
      { state: 'English', abbr: 'EN' }
    ],
    language: 'PL',
    noEmptyRules: [
      v => !!v || 'To pole jest wymagane.',
      v => (v && v.length >= 3) || 'Wypełnij to pole.'
    ],
    passed: false,
    resoultTest: 0,
    resoult: 0,
    dialogWelcome: false,
    dialogFinish: false,
    dialogAnswers: false,
    courseDialog: false,
    answersValid: [],
    answers: [],
    questions: [],
    choosenAnswers: [],
    egzam: { scope: 'brak' },
    loadingItems: false,
    allAnswers: 0,
    egzamAnswered: false,
    questionAnswered: false,
    documents: {
      az: '',
      up: '',
      kl: ''
    },

    e1: 1,
    step: 0,
    steps: 10,

    i18n: {
      pl: {
        labelLanguage: 'Wersja językowa / Language',
        labelLogin: 'Adres e-mail',
        labelPassword: 'Hasło',
        hintEmail: 'Login osoby przystępującej do szkolenia (otrzymany od pracodawcy)',
        hintPassword: 'Hasło autoryzujące szkolenie (otrzymane od pracodawcy)',
        titleChooseLanguage: 'Wybierz język / Choose language'
      },
      en: {
        labelLanguage: 'Language / Wersja językowa',
        labelLogin: 'E-mail address',
        labelPassword: 'Password',
        hintEmail: 'Login of person taking the training (given by employer)',
        hintPassword: 'Course authorization password (given by employer)',
        titleChooseLanguage: 'Choose language / Wybierz język',
        titleAttemptingPerson: 'Person taking the training',
        titlePasswordRequired: 'Authorization password',
        snackCredentialsRequired: 'E-mail address and password is required',
        snackNoCoursesAsigned: 'Currently there is no training asigned to this e-mail address. If given e-mail address is correct please inform your employer about it.',
        snackWrongCredentials: 'Wrong e-mail address and/or password has been given',
        passwordRequired: 'Password is needed for authorization',
        passwordRequirments: 'Password should contain at least 6 characters, one letter (small and big), a number',
        returnBtn: 'Back',
        forwardBtn: 'Next',
        confirmIdentBtn: 'Confirm identity'
      }
    }
  }),
  mounted () {
    // this.loadCoursePages(this.$route.params.id)
    this.loadEgzamPages(this.$route.params.eid, 'pl')
    this.$refs.firstname_input.focus()
    this.dialogWelcome = true
  },
  computed: {
    currentTitle () {
      return ('Pytanie ' + (this.step + 1) + '/' + this.steps)
    },
    translation: function () {
      if (this.language.toLowerCase() === 'pl') {
        return this.i18n.pl
      } else {
        return this.i18n.en
      }
    }
  },
  methods: {
    nextStep: function () {
      if (this.questionAnswered) {
        this.questionAnswered = false
        if (this.step === this.steps) {
          this.dialogFinish = true
        } else {
          this.step++
        }
        this.stepChanged(this.step)
        this.resoultTest = this.checkResoult()
      }
    },
    answer () {
      if (this.questionAnswered) {
        return false
      }
    },

    showAnswers () {
      this.dialogAnswers = true
    },
    isValidAnswer (egzamsAnswersId, isTrue) {
      for (var i = 0; i < this.answersValid.length; i++) {
        if (this.answersValid[i].egzams_answers_id === egzamsAnswersId &&
                    Boolean(this.answersValid[i].is_true) === Boolean(isTrue)) {
          return true
        }
      }
      this.answersValid.forEach((a) => {
        if (Boolean(isTrue) === Boolean(a.is_true) &&
                        a.egzams_answers_id === egzamsAnswersId) {
          return true
        }
      })
      return false
    },
    startEgzam () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.dialogWelcome = false
    },
    checkAnswers () {
      this.loadingItems = true

      axios({
        url: this.$ROOT_PATH + '/egzams/period/check',
        method: 'post',
        data: {
          egzams_period_id: this.$route.params.eid,
          answers: this.answers,
          employee: {
            firstname: this.firstname,
            lastname: this.lastname,
            section: this.section
          }
        }
      })
        .then(firstResponse => {
          this.resoult = firstResponse.data.resoult
          this.passed = firstResponse.data.passed
          this.dialogFinish = true
          this.loadingItems = false
          this.answersValid = firstResponse.data.answers_valid
        })
        .catch(error => {
          this.loadingItems = false
          console.log(error)
          this.showSnakbar('Błąd! Wczytanie pytań egzaminu nie powiodło.', 'error')
        })
    },
    checkResoult () {
      let egzamResoult = 0
      let allAnswers = 0
      this.questions.forEach((q) => {
        this.answers.forEach((a) => {
          if (q.egzams_questions_id === a.egzams_questions_id) {
            egzamResoult += (((typeof a.is_true === 'undefined' ? false : a.is_true) != a.is_true) ? 0 : 1)
            allAnswers++
          }
        })
      })
      this.resoult = parseInt(Math.floor((egzamResoult / (allAnswers)) * 100))
    },
    loadEgzamPages (egzams_id, language) {
      this.loadingItems = true
      axios.all([axios({
        url: this.$ROOT_PATH + '/egzams/period/start/' + egzams_id + '/' + language, method: 'get' })
      ])
        .then(axios.spread((firstResponse) => {
          this.questions = firstResponse.data.questions
          this.answers = firstResponse.data.answers
          this.egzam = firstResponse.data.egzam
          this.steps = this.questions.length
          this.stepChanged(this.e1)
          this.loadingItems = false
        }))
        .catch(error => {
          this.loadingItems = false
          console.log(error)
          this.showSnakbar('Błąd! Wczytanie pytań egzaminu nie powiodło.', 'error')
        })
    },
    nextStepp (n) {
      if (this.questionAnswered) {
        this.questionAnswered = false
        if (n === this.steps) {
          this.e1 = 1
        } else {
          this.e1 = n + 1
        }
        this.stepChanged(this.e1)
        this.resoultTest = this.checkResoult()
      }
    },
    stepChanged (n) {
      //   this.courseAnswered = false
    }
  },
  watch: {
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    }
  }

}

</script>

<style scoped>
.logo {
    border:1px solid #eee;
}
.container {
    padding:16px 0px !important;
}
.v-input__control {
    padding:2px !important;
    margin:2px !important;
}
.egzamIntroContent,
.courseBackground {
    background:url('https://a.odosc.pl/v1/assets/firm-paper2.png') right bottom no-repeat;background-size:50%;background-color:white;
}

</style>
