<template>

<v-layout v-scroll>
<v-flex>
          <v-toolbar dense height="28" flat color="blue-grey lighten-5" class="mb-3">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip>
          <v-icon class="mr-2">school</v-icon>Edytujesz szkolenie</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
              <!--v-select
          :items="languages"
          box
          label="Wybierz wersje językową"
        ></v-select-->
            <v-tooltip left>
                <v-btn @click="appendPage(false)" class="success" icon  slot="activator"><v-icon>add_circle</v-icon></v-btn>
                <span>Dodaj stronę na początku</span>
           </v-tooltip>
            <v-divider vertical inset class="mx-2"></v-divider>
            <!--v-tooltip left>
                <v-btn @click="saveSettings()" class="info" icon slot="activator"><v-icon>settings</v-icon></v-btn>
                <span>Ustawienia</span>
            </v-tooltip-->
            <v-tooltip left>
                <v-btn @click="showEgzamLinkDialog()" class="info" icon slot="activator"><v-icon>settings</v-icon></v-btn>
                <span>Ustawienia</span>
            </v-tooltip>
            <v-tooltip left>
                <v-btn icon @click="showContent = !showContent" :color="!showContent ? 'grey' : 'info'"  slot="activator"><v-icon>storage</v-icon></v-btn>
                <span>Zwiń/Rozwiń treść stron</span>
            </v-tooltip>
            <v-divider vertical inset class="mx-2"></v-divider>
            <v-tooltip left>
                <v-btn disabled class="error" icon @click="deleteCourseDlg.show = true" slot="activator"><v-icon>delete</v-icon></v-btn>
                <span>Usuń to szkolenie</span>
            </v-tooltip>
    </v-toolbar>

  <v-layout>
            <v-flex d-flex xs4 sm2 md2 class="text-xs-right">
        <v-card>
          <v-card-text class="logo-background">

        <h2 class="display-2 mt-1 blue-grey--text"> {{ timeOfReading }} min.</h2>
       potrzeba na to szkolenie<br/> (+ dodane video)
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 sm9 md7 child-flex>
    <v-card>
        <v-card-title class="ma-2">
<div>
<h6 class="headline mb-2">
  <v-chip :color="category.color" label text-color="white">
    <span>
      {{ category.name }}
    </span>
    </v-chip><span class="font-weight-bold">
  NR {{ course.courses_id }}</span> / <span>{{ course.name }}</span>
 </h6>
<v-divider></v-divider>
 <span class="font-italic mt-2">Zakres szkolenia</span><br/>
    <span class="body-2"> {{ course.scope }} </span>
 </div>
        </v-card-title>
    </v-card>
    </v-flex>
            <v-flex d-flex xs12 sm3 md4 child-flex>
              <v-card >
                <v-card-text>
                  <v-flex xs12 sm8 d-flex>
        <v-select
          v-model="language"
          :items="languagesItems"
          class="ml-3 mb-2"
          label="Wersja językowa"
          item-text="state"
          item-value="abbr"
          prepend-inner-icon="language"
          hide-details
          @change="langChanged()"
        >        <template slot="selection" slot-scope="{ item, parent, selected }">
          <v-list-tile-content>
              <span class="title">{{ item.state }}</span>
          </v-list-tile-content>
          <v-spacer></v-spacer>
          <country-flag :country="(item.abbr.toLowerCase() === 'en' ? 'gb' : item.abbr)" shadow class="ma-0" />
        </template>
        <template slot="item" slot-scope="{ index, item }">
          <v-list-tile-content>
              <span class="title">{{ item.state }}</span>
          </v-list-tile-content>
          <v-spacer></v-spacer>
          <country-flag :country="(item.abbr.toLowerCase() === 'en' ? 'gb' : item.abbr)" shadow class="ma-0" />
        </template>
      </v-select>
      </v-flex>
      <span v-if="course.egzams_id > 0">
      <v-tooltip left>
        <v-btn v-if="course.egzams_id > 0" :to="'/egzams/edit/' + course.egzams_id" color="primary" flat slot="activator"><v-icon>ballot</v-icon> Egzamin nr {{ course.egzams_id }}</v-btn>
        <span>Przejdź do egzaminu</span>
      </v-tooltip>
      <span class="pt-4 body-2">/ {{ course.egzams_id > 0 && egzams.length > 0 ? egzams.find((i) => i.egzams_id === course.egzams_id).name : 'brak' }}</span></span>
          </v-card-text>
      </v-card>

</v-flex>
</v-layout>

   <v-progress-linear v-if="loadingItems"
        height="2"
      color="primary"
      indeterminate
    ></v-progress-linear>
    <v-alert
    :value="coursePagesFiltred.length < 1 && loadingItems === false"
    type="info"
    v-if="!loadingItems">
     To szkolenie nie posiada żadnych stron w tej wersji językowej. Dodaj strony do tego szkolenia.
  </v-alert>
        <draggable :list="currentPages" @end="onPagePositionChange">
              <transition-group name="list-complete">
            <v-card class="list-complete-item ma-3 course-page" v-for="(page, index) in currentPages" :id="'page-'+index" :key="page.courses_pages_id">
          <v-card-title class="pa-1 pl-4 mb-0" color="grey"><h3> <v-icon>description</v-icon>{{ index+1 }}. {{ page.title }}</h3> <v-spacer></v-spacer>
             <v-chip color="primary" text-color="white" v-if="page.haveQuestions">
             <v-avatar><v-icon>help</v-icon></v-avatar>Pytania sprawdzające</v-chip>
          <!--v-chip color="secondary" text-color="white">Strona nr {{ page.position }}</v-chip-->
    <v-chip  v-if="page.hasQuestions > 0" color="blue ligthen-1" text-color="white" class="mr-4">
      <v-avatar>
        <v-icon>check_circle</v-icon>
      </v-avatar>
      Twierdzenia sprawdzające
    </v-chip>
            <v-tooltip top>
            <v-btn flat icon small @click="showPageEditDialog(page.courses_pages_id)" color="green" slot="activator"><v-icon>edit</v-icon></v-btn>
            <span>Edytuj treść szkolenia</span>
        </v-tooltip>
        <v-tooltip top>
            <v-btn flat icon small @click="showPageClaimsDialog(page.courses_pages_id)" color="blue" slot="activator"><v-icon>question_answer</v-icon></v-btn>
            <span>Edytuj twierdzenia</span>
        </v-tooltip>
        <v-tooltip top>
            <v-btn flat icon small @click="deletePage(page.courses_pages_id)" color="red" slot="activator"><v-icon>delete</v-icon></v-btn>
            <span>Usuń tą stronę</span>
        </v-tooltip>
          </v-card-title>
              <v-card-text v-if="showContent" class="mt-0 pa-3 pt-0">
                  <div style="text-align:justify;text-justify:inter-word;max-witdh:800px;">
                  <span v-html="page.text"></span>
                  </div>
              </v-card-text>
            </v-card>
            </transition-group>

             </draggable>

             <v-tooltip left>
                <v-btn
                    v-model="offsetTop"
                    color="green"
                    @click="appendPage(true)"
                    dark
                    fixed
                    icon
                    large
                    bottom
                    right
                    slot="activator"
                >
                    <v-icon>add_circle</v-icon>
                </v-btn>
                <span>Dodaj stronę na końcu</span>
             </v-tooltip>

      <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.type"
      :timeout="2000"
    >
      {{ snackbar.text }}
      <v-btn
        dark
        flat
        @click="snackbar.show = false"
      >
        X
      </v-btn>
    </v-snackbar>

      <v-snackbar
      v-model="snackbarGrabbing"
      right
      bottom
      color="info"
      multi-line
      :timeout="5000"
    >
    <v-icon medium class="mr-4">info</v-icon>
      <strong>Złap stronę klikając na nią i przeciągnij w górę lub w dół aby zmienić jej położenie na liście.</strong>
      <v-btn dark flat @click="snackbarGrabbing = false">X</v-btn>
    </v-snackbar>

    </v-flex>
   <v-dialog v-model="coursePageEditDialog.show" persistent max-width="800px">
      <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon>edit</v-icon> Edytujesz stronę szkolenia</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="course-slide-content">
            <v-text-field
                class="body-2"
                label="Tytuł strony"
                box
                v-model="coursePageEditDialog.title"
            ></v-text-field>
            <quill-editor v-model="coursePageEditDialog.text"
                      :options="editorOption">
            </quill-editor>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions  style="clear:both" class=" mt-13">
          <v-btn color="red darken-1" :disabled="this.coursePageEditDialog.loading" flat @click.native="coursePageEditDialog.show = false">Anuluj</v-btn>
             <v-spacer></v-spacer>
                       <v-btn flat color="blue darken-1" :disabled="this.coursePageEditDialog.loading" @click="showPageClaimsDialog(coursePageEditDialog.courses_pages_id)">Edytuj twierdzenia <v-icon class="ml-2">question_answer</v-icon></v-btn>
          <v-btn color="green darken-1" flat @click.native="updatePage()" :loading="coursePageEditDialog.loading"> Zapisz zmiany <v-icon class="ml-2">save</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   <v-dialog v-model="coursePageQuestionsDialog.show" persistent max-width="700px">
      <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="ml-2">question_answer</v-icon> Edytujesz twierdzenia</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-alert v-if="coursePageQuestionsDialog.items.length > 0" :value="true" color="warning" icon="info" class="pa-2 pl-3">
                Na liście zaznacz wybrane twierdzenia które są prawdziwe.
            </v-alert>
    <v-data-table
      :headers="coursePageQuestionsDialog.headers"
      :items="coursePageQuestionsDialog.items"
      v-model="coursePageQuestionsDialog.selected"
      :loading="coursePageQuestionsDialog.loading"
      hide-actions
      item-key="courses_claims_id"
    >
      <template slot="items" slot-scope="props">
     <td class="table-questions-shortcolumm">
        <v-checkbox
          v-model="props.selected"
          primary
          hide-details

        ></v-checkbox>
      </td>
        <td>
          <v-edit-dialog
            class="pa-3"
                :full-width="true"
                :return-value.sync="props.item.claim"
                lazy
          > {{ props.item.claim }}
            <v-text-field
                slot="input"
                clearable
                v-model="props.item.claim"
                label="Edytuj twierdzenie"
                :counter="150"
            ></v-text-field>
          </v-edit-dialog>
        </td>
        <td class="table-questions-shortcolumm">
            <v-btn dark icon small @click="deleteQuestion(props.item.courses_claims_id)" color="red darken-1"><v-icon icon small>delete</v-icon></v-btn>
        </td>
      </template>
      <template slot="no-data">
        <v-alert  v-if="!coursePageQuestionsDialog.loading" :value="true" color="info" icon="info" class="pa-2 pl-3 mt-4 mb-4">
            Do tej strony nie zostały dodane żadne twierdzenia.
        </v-alert>
        <span v-if="coursePageQuestionsDialog.loading">Ładowanie danych...</span>
      </template>
    </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red darken-1" flat @click.native="closePageQuestionsDialog">Anuluj</v-btn>
      <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="appendQuestion"> Dodaj twierdzenie <v-icon class="ml-2">add_circle</v-icon></v-btn>
          <v-btn color="green darken-1" flat @click="updatePageClaims" :loading="loadingItems">Zapisz zmiany <v-icon class="ml-2">save</v-icon> </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteCourseDlg.show" persistent max-width="470">
      <v-card>
        <v-card-title class="headline">Czy na pewno usunąć to szkolenie?</v-card-title>
        <v-divider></v-divider>
        <v-card-text>Szkolenie zostanie usunięte na trwałe. Nie będzie możliwości jego przywrócenia w systemie.</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="deleteCourseDlg.show = false">Anuluj</v-btn>
          <v-btn color="red darken-1" flat @click="deleteCourse($route.params.id)">Usuń</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<v-dialog v-model="egzamsLinkDialog.show" persistent max-width="600px" lazy>
    <v-card>
      <v-card-title primary-title class="title">Ustawienia szkolenia</v-card-title>
    <v-divider></v-divider>
    <v-card-text>

      <v-text-field
      v-model="egzamsLinkDialog.name"
      :counter="50" box label="Nazwa szkolenia (widoczna tylko w systemie)" required
    ></v-text-field>
    <v-text-field
      v-model="egzamsLinkDialog.scope" box label="Zakres szkolenia (widoczny w dokumentacji)" required
    ></v-text-field>
<!--layout>
  <v-flex>

  </v-flex>
</layout-->
        <v-subheader>  Przypisz egzamin do tego szkolenia.</v-subheader>
    <v-select
      :items="[{ egzams_id: 0, name: 'Żaden' }].concat(this.egzams)"
      label="Egzamin: "
      v-model="egzamsLinkDialog.egzams_id"
      prepend-icon="ballot"
      :menu-props="{ auto: true }"
      item-value="egzams_id"
      item-text="name"
      no-data-text="Dodaj najpierw egzamin by móc przypisać go do szkolenia."
    ></v-select>

    <v-divider></v-divider>
          <v-layout row  class="mt-4">

      <v-flex xs4>
  <v-combobox
    v-model="model"
    :filter="filter"
    :hide-no-data="!search_category"
    :items="items"
    :search-input.sync="search_category"
    hide-selected
    label="Podstawowa"
    solo
    no-data-text="Usuń aby utworzyć"
    class="mb-0"
    @keyup.enter.prevent="none()"
  >
    <template slot="no-data">
      <v-list-tile v-if="model === null">
        <v-chip
          :color="colors[0]"
          text-color="white"
          label
          small>
          {{ search }}
        </v-chip>
        <span class="subheading">Tworzysz nową</span>
      </v-list-tile>
    </template>
    <template slot="selection" slot-scope="{ item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        :color="item.color"
        :selected="selected"
        label
        text-color="white"
      >
        <span class="pr-2">
          {{ item.name }}
        </span>
      </v-chip>
    </template>
    <template slot="item" slot-scope="{ index, item }">
      <v-list-tile-content>
        <v-chip
          :color="item.color"
          label
          text-color="white">
          {{ item.name }}
          </v-chip>
      </v-list-tile-content>
      <v-spacer></v-spacer>
      <v-list-tile-action @click.stop>
        <v-btn
          icon
           v-if="editing !== item"
          @click.stop.prevent="showDeleteCategorySnakbar(item); editing = item">
          <v-icon>close</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click.stop.prevent="deleteCategory(index, item); editing = null">
          <v-icon>check</v-icon>
        </v-btn>
      </v-list-tile-action>
    </template>
  </v-combobox>
  </v-flex>
        <v-flex xs8>
          <v-subheader>  Przypisz kategorie do tego egzaminu. Możesz także utworzyć nową kategorie wpisując jej nazwę i wciskając enter.</v-subheader>
      </v-flex>
  </v-layout>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
    <v-btn @click="egzamsLinkDialog.show = false" flat color="red darken-1">Anuluj</v-btn>
    <v-spacer></v-spacer>
        <v-btn flat
        :loading="loadingItems"
        color="green darken-1"
        @click="linkEgzam()">
      Zapisz ustawienia  <v-icon flat class="ml-2">save</v-icon>
    </v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
    <v-snackbar
        v-model="deleteCategorySnackbar.show"
        color="orange"
        :timeout="5000">{{ deleteCategorySnackbar.text }}
    </v-snackbar>
        </v-layout>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  data: () => ({
    showContent: true,
    offsetTop: 0,
    languagesItems: [
      { state: 'Polski', abbr: 'PL' },
      { state: 'Angielski', abbr: 'EN' }
    ],
    language: 'PL',
    deleteCategorySnackbar: {
      show: false,
      text: ''
    },
    colors: [
      '#64B5F6', // 'blue',
      '#E57373', // 'red',
      '#4DB6AC', // 'green',
      '#FFB74D', // orange',
      '#7986CB', // 'indigo',
      //  '#4DB6AC', //teal',
      '#9575CD', // purple
      '#4DD0E1', // cyam
      '#A1887F' // brown
    ],
    editing: null,
    items: [],
    model: null,
    search: null,
    search_category: null,
    addCategories: [],
    removeCategories: [],
    categories: [],

    deleteCourseDlg: {
      show: false
    },
    egzamsLinkDialog: {
      show: false,
      egzams_id: 0,
      courses_id: 0,
      name: '',
      scope: ''
    },
    coursePageQuestionsDialog: {
      show: false,
      selected: [],
      loading: false,
      courses_pages_id: -1,
      headers: [
        {
          text: '',
          sortable: false,
          value: false,
          width: 50
        },
        {
          text: 'Treść twierdzenia',
          value: false,
          sortable: false,
          name: 'question',
          align: 'left'
        }, {
          value: false,
          sortable: false,
          width: 50
        }
      ],
      items: [],
      lastItemId: -9999
    },
    loadingItems: false,
    editorOption: {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'color': [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          ['clean'],
          ['image'],
          ['video']
        ],
        history: {
          delay: 1000,
          maxStack: 50,
          userOnly: false
        }
      }
    },
    coursePageEditDialog: {
      show: false,
      courses_pages_id: -1,
      text: '',
      title: '',
      loading: false
    },
    snackbarGrabbing: true,
    snackbar: {
      text: '',
      show: false,
      color: 'info'
    },
    coursePages: [],
    egzams: [],
    courses: [],
    course: { name: 'Ładowanie danych...', scope: '' },
    currentPages: [],
    egzam: {}
  }),
  created () {
    this.loadInitData()
    this.loadPages(this.$route.params.id)
  },
  computed: {
    timeOfReading: function () {
      // policzyc tagi img i dodac odpowiednia ilosc czasu usredniona
      var totalText = ''
      for (var i = 0; i < this.coursePagesFiltred.length; i++) {
        totalText += this.coursePagesFiltred[i].text.replace(/(\<([^>]+)\>)/gi, ' ')
      }
      totalText = totalText.replace(/\s\s/, ' ')
      return Math.round((totalText.length / 1200), 0)
    },
    coursePagesFiltred: function () {
      return this.coursePages.filter((i) => i.language.toLowerCase() === this.language.toLowerCase())
    },
    category: function () {
      var index = this.items.findIndex((c) => parseInt(c.categories_id) === parseInt(this.course.categories_id))
      if (index < 0) {
        return {
          categories_id: 0,
          name: 'Podstawowa',
          color: 'grey'
        }
      }
      return this.items[index]
    }
  },
  components: {
    draggable,
    quillEditor
  },
  watch: {
    model (val, prev) {
      if (typeof val === 'string' || typeof val === 'object') {
        var category = this.items.find((i) => this.egzam.categories_id === i.categories_id)
        if (typeof category !== 'undefined') {
        //  val = [category]
        }
        val = [val]
      }
      if ((prev !== null && val !== null && val !== prev) || val === null || typeof val === 'undefined') return
      this.model = val.map(v => {
        if (typeof v === 'string' && typeof this.items !== 'undefined' &&
              this.items.findIndex(i => i.name.toLowerCase() === v.toLowerCase()) < 0) {
          v = {
            name: v,
            color: this.colors.shift(),
            categories_id: 0,
            egzams_count: 0
          }
          this.items.push(v)
        }
        return v
      })[0]
    }
  },

  methods: {
    langChanged () {
      this.currentPages = this.coursePagesFiltred
    },
    none () {
      return true
    },
    showDeleteCategorySnakbar (item) {
      if (item.egzams_count > 0) {
        this.deleteCategorySnackbar.text = 'Aktualnie do tej kategorii są przypisane egzaminy (' + item.egzams_count + ') i zostaną z niej usunięte.'
        this.deleteCategorySnackbar.show = true
      }
    },
    deleteCategory (index, item) {
      if (item.categories_id > 0) {
        this.removeCategories.push(item)
      }
      this.items = this.items.filter(v => v.name !== item.name)
      this.colors.unshift(item.color)
    },
    filter (item, queryText, itemText) {
      if (item.header) return false
      const hasValue = val => val != null ? val : ''
      const text = hasValue(itemText)
      const query = hasValue(queryText)
      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    loadInitData () {
      this.loadingItems = true
      axios.all([axios({url: this.$ROOT_PATH + '/courses', method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token }}),
        axios({url: this.$ROOT_PATH + '/egzams', method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token }}),
        axios({url: this.$ROOT_PATH + '/categories', method: 'get', headers: { 'X-API-Token': this.$store.state.userCredentials.token }})
      ])
        .then(axios.spread((firstResponse, secondResponse, thirdResponse) => {
          this.courses = firstResponse.data
          this.egzams = secondResponse.data
          this.items = thirdResponse.data

          this.course = this.courses.find((i) => parseInt(i.courses_id) === parseInt(this.$route.params.id))
          if (this.course.egzams_id > 0) {
            this.egzam = this.egzams.find((i) => parseInt(i.egzams_id) === parseInt(this.course.egzams_id))
          }
          this.model = this.items.find((i) => this.course.categories_id === i.categories_id)
          this.colors = this.colors.filter((c) => this.items.findIndex(i => i.color === c) < 0)
        }))
        .catch(error => {
          console.log(error)
          this.showSnakbar('Błąd! Wczytanie szkolenia nie powiodło się.', 'error')
          this.loadingItems = false
        })
    },
    showEgzamLinkDialog () {
      this.egzamsLinkDialog.courses_id = this.$route.params.id
      this.egzamsLinkDialog.egzams_id = this.courses.find((i) => parseInt(i.courses_id) === parseInt(this.$route.params.id)).egzams_id
      this.egzamsLinkDialog.show = true
      this.egzamsLinkDialog.name = this.course.name
      this.egzamsLinkDialog.scope = this.course.scope
    },

    linkEgzam () {
      // this.loadingItems = true
      axios({
        url: this.$ROOT_PATH + '/courses/linkegzam',
        method: 'post',
        headers: { 'X-API-Token': this.$store.state.userCredentials.token },
        data: {
          courses_id: this.egzamsLinkDialog.courses_id,
          egzams_id: this.egzamsLinkDialog.egzams_id,
          category_name: (typeof this.model !== 'undefined' && this.model !== null ? this.model.name : -1),
          categories: this.items,
          remove_categories: this.removeCategories,
          name: this.egzamsLinkDialog.name,
          scope: this.egzamsLinkDialog.scope
        }
      })
        .then(response => {
          this.course.name = this.egzamsLinkDialog.name
          this.course.scope = this.egzamsLinkDialog.scope
          this.course.egzams_id = this.egzamsLinkDialog.egzams_id
          this.course.categories_id = response.data.categories_id
          this.items = response.data.categories
          this.removeCategories = []
          this.items.forEach(i => {
            this.colors = this.colors.filter(c => i.color !== c)
          })
          this.egzamsLinkDialog.show = false
          this.showSnakbar('Ustawienia tego szkolenia zostały zapisane', 'success')
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
        .then(() => {
          //   this.loadingItems = false;
        })
    },

    closePageQuestionsDialog () {
      this.coursePageQuestionsDialog.show = false
      this.coursePageQuestionsDialog.items = []
      this.coursePageQuestionsDialog.selected = []
    },
    updatePageClaims (courses_pages_id) {
      let coursePageClaims = this.coursePageQuestionsDialog.items
      for (let i = 0; i < coursePageClaims.length; i++) {
        coursePageClaims[i].is_true = (this.coursePageQuestionsDialog.selected.findIndex((c) => c.courses_claims_id === coursePageClaims[i].courses_claims_id) != -1)
      }
      this.coursePageQuestionsDialog.loading = true
      axios({
        url: this.$ROOT_PATH + '/courses/pages/updateclaims',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token},
        data: {
          claims: coursePageClaims,
          courses_pages_id: this.coursePageQuestionsDialog.courses_pages_id,
          courses_id: this.$route.params.id
        }
      })
        .then(response => {
          this.closePageQuestionsDialog()
          let pageIndex = this.coursePages.findIndex((c) => c.courses_pages_id === this.coursePageQuestionsDialog.courses_pages_id)
          this.coursePages[pageIndex].hasQuestions = coursePageClaims.length
          this.showSnakbar('Twierdzenia dla tej strony zostały zapisane.', 'success')
        })
        .catch(error => {
          console.log(error.response)
          this.showSnakbar('Błąd! Twierdzenia nie zostały zapisane.', 'error')
        }).then(() => {
          this.coursePageQuestionsDialog.loading = false
        })
    },

    deleteQuestion (coursesClaimsId) {
      this.coursePageQuestionsDialog.items.splice(this.coursePageQuestionsDialog.items.findIndex((i) => i.courses_claims_id === coursesClaimsId), 1)
    },
    appendQuestion () {
      this.coursePageQuestionsDialog.items.push(
        {
          claim: 'Wpisz treść twierdzenia (możesz edytować ten tekst klikając na niego)',
          courses_claims_id: this.coursePageQuestionsDialog.lastItemId++,
          courses_pages_id: this.coursePageQuestionsDialog.courses_pages_id
        }
      )
    },
    showSnakbar (text, type) {
      this.snackbar.show = false
      this.snackbar = { show: true, text: text, type: type}
    },
    deleteCourse (course_id) {
      axios({
        url: this.$ROOT_PATH + '/courses/delete/' + course_id,
        method: 'get',
        headers: {'X-API-Token': this.$store.state.userCredentials.token}
      })
        .then(response => {

        })
        .catch(error => {
          this.deleteCourseDlg.show = false
          this.showSnakbar('Szkolenie nie zostało usunięte. Nie posiadasz takich uprawnień.', 'error')
        })
    },
    showPageClaimsDialog (courses_pages_id) {
      this.coursePageQuestionsDialog.courses_pages_id = courses_pages_id
      this.loadClaims(courses_pages_id)
      this.coursePageQuestionsDialog.show = true
    },
    loadClaims (courses_pages_id) {
      this.coursePageQuestionsDialog.loading = true
      axios({
        url: this.$ROOT_PATH + '/courses/pages/claims/' + courses_pages_id,
        method: 'get',
        headers: {'X-API-Token': this.$store.state.userCredentials.token}
      })
        .then(response => {
          let coursePageClaims = response.data
          for (let i = 0; i < coursePageClaims.length; i++) {
            if (coursePageClaims[i].is_true != false) {
              this.coursePageQuestionsDialog.selected.push(coursePageClaims[i])
            }
          }
          this.coursePageQuestionsDialog.items = coursePageClaims
          this.coursePageQuestionsDialog.loading = false
        })
        .catch(error => {
          console.log(error)
          this.showSnakbar('Błąd! Nie można pobrać twierdzeń dla tej strony.', 'error')
        }).then(() => this.loadingItems = false)
    },
    loadPages (courses_id) {
      this.loadingItems = true
      axios({
        url: this.$ROOT_PATH + '/courses/pages/' + courses_id,
        method: 'get',
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      })
        .then(response => {
          this.coursePages = response.data
          this.currentPages = this.coursePagesFiltred
          this.loadingItems = false
        })
        .catch(error => {
          console.log(error.response)
        }).then(() => this.loadingItems = false)
    },
    showPageEditDialog (courses_pages_id) {
      let coursePage = this.coursePages[this.coursePages.findIndex((i) => i.courses_pages_id === courses_pages_id)]
      this.coursePageEditDialog.text = coursePage.text
      this.coursePageEditDialog.title = coursePage.title
      this.coursePageEditDialog.courses_pages_id = coursePage.courses_pages_id
      this.coursePageEditDialog.show = true
    },
    onPagePositionChange () {
      let payload = []
      for (let i = 0; i < this.currentPages.length; i++) {
        payload.push({ courses_pages_id: this.currentPages[i].courses_pages_id, position: i })
      }

      axios({
        url: this.$ROOT_PATH + '/courses/pages/updatepositions',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token},
        data: { positions: payload }
      })
        .then(response => {
          // this.showSnakbar('Pozycje stron zostały zaktualizowane pomyślnie!','success')
        })
        .catch(error => {
          console.log(error.response)
          this.showSnakbar('Błąd! Nie zaaktualizowano pozycji stron.', 'error')
        })
    },
    updatePage () {
      this.coursePageEditDialog.loading = true
      let courseText = this.coursePageEditDialog.text.replace(/(<br>)/img, '').replace(/(<\/p>)/img, '<br>').replace(/(<p[^>]+?>|<p>)/img, '')
      axios({
        url: this.$ROOT_PATH + '/courses/pages/update',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token},
        data: {
          courses_pages_id: this.coursePageEditDialog.courses_pages_id,
          text: courseText,
          title: this.coursePageEditDialog.title
        }
      })
        .then(response => {
          this.coursePageEditDialog.loading = false
          let coursePagesIndex = this.coursePages.findIndex((i) => i.courses_pages_id === this.coursePageEditDialog.courses_pages_id)
          this.coursePages[coursePagesIndex].title = this.coursePageEditDialog.title
          this.coursePages[coursePagesIndex].text = courseText
          this.coursePageEditDialog.show = false

          this.currentPages = this.coursePagesFiltred
          this.showSnakbar('Strona szkolenia została zaktualizowana.', 'success')
        })
        .catch(error => {
          this.coursePageEditDialog.loading = false
          console.log(error.response)
          this.showSnakbar('Błąd! Dane strony nie zostały zaaktualizowane.', 'error')
        })
    },
    appendPage (to_end) {
      axios({
        url: this.$ROOT_PATH + '/courses/pages/new',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token},
        data: {
          courses_id: this.$route.params.id,
          position: this.coursePages.length + 1,
          text: 'Uzupełnij dane strony szkolenia.',
          title: 'Nowa strona',
          language: this.language
        }
      })
        .then(response => {
          let course = response.data
          let newCoursePage = { courses_pages_id: course.courses_pages_id, position: course.position, text: course.text, title: course.title, hasQuestions: false, language: this.language }
          if (to_end) {
            this.coursePages.push(newCoursePage)
            this.$scrollTo('#page-' + (this.coursePages.length - 2), { offset: 300 })
          } else {
            this.coursePages = [newCoursePage].concat(this.coursePages)
          }
          this.currentPages = this.coursePagesFiltred
          this.onPagePositionChange()
          //   this.showPageEditDialog(course.courses_pages_id)
          this.showSnakbar('Dodano nową stronę dla tego szkolenia.', 'success')
        })
        .catch(error => {
          console.log(error)
          this.showSnakbar('Błąd! Nowa strona nie została dodana.', 'error')
        })
    },
    deletePage (pageId) {
      axios({
        url: this.$ROOT_PATH + '/courses/pages/delete',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token },
        data: { courses_pages_id: pageId }
      })
        .then(response => {
          this.coursePages.splice(this.coursePages.findIndex((i) => i.courses_pages_id === pageId), 1)
          this.currentPages = this.coursePagesFiltred
          this.showSnakbar('Strona została usunięta z powodzeniem.', 'success')
          this.onPagePositionChange()
        })
        .catch(error => {
          this.showSnakbar('Błąd! Strona nie zostałą usunięta.', 'error')
        })
    }
  }
}
</script>
<style>
.table-questions-shortcolumm {
    width:30px;
}
.quill-editor {
    height: 25rem;
    margin-bottom:40px;
}
@media only screen and (max-width: 600px) {
  .ql-video,
  .course-page img {
      width:100% !important;
      max-width:900px !important;
  }
}
.ql-video {
    width:100%;
    max-width:800px;
    max-height:800px;
    height:400px;
}
.course-page img {
    margin:10px auto !important;
    border:1px solid #666 !important;
    max-width:800px;
}
.list-complete-item {
  cursor: grabbing;
  transition: all 0.3s;
}
.list-complete-enter, .list-complete-leave-active {
  opacity: 0;
  display: absolute;
}
</style>
