<template>
<div  v-if="showPanel" transition="fade">
    <v-layout row justify-center class="mt-5">
      <v-card class="pa-1">
        <v-card-title class="text-xs-center" dark>
          PANEL ADMINISTRACYJNY SYSTEMU SZKOLENIOWEGO
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kancelaria Bezpieczeństwa" class="mb-4" style="width:100%;max-width:350px;" />
      <v-form refs="form" v-model="valid" lazy-validation>
        <v-flex>
          <v-text-field
            v-model="login"
            required
            name="login"
            :rules="[rules.required, rules.isEmail]"
            label="Login:"
            class="title"
            suffix="@odosc.pl"
            placeholder="np. imię.nazwisko"
            prepend-inner-icon="account_box"></v-text-field>
        </v-flex>
        <v-flex class="text-xs-left">
          <v-text-field
            v-model="password"
            required
            class="title"
            name="password"
            counter="30"
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            :rules="[rules.required, rules.min, rules.checkCharacters]"
            :type="showPassword ? 'text' : 'password'"
            label="Hasło:"
            placeholder="Podaj hasło"
            mask
            @click:append="showPassword = !showPassword"
            prepend-inner-icon="https"></v-text-field>
          <!--a class="mt-n2" href="">Nie pamiętasz hasła?</a-->
        </v-flex>
      </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="text-xs-center mt-2">
        <v-btn @click="loginWithPassword" :disabled="!valid" depressed class="info ml-10 ma-auto">Zaloguj się</v-btn>
        <!--v-spacer></v-spacer>
        <v-btn flat>Resetuj hasło</v-btn-->
      </v-card-actions>
    </v-card>
      <v-dialog
        v-model="dialogOnlyDesktop"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
      <v-card  class="align-xs-center justify-center courseIntroContent" style="max-width:600px;text-align:center">

             <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kancelaria Bezpieczeństwa" width="350" class="logo" />

             <p class="mt-2 pa-2" >
              Panel administracyjny systemu dostępny jest tylko i wyłącznie w wersji na komputer.<br/>
             </p>
        <p class="mt-2 pa-2" >
              Jeżeli logujesz się z komputera powiększ okno przeglądarki i odświerz stronę.
             </p>
      </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.type"
        :timeout="6000">
      {{ snackbar.text }}
      <v-btn dark flat @click="snackbar.show = false">X</v-btn>
    </v-snackbar>
    </v-layout>
    </div>
</template>

<script>
import axios from 'axios'
import JwtDecode from 'jwt-decode'

export default {
  data: () => ({
    valid: false,
    snackbar: {
      text: '',
      show: false,
      color: 'info'
    },
    dialogOnlyDesktop: false,
    showPanel: false,
    login: '',
    password: '',
    showPassword: false,
    rules: {
      required: v => !!v || 'To pole jest wymagane',
      min: v => v.length >= 5 || 'Minimum 6 znaków',
      checkCharacters: v => (/[0-9]+/.test(v) && /[a-zA-Z]+/.test(v)) || 'Hasło powinno zawierać jeden znak oraz liczbę',
      isEmail: true // v => /(.*)@odosc.pl$/.test(v) || 'Podaj poprawny adres e-mail'
    },
    isMobile: false,
    loading: false
  }),

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  created () {
    this.showPanel = true
    this.dialogOnlyDesktop = window.innerWidth < 500
  },
  methods: {
    onResize () {
      this.dialogOnlyDesktop = window.innerWidth < 500
    },
    showSnakbar (text, type) {
      this.snackbar = { show: true, text: text, type: type }
    },

    loginWithPassword () {
      this.loading = true
      axios({
        url: this.$ROOT_PATH + '/auth/login',
        method: 'post',
        data: {
          email: this.login + '@odosc.pl',
          password: this.password
        }
      }).then(response => {
          this.loading = false
          let jwt = JwtDecode(response.data.token)
          this.$store.state.userCredentials = {
            token: response.data.token,
            isAuth: true,
            email: jwt.email,
            name: jwt.name,
            isAdmin: jwt.isadmin,
            sub: jwt.sub,
            prv: jwt.prv
          }
          this.$router.push('customers')
        })
        .catch(error => {
          this.loading = false
          this.showSnakbar('Nieprawidłowe dane logowania. Sprawdź login i hasło.', 'error')
        })
    }
  }

}

</script>
<style scoped>

</style>
