<template>
        <v-flex>
           <v-toolbar dense height="28" flat color="blue-grey lighten-5" class="mb-4">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip>
        <v-icon class="mr-2">ballot</v-icon>Egzaminy RODO</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-tooltip left>
          <v-btn class="mb-2 success" @click="showAppendEgzamDialog()" slot="activator" icon><v-icon>add_circle</v-icon></v-btn>
          <span>Dodaj nowy egzamin</span>
      </v-tooltip>
    </v-toolbar>
        <v-alert v-if="tableItems.length === 0 && loadingItems === false" :value="true" icon="info" class="info">
           Aktualnie nie ma żadnych egzaminów w bazie danych. Kliknij znak "+" w prawym górnym rogu aby dodać nowy.
        </v-alert>
           <v-progress-linear v-if="loadingItems"
        height="2"
      color="primary"
      indeterminate
    ></v-progress-linear>
  <v-layout wrap>
    <v-flex  md6 lg4 v-for="item in tableItems" :key="item.egzams_id" md4 class="pa-2">
        <v-card class="card-egzam" :style="'border-top-color: ' + getCategoryColor(item.category_color)">
            <v-card-title class="headline">
             <span><strong>NR {{ item.egzams_id }}</strong>&nbsp;/&nbsp;{{ item.name }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text >
              <v-layout>
                <v-flex md7>
                  <span class="font-italic">Zakres egzaminu</span><br/>
                  <span class="body-2"> {{ item.scope }} </span>
                </v-flex>
                <v-flex md5 class="pl-3">
                  <span class="font-italic">Trafność odpowiedzi</span><br/>
                  <v-progress-linear v-model="item.correct_precent" height="10" class="ma-0" color="blue-grey" style="opacity:0.7"></v-progress-linear>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions color="gray">
              <v-chip :color="item.category_color ? item.category_color : 'grey'" label text-color="white">
                <span>
                  {{ (item.category_name ? item.category_name : 'Podstawowa') }}
                </span>
                </v-chip>
               <v-spacer></v-spacer>
               <v-btn color="primary lighten-1" :to="'/egzams/edit/'+ item.egzams_id" depressed>Edytuj egzamin <v-icon class="ml-2">edit</v-icon></v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
  </v-layout>

<v-dialog v-model="appendEgzamDialog.show" persistent max-width="600px">
  <v-form ref="form" v-model="appendEgzamDialog.valid" lazy-validation >
        <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="mr-2">ballot</v-icon> Dodajesz egzamin</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

    <v-text-field
      v-model="appendEgzamDialog.name"
      label="Nazwa egzaminu (widoczna tylko w systemie)"
      required
      box
    ></v-text-field>
    <v-text-field
      v-model="appendEgzamDialog.scope"
      label="Zakres egzaminu (widoczny w dokumentacji)"
      required
      box
    ></v-text-field>
      </v-card-text>
  <v-divider></v-divider>
<v-card-actions>

    <v-btn @click="appendEgzamDialog.show = false" flat color="red darken-1">Anuluj</v-btn>

  <v-spacer></v-spacer>

        <v-btn
      flat
      @click="appendEgzam"
      color="green darken-1"
      :disabled="!appendEgzamDialog.valid"
    >
      Dodaj egzamin <v-icon class="ml-2">add_circle</v-icon>
    </v-btn>
</v-card-actions>

        </v-card>
  </v-form>
</v-dialog>

    <v-snackbar
      v-model="snackbars.errorShow"
      color="error"
      :timeout="2000"
    >
      Błąd wczytywania danych! Sprawdź połączenie z internetem
      <v-btn
        dark
        flat
        @click="snackbars.errorShow = false"
      >
        X
      </v-btn>
    </v-snackbar>
  </v-flex>
</template>
<script>

import axios from 'axios'

export default {
  data () {
    return {
      appendEgzamDialog: {
        show: false,
        name: '',
        scope: '',
        valid: true
      },
      loadingItems: false,
      snackbars: {
        errorShow: false
      },
      search: '',
      headers: [
        { text: 'ID',
          value: 'egzams_id'
        },
        {
          text: 'Nazwa egzaminu',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        { text: 'Zakres egzaminu', value: 'scope', sortable: true },
        { text: '', value: 'name', sortable: false}
      ],
      tableItems: []

    }
  },

  created () {
    this.loadCourses()
  },
  methods: {
    getCategoryColor (color) {
      if (color && color.length > 2) {
        return color
      }
      return '#888'
    },
    getAccuracyColor (precent) {
      if (precent >= 80) { return 'green' }
      if (precent >= 70) { return 'yellow darken-3' }
      if (precent >= 60) { return 'orange darken-2' }
      if (precent >= 50) { return 'red lighten-2' }
      return 'red darken-1'
    },
    showAppendEgzamDialog () {
      this.appendEgzamDialog = {
        show: true,
        scope: '',
        name: '',
        valid: false
      }
    },
    appendEgzam () {
      axios({
        url: this.$ROOT_PATH + '/egzams/new',
        method: 'post',
        headers: {'X-API-Token': this.$store.state.userCredentials.token},
        data: {
          name: this.appendEgzamDialog.name,
          scope: this.appendEgzamDialog.scope
        }
      })
        .then(response => {
          console.log(response.data)
          this.tableItems.push({
            egzams_id: response.data.egzams_id,
            name: response.data.name,
            scope: response.data.scope
          })
          this.appendEgzamDialog.show = false
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    loadCourses () {
      this.loadingItems = true
      axios({
        url: this.$ROOT_PATH + '/egzams',
        method: 'get',
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      })
        .then(response => {
          this.tableItems = response.data
        })
        .catch(error => {
          console.log(error.response)
        })
        .then(() => {
          this.loadingItems = false
        })
    }
  }
}
</script>
<style scoped>
.table-courses-shortcolumm {
    width:30px;
}
.card-egzam {
  border-top:7px solid #999;
}
.v-card__actions {
  background-color:#f9f9f9;
}
</style>
