<template>
  <v-layout wrap>
    <v-toolbar dense height="28" flat color="blue-grey lighten-5" class="mb-4">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip>
          <v-icon class="mr-2">dvr</v-icon>Webinarium</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-tooltip left>
          <v-btn class="mb-2 success" slot="activator" icon @click="addDlg()"><v-icon>add_circle</v-icon></v-btn>
          <span>Dodaj nowy webinar</span>
      </v-tooltip>
    </v-toolbar>

    <v-layout>

      <v-flex md6 lg4 v-for="item in webinars" :key="item.webinars_id" md4 class="pa-2">
        <v-card>
            <v-card-title class="headline webinar-card" :style="'border-top-color: ' + (item.days_left > 1 ? '#777' : 'red' )">
               <span><strong>NR {{ item.webinars_id }}</strong>&nbsp;/&nbsp;{{ item.title }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
<v-layout class="mb-2">
  <v-flex xs8 md8>             <span class="font-italic">Zakres webinaru</span><br/>
    <span class="body-2"> {{ item.scope }} </span></v-flex>
<v-flex xs4 md4 class="ml-3">
    <span class="font-italic">Termin webinaru</span><br/>
    <v-chip class="title white--text" :color="(item.days_left > 1 ? 'blue-grey' : 'red' )" >{{ item.start_datetime.substr(8,2) + '/' + item.start_datetime.substr(5,2) + ' ' + item.start_datetime.substr(11,5) }}</v-chip>
</v-flex>
</v-layout>
            </v-card-text>
              <v-divider></v-divider>
            <v-card-actions class="pa-2">
              <v-btn class="primary lighten-1" :to="'/webinars/edit/' + item.webinars_id " depressed>Edytuj<v-icon class="ml-2">edit</v-icon></v-btn>
              <v-spacer></v-spacer>
                <v-btn class="success" @click="termDlg(item.webinars_id)" depressed>Zaplanuj<v-icon class="ml-2">schedule</v-icon></v-btn>
               <v-btn class="red darken-2 white--text" :to="'/webinar/'+ item.webinars_id " depressed>Moderuj<v-icon class="ml-2">admin_panel_settings</v-icon></v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    </v-layout>

    <v-dialog v-model="webinarEditDlg.show" persistent width="700">
      <v-card>
      <v-card-title class="headline"><v-icon class="mr-2">dvr</v-icon>
        {{ webinarEditDlg.webinars_id > 0 ? 'Edytujesz' : 'Dodajesz' }} webinar
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field v-model="webinarEditDlg.title" box label="Nazwa webinaru (widoczna tylko w systemie)"></v-text-field>
        <v-text-field v-model="webinarEditDlg.scope" box label="Zakres webinaru (widoczny dla uczestników)"></v-text-field>
        <span class="subheader">Przypisz film do tego webinaru </span><br />
        <form id="webinarmovie">
          <input type="hidden" name="title" :value="webinarEditDlg.title" />
          <input type="hidden" name="scope" :value="webinarEditDlg.scope" />
          <input type="hidden" name="webinars_id" :value="webinarEditDlg.webinars_id" />
          <uploadbtn icon="picture" accept=".webm" required></uploadbtn>
        </form>
        <v-progress-linear v-model="webinarEditDlg.progress" size="5" color="primary"></v-progress-linear>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="red" flat :disabled="webinarEditDlg.uploading" @click="webinarEditDlg.show = false">Anuluj</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="success" :loading="webinarEditDlg.uploading" flat @click="uploadMovie()">Zapisz</v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="webinarTermDlg.show" persistent max-width="620px">
      <v-card>
          <v-card-title primary-title>
            <span class="title"><v-icon class="mr-2">schedule</v-icon>Ustaw datę następnego webinaru</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout>
              <v-flex xs6 md6>
                <v-date-picker v-model="webinarTermDlg.date" show-current
                    :first-day-of-week="1" locale="pl-PL" class="elevation-0"></v-date-picker>
              </v-flex>
              <v-flex xs6 md6>
                <v-time-picker v-model="webinarTermDlg.time" format="24hr" class="elevation-0"></v-time-picker>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red darken-1" flat @click.native="webinarTermDlg.show = false">Anuluj</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="setDatetime()" :disabled="!webinarTermDlg.date || !webinarTermDlg.time" flat>Ustaw datę rozpoczęcia</v-btn>
          </v-card-actions>
        </v-card>
  </v-dialog>
  
    <!--v-flex
      xs12
      class="mb-3">
      <v-card>
        <v-card-text>
      <v-sheet height="500">
        <v-calendar
          ref="calendar"
          v-model="start"
          :type="type"
          :end="end"
          color="primary"
        ></v-calendar>
      </v-sheet>
    </v-card-text>
      </v-card>
    </v-flex>

    <v-flex
      sm4
      xs12
      class="text-sm-left text-xs-center"
    >
      <v-btn @click="$refs.calendar.prev()">
        <v-icon
          dark
          left>
          keyboard_arrow_left
        </v-icon>
        Prev
      </v-btn>
    </v-flex>
    <v-flex
      sm4
      xs12
      class="text-sm-right text-xs-center"
    >
      <v-btn @click="$refs.calendar.next()">
        Next
        <v-icon
          right
          dark>
          keyboard_arrow_right
        </v-icon>
      </v-btn>
    </v-flex-->

  </v-layout>
</template>

<script>
import axios from 'axios'
import * as moment from 'moment'
import * as easings from 'vuetify/es5/util/easing-patterns'
import uploadbtn from '../components/upload-btn.vue'

export default {
  data: () => ({
    loginDlg: false,
    message: '',
    messages: [],
    controlsDisabled: true,
    e1: 0,
    step: 1,
    seek: 0,
    type: 'month',
    start: '2019-01-01',
    end: '2019-01-06',
    typeOptions: [
      { text: 'Day', value: 'day' },
      { text: '4 Day', value: '4day' },
      { text: 'Week', value: 'week' },
      { text: 'Month', value: 'month' },
      { text: 'Custom Daily', value: 'custom-daily' },
      { text: 'Custom Weekly', value: 'custom-weekly' }
    ],
    login: '',
    channel: 'ID1',
    websocket: null,
    server: 'wss://szkolenia.odosc.pl:5678',
    webinars: [],
    synth: window.speechSynthesis,
    playing: false,
    videoplaying: false,
    slideplaying: false,
    timeRanges: {},
    duration: 0,
    timerSlide: 0,
    timerSlideStarting: 0,
    slideInterval: 0,
    playVideoDelay: 0,
    webinarEditDlg: {
      show: false,
      uploading: false,
      progress: 0
    },
    webinarTermDlg: {
      show: false,
      webinars_id: 0,
      start_date: '',
      start_time: '',
      title: '',
      scope: ''
    }
  }),
  created () {
    this.loadWebinars()
  },
  mounted () {

  },
  unmounted () {

  },

  components: {
    uploadbtn
  },

  computed: {
  },
  methods: {
    getTimeleftColor (days_left) {

    },

    addDlg () {
      this.webinarEditDlg.webinars_id = 0
      this.webinarEditDlg.title = ''
      this.webinarEditDlg.scope = ''
      this.webinarEditDlg.webinar_movie = ''
      this.webinarEditDlg.show = true
    },

    editDlg (webinars_id) {
      let webinar = this.webinars.find((w) => w.webinars_id === webinars_id)
      this.webinarEditDlg.webinars_id = webinars_id
      this.webinarEditDlg.title = webinar.title
      this.webinarEditDlg.scope = webinar.scope
      this.webinarEditDlg.show = true
    },

    termDlg (webinars_id) {
      this.webinarTermDlg.webinars_id = webinars_id
      this.webinarTermDlg.show = true
    },

    setDatetime () {
      axios.post(this.$ROOT_PATH + '/webinar/setdatetime', {
        datetime: this.webinarTermDlg.date + ' ' + this.webinarTermDlg.time + ':00',
        webinars_id: this.webinarTermDlg.webinars_id,
      }, {
        headers: { 'X-API-Token': this.$store.state.userCredentials.token }
      }).then(response => {
        var webinar = this.webinars.find((w) => w.webinars_id === response.data.webinars_id)
        webinar.start_datetime = response.data.start_datetime
        this.webinarTermDlg.show = false
        console.log(response.data)
      }).catch(error => {
        console.log(error)
      })
    },

    loadWebinars () {
      axios.all([axios({ 
        url: this.$ROOT_PATH + '/webinars',
        method: 'get',
        headers: { 'X-API-Token': this.$store.state.userCredentials.token }
      })])
        .then(axios.spread((firstResponse) => {
          this.webinars = firstResponse.data
        }))
      .catch(error => {
        this.loadingItems = false
        this.showSnakbar('Błąd! Wczytanie webinarów nie powiodło się.', 'error')
      })
    },
    uploadMovie () {
      this.webinarEditDlg.uploading = true
      let formData = new FormData(document.querySelector('#webinarmovie'))
      axios.post(this.$ROOT_PATH + '/webinar/setmovie', formData, {
        headers: {'X-API-Token': this.$store.state.userCredentials.token, 'Content-Type': 'multipart/form-data'},
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            this.webinarEditDlg.progress = progressEvent.loaded / progressEvent.total * 100
            console.log(progressEvent.loaded)
          }
        }
      })
        .then(response => {
          var webinar = this.webinars.find((w) => w.webinars_id === parseInt(response.data.webinars_id))
          if (response.data.filename.length > 1) {
            webinar.webinar_movie = response.data.filename
          }
          webinar.title = response.data.title
          webinar.scope = response.data.scope
          this.webinarEditDlg.uploading = false
          this.webinarEditDlg.show = false
          console.log(response.data)
        })
        .catch(error => {
          this.webinarEditDlg.uploading = false
          console.log(error)
          this.showSnakbar('Wystapił błąd podczas importowania pliku', 'error')
        })
    },
  }
}
</script>
<style>

.message {
  background-color:#fff;
}

.v-picker--time .v-picker__title {
  padding:9px;
}

.messages {
  height:500px;overflow-y:scroll;padding:10px;
  border-radius:4px;
  border:2px solid #777;
  background-color:#fff;
}

.v-card__actions {
  background:#f7f7f7;
}
.webinar-card {
  border-top:7px solid #888;
}

</style>
