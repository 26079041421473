<template>
  <v-app id="inspire" >
    <v-hover>

    <v-navigation-drawer
      v-if="$store.state.userCredentials.isAuth"
      v-model="drawer"
      slot-scope="{ hover }"
      :clipped="false"
      :mini-variant="!$vuetify.breakpoint.lgAndDown ? $vuetify.breakpoint.lgAndDown : !hover"
      :expand-on-hover="true"
      height="500"
      :absolute="false"
      floating
      :app="!$vuetify.breakpoint.lgAndDown || !hover"
      fixed
      width="290"
      class="elevation-2 navigation"
      color="#c09215"
    >
      <v-toolbar flat class="transparent">
        <v-list class="pa-0">
          <v-list-tile avatar>
            <v-list-tile-avatar tile>
              <img :src="$BASE_PATH + '/assets/logo.2.icon.png'" />
            </v-list-tile-avatar>
            <v-list-tile-content class="text-no-wrap text-truncate body-2">
             {{ $store.state.userCredentials.name }}
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list class="pt-4 navigationBackground">
          <v-list-tile v-for="item in menuItems" :key="item.text" :to="{ path: item.path }">
            <v-list-tile-action>
              <v-icon color="lighten-1">{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title.toUpperCase() }}</v-list-tile-title>
              <!--v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title-->
            </v-list-tile-content>
          </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    </v-hover>
    <v-toolbar
      :clipped-left="false"
      color="blue darken-3"
      dark
      app
      dense
      fixed
    >
      <v-toolbar-title class="ma-0 pl-1">
        <span>Kancelaria Bezpieczeństwa</span> <span v-if="currentTitle && !$store.state.userCredentials.isAuth"> - {{ currentTitle }}</span>
      </v-toolbar-title>
       <v-spacer></v-spacer>
       <v-toolbar-items v-if="!drawer && $store.state.userCredentials.isAuth">
        <v-btn flat v-for="item in menuItems" :key="item.text" :to="item.path" icon><v-icon>{{ item.icon }}</v-icon></v-btn>
      </v-toolbar-items>
    <v-spacer></v-spacer>

      <v-toolbar-items>
        <!--v-btn flat>
          <router-link to="/customers" tag="span" replace style="cursor:pointer"> Klienci</router-link>
        </v-btn>
        <v-btn flat>
          <router-link to="/courses" tag="span" replace style="cursor:pointer">Szkolenia</router-link>
        </v-btn>
        <v-btn flat>
          <router-link to="/" tag="span" replace style="cursor:pointer">Użytkownicy</router-link>
        </v-btn-->
      <v-btn v-if="$store.state.userCredentials.isAuth" @click="accountEditDlg.show = true" flat>
      <v-icon class="mr-2">person</v-icon>  {{ $store.state.userCredentials.email }}
      </v-btn>
      <v-btn @click="signOut" v-if="$store.state.userCredentials.isAuth" flat>
        <v-icon>input</v-icon>
      </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  <v-content>

    <v-container fluid class="container ml-6 pa-5">
         <router-view></router-view>
    </v-container>

  </v-content>

    <v-footer class="px-2">Version:&nbsp;{{ this.$VERSION }}<v-spacer></v-spacer><span class="ml-4 text-xs-right font-weight-bold"> Copyrights &copy; {{ new Date().getFullYear() }} Kancelaria&nbsp;Bezpieczeństwa</span></v-footer>

    <v-dialog v-model="accountEditDlg.show" persistent max-width="500px">
      <v-card>
        <v-card-title primary-title>
          <span class="title"><v-icon class="big mr-2">supervised_user_circle</v-icon>Zmień hasło</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
 <v-form ref="form" v-model="accountEditDlg.valid" lazy-validation>
   Ustaw nowe hasło dla <strong>{{ $store.state.userCredentials.email }}</strong>
          <v-text-field
            v-model="accountEditDlg.password"
            required
            name="password"
            :append-icon="accountEditDlg.pwdShow ? 'visibility_off' : 'visibility'"
            :rules="accountEditDlg.pwdRules"
            :type="accountEditDlg.pwdShow ? 'text' : 'password'"
            label="Hasło"
            mask
            @click:append="accountEditDlg.pwdShow = !accountEditDlg.pwdShow"
          ></v-text-field>

          <v-text-field
            v-model="accountEditDlg.rpassword"
            required
            name="rpassword"
            :append-icon="accountEditDlg.pwdShow ? 'visibility_off' : 'visibility'"
            :rules="repeatPasswordRules"
            :type="accountEditDlg.pwdShow ? 'text' : 'password'"
            label="Powtórz hasło"
            mask
            @click:append="accountEditDlg.pwdShow = !accountEditDlg.pwdShow"
          ></v-text-field>
    <!--v-checkbox
      v-model="admin"
      label="Uprawnienia administrator systemu"
      required
    ></v-checkbox-->
 </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat @click.native="accountEditDlg.show = false" color="error darken-1">Anuluj</v-btn>
                    <v-spacer></v-spacer>
          <v-btn flat @click="submitChangePwd" :disabled="!accountEditDlg.valid" color="green darken-1"> Zmień hasło<v-icon class="ml-2">save</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="technicalBreak" fullscreen hide-overlay transition="dialog-bottom-transition">
        Przepraszamy, trwa przerwa techniczna.
    </v-dialog>
  </v-app>
</template>

<script>

export default {
  props: {
    source: String
  },
  data: () => ({
    technicalBreak: false,
    showMenu: false,
    drawer: null,
    menuItems: [
      { icon: 'dvr', title: 'Webinarium', subtitle: 'Zaplanuj webinary', path: '/webinars' },
      { icon: 'assignment_ind', title: 'Lista klientów', subtitle: 'Zarządzaj klientami', path: '/customers' },
      { icon: 'description', title: 'Dokumenty', subtitle: 'Wszystkie wystawione', path: '/documents' },
      /* { icon: 'notifications', title: 'Kalendarz', subtitle: '', path: '/calender' }, */
      { icon: 'school', title: 'Szkolenia RODO', subtitle: 'Edytuj szkolenia', path: '/courses' },
      { icon: 'ballot', title: 'Egzaminy RODO', subtitle: 'Edytuj egzaminy', path: '/egzams' },
      { icon: 'supervised_user_circle', title: 'Użytkownicy', subtitle: 'Zmień uprawnienia', path: '/users' }
    ],
    menuItemsWeb: [
      { icon: '', title: 'Aktualności', path: '/news' }
    ],
    accountEditDlg: {
      pwdShow: false,
      show: false,
      password: '',
      rpassword: '',
      pwdRules: [
        v => !!v || 'Podaj nowe hasło',
        v => (/[0-9]+/.test(v) && /[a-z]+/.test(v) && /[A-Z]+/.test(v)) || 'Hasło powinno zawierać minimum 6 znaków - jedną literę (małą i dużą) oraz liczbę'
      ],
      rpwdRules: [
        v => !!v || 'Powtórz nowe hasło'
      ]

    }
  }),
  watch: {
    $route: {
      deep: true,
      handler (to, from) {
        /* this.$ACTIVITY_TIMER = setTimeout(() => {
            clearTimeout(this.$ACTIVITY_TIMER)
            this.clearCredentials()
            this.$router.push('/signin')
        },1000*60*20) */
      }
    }
  },
  computed: {
    currentTitle () {
      //console.log(this.$route)
      return this.$route.name
    },

    repeatPasswordRules (v) {
      return [
        (v) => (v === this.accountEditDlg.password) || 'Hasła różnią sie od siebie.'
      ]
    }
  },
  methods: {
    repwdCheck () {
      if (this.accountEditDlg.password !== this.accountEditDlg.rpassword || this.accountEditDlg.rpassword.length > 5) {
        return false
      }
      return true
    },
    clicked () {
    /* console.log('lickec')
        this.$ACTIVITY_TIMER = setTimeout(() => {
            clearTimeout(this.$ACTIVITY_TIMER)
            this.clearCredentials()
            this.$router.push('/signin')
        },1000*60*20) */

    },
    clearCredentials () {
      this.$store.state.userCredentials = {
        isAuth: false,
        token: null
      }
    },
    signOut () {
      this.clearCredentials()
      this.$router.push('/signin')
    },
    submitChangePwd () {
      if (this.$refs.form.validate()) {
        axios({
          url: this.$ROOT_PATH + '/change',
          method: 'post',
          data: {
            password: this.accountEditDlg.password
          },
          headers: {
            'X-API-Token': this.$store.state.userCredentials.token
          }
        })
          .then(response => {
            this.accountEditDlg.show = false
            this.accountEditDlg.password = ''
          })
          .catch(error => {
            this.accountEditDlg.password = ''
            console.log(error)
          })
      }
    }
  }
}
</script>
<style>
tbody tr:nth-child(odd) {
  background: #F8F8F8;
}
tbody tr:hover {
  background: #BBDEFB;
}

.v-list__tile--active {
  animation: rotate 0.5s;
  background: #E3F2FD !important;
}

.enter { transform: translateX(100%) }
.enter-to { transform: translateX(0) }
.slide-enter-active { position: absolute }

.leave { transform: translateX(0) }
.leave-to { transform: translateX(-100%) }

.slide-enter-active,
.slide-leave-active { transition: all 50ms ease-in-out }

.navigation .v-list__tile {
   transition: all 3000ms ease-in-out
}

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}
.fade-enter-active {
  transition-delay: 0s;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
.navigationBackground {
  background:url('https://a.odosc.pl/v1/assets/firm-paper2.png') bottom right no-repeat;background-size:100%;
}
table.v-table tbody td:first-child, table.v-table tbody td:not(:first-child), table.v-table tbody th:first-child, table.v-table tbody th:not(:first-child), table.v-table thead td:first-child, table.v-table thead td:not(:first-child), table.v-table thead th:first-child, table.v-table thead th:not(:first-child)
{
  padding:0px 12px !important;
}
.v-footer {
  border-top:1px solid #ddd;
}

  @keyframes rotate {
    0% {
      transform: scaleY(0)
    }
    100% {
      transform: scaleY(1)
    }
  }
</style>
