<template>
    <v-flex>
    <v-toolbar dense height="28" flat color="blue-grey lighten-5">
      <v-toolbar-title><v-icon class="mr-2">supervised_user_circle</v-icon>Dodajesz nowego użytkownika systemu</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card>
    <v-card-text class="mt-3">
 <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="name"
      :rules="nameRules"
      :counter="10"
      label="Imię i nazwisko"
      required
    ></v-text-field>
    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="Adres e-mail"
      required
    ></v-text-field>
          <v-text-field
            v-model="password"
            required
            name="login"
            :append-icon="passwordShow ? 'visibility_off' : 'visibility'"
            :rules="passwordRules"
            :type="passwordShow ? 'text' : 'password'"
            label="Hasło"
            mask
            @click:append="passwordShow = !passwordShow"
          ></v-text-field>

    <v-checkbox
      v-model="admin"
      label="Uprawnienia administrator systemu"
      required
    ></v-checkbox>

    <v-btn
      class="success"
      :disabled="!valid"
      @click="submit"
    >
      Dodaj użytkownika
    </v-btn>
    <v-btn to="/users" class="error">Anuluj</v-btn>
  </v-form>
</v-card-text>
    </v-card>

    </v-flex>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    valid: true,
    password: '',
    passwordShow: false,
    passwordRules: [
      v => !!v || 'Podaj hasło dla nowego użytkownika',
      v => (/[0-9]+/.test(v) && /[a-zA-Z]+/.test(v)) || 'Hasło powinno zawierać przynajmniej jeden znak oraz liczbę'
    ],
    name: '',
    nameRules: [
      v => !!v || 'Wpisz imię i nazwisko nowego użytkownika',
      v => (v && v.length >= 10) || 'Imię i nazwisko musi mieć conajmniej 10 znaków'
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail jest wymagany',
      v => /.+@.+/.test(v) || 'E-mail jest nieprawidłowy'
    ],
    admin: false
  }),

  methods: {
    submit () {
      if (this.$refs.form.validate()) {
        axios({
          url: this.$ROOT_PATH + '/auth/signup',
          method: 'post',
          data: {
            email: this.email,
            name: this.name,
            password: this.password,
            admin: this.admin
          },
          headers: {
            'X-API-Token': this.$store.state.userCredentials.token
          }
        })
          .then(response => {
            this.$router.push('/users')
          })
          .catch(error => {
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          })
          .then(() => {

          })
      }
    }
  }
}
</script>
