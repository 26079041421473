<template>
<v-layout fill-height column>
    <v-flex>
 <div id="content-top">
<v-card class="mx-auto courseBackground" max-width="700">
      <div class="text-xs-center pa-10 mt-2">
   <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kancelaria Bezpieczeństwa" width="330" />
    </div>
  <v-card-title><h3 class="display-1 grey--text darken-4 text-xs-center">
    {{ translation.egzamTitle }}</h3>
  </v-card-title>
  <v-divider></v-divider>
  <v-card-text>
    <v-window v-model="step" >
      <v-window-item v-for="(question,i) in questions" :key="question.egzams_questions_id" :value="i" style="min-height:200px;">
        <h4 class="title mt-3 mb-3"> {{ question.text }} </h4>
          <v-checkbox v-for="answer in answers.filter((a) => a.egzams_questions_id === question.egzams_questions_id)"
                        :key="answer.egzams_answers_id"
                        :value="1"
                        v-model="answer.is_true"
                        class="pa-0 ma-2"
                        hide-details
                        :label="answer.text"
                        @change="questions[i].answered = true"
            ></v-checkbox>
            <!-- powinno sprawdzac czy jakakolwiek odpowiedz jest zaznaczona -->
      </v-window-item>
    </v-window>
        </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="text-lg-center body-2 ml-2">
    <span>{{  translation.questionNumberOf }} <strong>{{ step+1 }}</strong> / {{steps}}</span>
    <v-spacer></v-spacer>
      <v-btn
        v-if="step > 0" depressed flat @click="prevStep" :disabled="!questions[step].answered">
        <v-icon class="mr-2">chevron_left</v-icon> {{ translation.prevQuestion }}</v-btn>
      <v-btn
        v-if="step < steps-1" color="primary" depressed text-color="white" @click="nextStep" :disabled="loadingItems || !questions[step].answered"
      >{{ translation.nextQuestion }} <v-icon class="ml-2">chevron_right</v-icon></v-btn>
      <v-btn
        v-if="step === steps-1" color="primary" depressed @click="checkAnswers" :loading="loadingItems"
        :disabled="loadingItems || !questions[step].answered">{{ translation.finishEgzam }} <v-icon class="ml-2">thumb_up_alt</v-icon></v-btn>
    </v-card-actions>
  </v-card>
      <v-dialog
        v-model="dialogWelcome" fullscreen hide-overlay
        transition="dialog-bottom-transition" scrollable>
      <v-card  class="align-center justify-center egzamIntroContent" v-if="this.$store.state.employerCredentials.language.toLowerCase() === 'pl'">
             <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kanelaria Bezpieczeństwa" width="350" class="logo" />

             <h1 class="headline mt-4">Egzamin RODO</h1>
             <p class="mt-4" style="max-width:600px;text-align:center">Przystępujesz do egzaminu na podstawie którego zostanie wydane Tobie zaświadczenie ukończenia szkolenia
                  oraz upoważnienie do przetwarzania danych osobowych.<br/><br/>
                  <strong>Pamiętaj!</strong> Więcej niż jedna odpowiedź na pytanie może być prawidłowa.<br/> <Br/> Powodzenia!
                  </p>
                  <p style="max-width:600px;text-align:center" class="align-center text-align-right">
                  <v-btn class="success" @click="dialogWelcome = false">Zaczynamy! <v-icon class="ml-2">play_circle_filled</v-icon></v-btn>
                      </p>
      </v-card>

      <v-card  class="align-center justify-center egzamIntroContent" v-if="this.$store.state.employerCredentials.language.toLowerCase() !== 'pl'">
        <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kanelaria Bezpieczeństwa" width="350" class="logo" />

        <h1 class="headline mt-4">GDPR Egzam</h1>
        <p class="mt-4" style="max-width:600px;text-align:center">
          You take the exam on the basis of which you will be issued a certificate of completion of the training
                        and authorization to process personal data.<br/><br/>
                        <strong>Remember!</strong> More than one answer to a question may be correct.<br/> <Br/> Good luck!
                        </p>
                        <p style="max-width:600px;text-align:center" class="align-center text-align-right">
          <v-btn class="success" @click="dialogWelcome = false">Let's go! <v-icon class="ml-2">play_circle_filled</v-icon></v-btn>
                 </p>
 </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFinish" fullscreen hide-overlay
        transition="dialog-bottom-transition" scrollable>
      <v-card  class="align-center justify-center egzamBackground" v-if="this.$store.state.employerCredentials.language.toLowerCase() === 'pl'">
             <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kanelaria Bezpieczeństwa" width="350" class="mb-4 logo"/>

            <div v-if="passed===true" style="text-align:center;" class="mt-10">
                    <h2 class="headline mt-6 text-xs-center" style="text-align:center;" color="green">Gratulacje! Egzamin zaliczony ;)</h2>

                <p style="max-width:600px;text-align:center;color:green" class="align-center mb-6 mt-2">
                    Uzyskałeś/-aś <br />
                    <span style="" class="display-3">{{ resoult }}%</span> <br/>
                    poprawnych odpowiedzi<br /><br />
                </p>
                <p>
                <v-btn @click="showAnswers()" color="primary"><v-icon class="mr-2">question_answer</v-icon> Zobacz odpowiedzi</v-btn> <br/>
                </p>
                        <p class="mt-4" style="max-width:600px;text-align:center">Prosimy pamiętać o pobraniu niezbędnych dokumentów poniżej</p>
         <p>
                                <v-btn color="primary" :href="this.$ROOT_PATH + '/documents/pdf/'+ documents.za " target="_blank" flat><v-icon class="mr-2">archive</v-icon> Pobierz zaświadczenie</v-btn> <br/>
            <v-btn v-if="documents.up.length > 10" color="primary" :href="this.$ROOT_PATH + '/documents/pdf/'+ documents.up " target="_blank" flat><v-icon class="mr-2">archive</v-icon> Pobierz upoważnienie</v-btn> <br/>
            <v-btn v-if="documents.kl.length > 10" color="primary" :href="this.$ROOT_PATH + '/documents/pdf/'+ documents.kl " target="_blank" flat><v-icon class="mr-2">archive</v-icon> Pobierz klauzulę poufności</v-btn>
</p>
             </div>
            <div v-if="passed===false"  style="text-align:center;" class="mt-10">
                    <h2 class="headline mt-6 text-xs-center" color="red">Upss! Egzamin niezaliczony ;(</h2>
             <!--p class="mt-4" style="max-width:600px;text-align:center">Twoje szkolenie zostało zakończone</p-->
                <p style="max-width:600px;text-align:center;color:red" class="align-center text-align-right mb-6 mt-2">
                   Uzyskałeś/-aś<br />
                    <span style="" class="display-3">{{ resoult }}%</span> <br/>
                    poprawnych odpowiedzi<br /><br />
                </p>
             <p class="mt-4" style="max-width:600px;text-align:center">Sprawdż swoje odpowiedzi aby zobaczyć gdzie zostały popełnione błędy. Zapraszamy do przejścia szkolenia i podejścia do egzaminu ponownie.</p>
                <p>
                   <v-btn @click="showAnswers()" color="primary"><v-icon class="mr-2">question_answer</v-icon> Zobacz odpowiedzi</v-btn>
                <v-btn class="success" @click="repeatCourse()"><v-icon class="mr-2">play_circle_filled</v-icon> Powtórz szkolenie</v-btn>
                </p>
   </div>
             <!--v-btn to="/identify" color="primary">Następna osoba</v-btn-->
      </v-card>
      <v-card  class="align-center justify-center egzamBackground" v-if="this.$store.state.employerCredentials.language.toLowerCase() !== 'pl'">
        <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kanelaria Bezpieczeństwa" width="350" class="mb-4 logo"/>

       <div v-if="passed===true" style="text-align:center;" class="mt-10">
               <h2 class="headline mt-6 text-xs-center" style="text-align:center;" color="green">Congratulations! You passed ;)</h2>

           <p style="max-width:600px;text-align:center;color:green" class="align-center mb-6 mt-2">
               You gained <br />
               <span style="" class="display-3">{{ resoult }}%</span> <br/>
               of correct answers<br /><br />
           </p>
           <p>
           <v-btn @click="showAnswers()" color="primary"><v-icon class="mr-2">question_answer</v-icon> Show answers</v-btn> <br/>
           </p>
                   <p class="mt-4" style="max-width:600px;text-align:center">Please download and print documents below.</p>
    <p>
      <v-btn color="primary" :href="this.$ROOT_PATH + '/documents/pdf/'+ documents.za " target="_blank" flat><v-icon class="mr-2">archive </v-icon> Download certificate</v-btn> <br/>
              <v-btn v-if="documents.up.length > 10" color="primary" :href="this.$ROOT_PATH + '/documents/pdf/'+ documents.up " target="_blank" flat> <v-icon class="mr-2">archive</v-icon> Download authorization</v-btn> <br/>
              <v-btn v-if="documents.kl.length > 10" color="primary" :href="this.$ROOT_PATH + '/documents/pdf/'+ documents.kl " target="_blank" flat> <v-icon class="mr-2">archive</v-icon> Download privacy statement</v-btn>
</p>
        </div>
       <div v-if="passed===false"  style="text-align:center;" class="mt-10">
               <h2 class="headline mt-6 text-xs-center" color="red">Yay! Egzam failed ;(</h2>
        <!--p class="mt-4" style="max-width:600px;text-align:center">Twoje szkolenie zostało zakończone</p-->
           <p style="max-width:600px;text-align:center;color:red" class="align-center text-align-right mb-6 mt-2">
              You gained<br />
               <span style="" class="display-3">{{ resoult }}%</span> <br/>
               of correct answers<br /><br />
           </p>
        <p class="mt-4" style="max-width:600px;text-align:center">
          Check your answers to see where mistakes were made. We invite you to go through the training and take the exam again.</p>
           <p>
              <v-btn @click="showAnswers()" color="primary"><v-icon class="mr-2">question_answer</v-icon> Show answers</v-btn>
           <v-btn class="success" @click="repeatCourse()"><v-icon class="mr-2">play_circle_filled</v-icon> Repeat course</v-btn>
           </p>
</div>
        <!--v-btn to="/identify" color="primary">Następna osoba</v-btn-->
 </v-card>
      </v-dialog>
        <v-dialog v-model="dialogAnswers"
        fullscreen
        transition="dialog-bottom-transition"
        scrollable>
              <v-card style="max-width:800px;margin:0 auto;">
        <v-card-title primary-title>
          <span class="title" v-if="this.$store.state.employerCredentials.language.toLowerCase() === 'pl'">Twoje odpowiedzi egzaminacyjne</span>
          <span class="title" v-if="this.$store.state.employerCredentials.language.toLowerCase() !== 'pl'">Yours egzamination answers</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
      <div class="mt-5" v-for="(question,i) in questions" :key="question.egzams_questions_id" :value="i" >
<span class="body mb-3"> {{ question.text }} </span>
             <v-checkbox v-for="answer in answers.filter((a) => a.egzams_questions_id === question.egzams_questions_id)"
                        :key="answer.egzams_answers_id"
                        :value="1"
                        v-model="answer.is_true"
                        class="pa-0 ma-2"
                        hide-details
                        disabled
                        :prepend-icon="!isValidAnswer(answer.egzams_answers_id, true) ? 'close' : 'check'"
                        :label="answer.text"
                        :color="isValidAnswer(answer.egzams_answers_id, true) ? 'black' : 'red'"
            ></v-checkbox> <span v-if="answer.is_true">(poprawna)</span>
    <v-divider></v-divider>
      </div>
      </v-card-text>
      <v-card-actions>
      <v-btn color="primary" @click="dialogAnswers = false" flat>{{ translation.backToResoultBtn }}</v-btn>
      </v-card-actions>
      </v-card>
      </v-dialog>
  </div>
    </v-flex>
</v-layout>

</template>

<script>

import axios from 'axios'
import '../../node_modules/chartist/dist/chartist.min.css'

export default {

  data: () => ({

    passed: false,
    resoultTest: 0,
    resoult: 0,
    dialogWelcome: false,
    dialogFinish: false,
    dialogAnswers: false,
    courseDialog: false,
    answersValid: [],
    answers: [],
    questions: [],
    choosenAnswers: [],
    egzam: { scope: 'brak' },
    loadingItems: false,
    allAnswers: 0,
    egzamAnswered: false,
    questionAnswered: false,
    documents: {
      az: '',
      up: '',
      kl: ''
    },

    i18n: {
      pl: {
        questionNumberOf: 'Pytanie ',
        prevQuestion: 'Wstecz',
        nextQuestion: 'Następne',
        finishEgzam: 'Zakończ',
        egzamFinished: 'Szkolenie zakończone',
        youGained: 'Uzyskałeś/-aś',
        ofCorrectAnswers: 'poprawnych odpowiedzi',
        egzamTitle: 'Egzamin RODO',
        backToResoultBtn: 'Powrót do wyniku egzaminu'
      },
      en: {
        questionNumberOf: 'Question ',
        prevQuestion: 'Previous',
        nextQuestion: 'Next',
        finishEgzam: 'Finish',
        egzamFinished: 'Egzam finished',
        youGained: 'You gained',
        ofCorrectAnswers: 'of correct answers',
        egzamTitle: 'GDPR Egzam',
        backToResoultBtn: 'Close answers'
      }
    },
    e1: 1,
    step: 0,
    steps: 10
  }),
  created () {
    // this.loadCoursePages(this.$route.params.id)
    if (this.$store.state.employerCredentials.employer_id > 0) {
      this.loadEgzamPages(this.$route.params.token, this.$route.params.lang)
      this.dialogWelcome = true
    } else { this.$router.push('/identify') }
  },
  computed: {
    currentTitle () {
      return ('Pytanie numer ' + (this.step + 1) + '/' + this.steps)
    },
    translation: function () {
      if (this.$store.state.employerCredentials.language.toLowerCase() === 'pl') {
        return this.i18n.pl
      } else {
        return this.i18n.en
      }
    }
  },
  methods: {
    nextStep: function () {
      if (this.questions[this.step].answered) {
        if (this.step === this.steps) {
          this.dialogFinish = true
        } else {
          this.step++
        }
        this.stepChanged(this.step)
        this.resoultTest = this.checkResoult()
      }
    },
    prevStep: function () {
      if (this.questions[this.step].answered) {
        if (this.step === this.steps) {
          this.dialogFinish = true
        } else {
          this.step--
        }
        this.stepChanged(this.step)
      }
    },
    repeatCourse () {
      this.$router.push('/identify')
    },
    answer () {
      if (this.questionAnswered) {
        return false
      }
    },

    showAnswers () {
      this.dialogAnswers = true
    },
    isValidAnswer (egzamsAnswersId, isTrue) {
      for (var i = 0; i < this.answersValid.length; i++) {
        if (this.answersValid[i].egzams_answers_id === egzamsAnswersId &&
                    Boolean(this.answersValid[i].is_true) === Boolean(isTrue)) {
          return true
        }
      }
      this.answersValid.forEach((a) => {
        if (Boolean(isTrue) === Boolean(a.is_true) &&
                        a.egzams_answers_id === egzamsAnswersId) {
          return true
        }
      })
      return false
    },

    checkAnswers () {
      this.loadingItems = true
      axios({
        url: this.$ROOT_PATH + '/egzams/check',
        method: 'post',
        data: {
          answers: this.answers,
          employer: this.$store.state.employerCredentials
        }
      })
        .then(firstResponse => {
          console.log(firstResponse.data)
          console.log(this.$store.state.employerCredentials)

          this.resoult = firstResponse.data.resoult
          this.passed = firstResponse.data.passed
          if (firstResponse.data.passed) {
            this.documents.za = firstResponse.data.documents.ZA.token
            this.documents.up = firstResponse.data.documents.UP.token
            this.documents.kl = firstResponse.data.documents.KL.token !== '' ? firstResponse.data.documents.KL.token : ''
          }
          this.dialogFinish = true
          this.loadingItems = false
          this.answersValid = firstResponse.data.answers_valid
          this.$store.state.employerCredentials = { employer_id: 0, language: this.$store.state.employerCredentials.language }
        })
        .catch(error => {
          this.loadingItems = false
          console.log(error)
          this.showSnakbar('Błąd! Wczytanie pytań egzaminu nie powiodło.', 'error')
        })
    },
    checkResoult () {
      let egzamResoult = 0
      let allAnswers = 0
      this.questions.forEach((q) => {
        this.answers.forEach((a) => {
          if (q.egzams_questions_id === a.egzams_questions_id) {
            egzamResoult += (((typeof a.is_true === 'undefined' ? false : a.is_true) != a.is_true) ? 0 : 1)
            allAnswers++
          }
        })
      })
      this.resoult = parseInt(Math.floor((egzamResoult / (allAnswers)) * 100))
    },
    loadEgzamPages (token, lang) {
      this.loadingItems = true
      axios.all([axios({ url: this.$ROOT_PATH + '/egzams/start/' + token + '/' + lang, method: 'get' })])
        .then(axios.spread((firstResponse) => {
          this.questions = firstResponse.data.questions
          for (var i = 0; i < this.questions.length; i++) {
            this.questions[i].answered = false
          }

          /* this.questions.forEach((q) => {
            q.answered = false
          }) */
          this.answers = firstResponse.data.answers
          this.steps = this.questions.length
          this.stepChanged(this.e1)
          this.loadingItems = false
        }))
        .catch(error => {
          this.loadingItems = false
          console.log(error)
          this.showSnakbar('Błąd! Wczytanie pytań egzaminu nie powiodło.', 'error')
        })
    },
    nextStepp (n) {
      if (this.question[this.step].answered) {
        this.questionAnswered = false
        if (n === this.steps) {
          this.e1 = 1
        } else {
          this.e1 = n + 1
        }
        this.stepChanged(this.e1)
        this.resoultTest = this.checkResoult()
      }
    },

    stepChanged (n) {
      //   this.courseAnswered = false
    }
  },
  watch: {
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    }
  }

}

</script>

<style scoped>
.logo {
    border:1px solid #eee;
}
.container {
    padding:16px 0px !important;
}
.v-input__control {
    padding:2px !important;
    margin:2px !important;
}
.egzamIntroContent,
.egzamBackground {
    background:url('https://a.odosc.pl/v1/assets/firm-paper2.png') right top no-repeat;background-size:100%;background-color:white;
}

</style>
