<template>
  <v-layout wrap>
        <v-toolbar v-if="this.$store.state.userCredentials.isAuth" dense height="28" flat color="blue-grey lighten-5" class="mb-3">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip>
          <v-icon class="mr-2">dvr</v-icon>Moderacja webinaru</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-btn @click="videoStart()" v-if="!videoplaying" :disabled="controlsDisabled || pages[this.e1 -1].type !== 'movie'" color="success" depressed>odtwórz <v-icon class="ml-2">ondemand_video</v-icon></v-btn>
        <v-btn v-if="videoplaying" @click="videoStart()" :disabled="controlsDisabled || pages[this.e1 -1].type !== 'movie'" color="success" depressed>zatrzymaj <v-icon>pause_circle_outline</v-icon></v-btn>
         <v-btn @click="slideSpeech('start')" :loading="playing" :disabled="controlsDisabled || pages[this.e1 -1].type === 'movie'" color="success" depressed>lektor<v-icon class="ml-2">volume_up</v-icon></v-btn>
         <v-divider
         class="mx-2"
         inset
         vertical
       ></v-divider>
         <v-btn @click="playSlide()" color="success" v-if="!slideplaying" :disabled="controlsDisabled || pages[this.e1 -1].type === 'movie'" depressed><v-icon>play_circle_outline</v-icon></v-btn>
         <v-btn @click="stopSlide()" color="primary" v-if="slideplaying" :disabled="controlsDisabled || pages[this.e1 -1 ].type === 'movie'" depressed><v-icon>pause_circle_outline</v-icon></v-btn>
      <v-btn @click="prevSlide()" color="primary" :disabled="e1 === 0 || controlsDisabled" depressed><v-icon class="mr-2">west</v-icon> <v-icon>dvr</v-icon></v-btn>
      <v-btn @click="nextSlide()" color="primary" :disabled="e1 === pages.length || controlsDisabled" depressed><v-icon>dvr</v-icon> <v-icon class="ml-2">east</v-icon></v-btn>
      </v-toolbar>
    <v-alert color="info">
        Webinar zaplanowany jest na godzine {{ moment(webinar.start_datetime).format('HH:mm') }} dnia {{ moment(webinar.start_datetime).format('dd:MM') }}
    </v-alert>
    <v-layout wrap>
      <!--v-flex md12>
        <v-card v-if="this.$store.state.userCredentials.isAuth" class="elevation-0 text-xs-center pa-0 ma-0">
          <v-card-text class="pa-1">
          <v-slider v-model="e1" class="mx-4 mt-4" :disabled="controlsDisabled"
            :step="1" color="primary" ticks="always" thumb-label="always"
            :size="15" :min="0" :max="pages.length" :tick-size="5" @change="slideChange()" width="200"></v-slider-->
        <!--v-btn @click="playSlide()" color="success" v-if="!slideplaying" :disabled="controlsDisabled" depressed> <v-icon>play_circle_outline</v-icon></v-btn>
        <v-btn @click="stopSlide()" color="primary" v-if="slideplaying" :disabled="controlsDisabled" depressed> <v-icon>pause_circle_outline</v-icon></v-btn>
        <v-btn @click="prevSlide()" color="primary" :disabled="e1 === 0 || controlsDisabled" depressed><v-icon class="mr-2">west</v-icon> <v-icon>dvr</v-icon></v-btn>
        <v-btn @click="nextSlide()" color="primary" :disabled="e1 === pages.length || controlsDisabled" depressed><v-icon>dvr</v-icon> <v-icon class="ml-2">east</v-icon></v-btn>
        <v-btn @click="slideSpeech('start')" :loading="playing" :disabled="controlsDisabled" color="success" depressed>lektor<v-icon class="ml-2">volume_up</v-icon></v-btn>
        <v-btn @click="videoStart()" v-if="!videoplaying" :disabled="controlsDisabled" color="success" depressed>
            odtwórz <v-icon class="ml-2">ondemand_video</v-icon></v-btn>
            <v-btn v-if="videoplaying" @click="videoStart()" :disabled="controlsDisabled" color="success" depressed>
             zatrzymaj <v-icon>pause_circle_outline</v-icon></v-btn-->
             <!--span class="ml-2">{{ Math.round(seek,2) }}/{{ Math.round(duration) }}</span-->

        <!--v-slider v-model="seek" class="mx-4 mt-4" :disabled="controlsDisabled"
             :step="1" color="primary"
             :size="5" :min="0" :max="duration" :tick-size="1"></v-slider-->
        <!--v-btn @click="connect" :disabled="!controlsDisabled" color="success">Połacz</v-btn>
      </v-card-text>
        </v-card>
      </v-flex-->
    <v-flex md8>
      <!--v-hover>
          <v-card class="ma-0 elevation-1 fill-height" slot-scope="{ hover }">
            <v-expand-transition>
              <div
                v-if="!hover"
                class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal display-3 white--text"
                style="height: 100%;"
              >
               {{ pagesMovies[0].start_datetime }}
              </div>
            </v-expand-transition>
            <v-progress-linear v-model="timerSlide" size="5" :color="timerSlide / slideReadingTime * 100 < 20 ? 'red' : 'green'"></v-progress-linear>
            <v-card-title primary-title class="text-xs-center">
              <h5 class="headline text-xs-center">{{ pagesMovies[0].title }}</h5>
            </v-card-title>
            <v-card-text class="fill-height">
              <video v-if="pagesMovies[0].type == 'movie'" id="video" ref="video" width="100%" fill-height max-height="400px">
                <source :src="$BASE_PATH + '/storage/movies/' + pagesMovies[0].text" type="video/webm">
              </video>
            </v-card-text>
          </v-card>

      </v-hover-->
          <v-stepper v-model="e1" vertical class="fill-height pa-0">
            <!--v-stepper-header v-for="n in pages.length" :step="n" :key="n">
            <v-stepper-step>
            </v-stepper-step>
          </v-stepper-header-->
          <v-stepper-items>
      <v-stepper-content v-for="n in pages.length" :step="n" :key="n">
              <v-progress-linear :value="timerSlide / slideReadingTime * 100" size="5" :color="timerSlide / slideReadingTime * 100 < 20 ? 'red' : 'green'"></v-progress-linear>
          <h5 class="title text-xs-center mb-1 mt-1">{{ pages[n - 1].title }}</h5>
           <div style="text-align:justify;text-justify:inter-word;" class="fill-height pa-4">
              <span  v-if="pages[n - 1].type == 'text'"  class="font-weight-normal" v-html="pages[n - 1].text"></span>
              <video v-if="pages[n - 1].type == 'movie'" :id="'video-' + n" :ref="'video-' + n" width="100%" fill-height height="400px">
                <source :src="$BASE_PATH + '/storage/movies/' + pages[n - 1].text" type="video/webm">
              </video>
            </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    </v-flex>
    <v-flex md4 class="fill-height pa-2" fill-height width="100%">
        <div class="messages mt-1" ref="messages" id="messages" fill-height height="100%" >
          <div v-for="(msg, key) in messages.filter((m) => typeof m.message !== undefined)" :id="'message' + key" :key="key" class="mt-1">
            <v-divider class="mb-1"></v-divider>
            <span class="grey--text">({{ getCurrentTime('HH:mm') }}) </span>
            <span v-if="msg.type.toLowerCase() === 'notice'"><span :class="'font-weight-bold ' + (msg.moderator ? 'green' : 'blue-grey') + '--text darken-2'">{{ msg.nickname }}</span> <span v-if="msg.moderator"> (moderator)</span>:<br/> {{ msg.message }}</span>
            <span v-if="msg.type.toLowerCase() === 'system'" :class="( msg.color !== 'black' ? msg.color : 'black')  + '--text'"><span class="font-italic"> * {{ msg.message }}</span></span>
            <!--span v-if="msg.type.toLowerCase() === 'connected'">(<span class="text--grey">{{ getCurrentTime('HH:mm')  }}) </span><span class="text--grey font-italic"> * {{ msg.message }}</span></span-->
            <!--span v-if="msg.type.toLowerCase() !== 'system' && msg.type.toLowerCase() !== 'notice'">{{ msg.type }} - {{ msg.message }}</span-->
          </div>
        </div>
        <v-text-field class="mt-2 elevation-0 message body-2" v-model="message" hide-details :disabled="login.length < 1" @keydown.enter="send()" label="Wiadomość" append-icon="send" outline></v-text-field> 
    </v-flex>

  </v-layout>

  <v-dialog v-model="loginDlg" persistent width="400">
    <v-card>
    <v-card-title class="title">
      Logowanie do Webinaru
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-xs-center">
      <v-icon color="primary" size="100" class="mr-2">dvr</v-icon>
      <br /><br/>
      <strong>Witamy!</strong> Bierzesz udział w webinarze dotyczącym:<br/> <span class="font-italic">{{ webinar.scope }}</span>.
      <br/><br/> Prosimy podać imię i nazwisko, aby zalogować się i wziąść udział w webinarze.<br/><br/>
       <v-text-field
        class="elevation-0 ma-4"
        v-model="login"
        label="Imię i nazwisko"
        prepend-inner-icon="person"
        hide-details
        outline
        ></v-text-field>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="loginDlg = false; connect()" color="success" :disabled="login.length < 6" depressed>Zaloguj się <v-icon class="ml-2">login</v-icon></v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>

  </v-layout>
</template>

<script>
import axios from 'axios'
import * as moment from 'moment'
import * as easings from 'vuetify/es5/util/easing-patterns'

export default {
  data: () => ({
    loginDlg: false,
    message: '',
    messages: [],
    controlsDisabled: true,
    e1: 0,
    step: 1,
    seek: 0,
    type: 'month',
    start: '2019-01-01',
    end: '2019-01-06',
    typeOptions: [
      { text: 'Day', value: 'day' },
      { text: '4 Day', value: '4day' },
      { text: 'Week', value: 'week' },
      { text: 'Month', value: 'month' },
      { text: 'Custom Daily', value: 'custom-daily' },
      { text: 'Custom Weekly', value: 'custom-weekly' }
    ],
    login: 'Gość',
    channel: 'ID1',
    websocket: null,
    server: 'wss://szkolenia.odosc.pl:5678',
    webinar: { title: 'Ładowanie danych', scope: '' },
    pages: [],
    synth: window.speechSynthesis,
    playing: false,
    videoplaying: false,
    slideplaying: false,
    timeRanges: {},
    duration: 0,
    timerSlide: 0,
    timerSlideStarting: 0,
    slideInterval: 0,
    playVideoDelay: 0,
    updateTimer: null,
    connectTimer: null
  }),
  created () {
    var min = 10
    var max = 99
    this.login = 'Gosc-' + Math.floor(Math.random() * (max - min + 1)) + min
    this.channel = 'ID' + this.$route.params.id
    this.loadWebinarPages(this.$route.params.id)
  },
  mounted () {
    var self = this
    this.updateTimer = setInterval(() => {
      self.playing = self.synth.speaking
      if (this.getSlideVideo()) {
        this.timerSlide = (this.getSlideVideo().currentTime / this.getSlideVideo().duration) * 100
        console.log(this.timerSlide + ' ' + this.timerSlideStarting)
        this.timerSlide -= 100
      }
    }, 100)
  },
  unmounted () {
    this.stopVideo()
    clearInterval(this.updateTimer)
    this.disconnect()
  },
  computed: {
    isSpeaking () {
      return this.synth.speaking
    },
    slideReadingTime () {
      var extraTime = 15000
      if (this.getSlideVideo()) {
        return (this.getSlideVideo().duration * 100) + extraTime
      }
      if (!this.pages[this.e1 - 1]) {
        return extraTime
      }
      return ((this.pages[this.e1 - 1].text.length + this.pages[this.e1 - 1].title.length) * 70) + extraTime
    },
    moderatorName () {
      return this.$store.state.userCredentials.name
    },
    pagesMovies () {
      return this.pages.filter((p) => p.type === 'movie')
    },
    videoTimeout () {
      return (this.timerSlide / this.slideReadingTime) * 100
    }
  },
  methods: {

    moment (date) {
      return moment(date)
    },

    stopSlide () {
      clearInterval(this.slideInterval)
      this.slideplaying = false
    },

    playSlide () {
      this.stopSlide()
      this.slideInterval = setInterval(() => {
        this.slideplaying = true
        this.timerSlide -= 100
        this.playVideoDelay += 100
        if (this.timerSlide <= 0 && this.timerSlide >= -100 && this.slideplaying) {
          this.nextSlide()
        }
        if (this.playVideoDelay === 3000) {
          this.playVideo()
        }
      }, 100)
    },

    nextSlide () {
      this.stopVideo()
      this.e1 += 1
      this.slideChange()
      this.synth.pause()
      this.synth.cancel()
      this.timerSlide = this.slideReadingTime
    },
    prevSlide () {
      this.stopVideo()
      this.e1 -= 1
      this.slideChange()
      this.synth.pause()
      this.synth.cancel()
      this.timerSlide = this.slideReadingTime
    },
    stopVideo () {
      var video = this.getSlideVideo()
      if (video) {
        video.pause()
        this.videoplaying = false
      }
    },
    getSlideVideo () {
      return document.getElementById('video-' + this.e1)
      //return document.getElementById('video')
    },
    playVideo () {
      var i = setInterval(() => {
        var video = this.getSlideVideo()
        if (video) {
          this.videoplaying = true
          video.volume = 1
          video.play()
          video.onpause = (event) => {
            this.videoplaying = false
          },
          video.ontimeupdate = (event) => {
            this.seek = event.target.currentTime
          }
          this.timerSlideStarting = this.getSlideVideo().duration * 1000
          this.timerSlide = this.getSlideVideo().duration * 1000
          console.log(this.timerSlide)
          clearInterval(i)
        }
      }, 100)
    },

    playVoice (n) {
      if (this.synth.speaking) {
        this.synth.cancel()
        return
      }
      let utterance = new SpeechSynthesisUtterance(this.pages[n - 1].text.replace(/\<video\>.*\<\/video\>/ig, '').replace(/<[^>]+>/ig, ''))
      if (utterance.length < 3) {
        return
      }
      utterance.lang = 'pl-PL'
      this.synth.speak(utterance)
    },

    getCurrentTime (format) {
      return moment().format(format)
    },
    loadWebinarPages (webinar_id) {
      axios.all([axios({ url: this.$ROOT_PATH + '/webinar/' + webinar_id, method: 'get' })])
        .then(axios.spread((firstResponse) => {
          this.pages = firstResponse.data.pages
          this.webinar = firstResponse.data.webinar
          if (this.$store.state.userCredentials.isAuth) {
            this.login = this.$store.state.userCredentials.name
            this.connect()
          } else {
            this.loginDlg = true
          }
        }))
        .catch(error => {
          this.loadingItems = false
          this.showSnakbar('Błąd! Wczytanie webinaru nie powiodło się.', 'error')
        })
    },

    videoStart () {
      if (!this.videoplaying) {
        this.sendRaw({ type: 'VIDEO', state: 'START', number: this.e1 })
      } else {
        this.sendRaw({ type: 'VIDEO', state: 'STOP', number: this.e1 })
      }
    },
    slideChange () {
      this.sendRaw({ type: 'SLIDE', number: this.e1 })
    },
    slideSpeech (st) {
      this.sendRaw({ type: 'SPEECH', state: st, number: this.e1 })
    },
    disconnect () {
      this.websocket.close()
    },
    send () {
      if (this.message.substr(0, 1) === '/') {
        this.sendRaw(this.message.substr(1, this.message.length))
      } else {
        this.sendRaw({
          type: 'NOTICE',
          channel: this.channel,
          nickname: this.login,
          message: this.message,
          moderator: this.$store.state.userCredentials.isAuth
        })
      }
      this.message = ''
    },
    sendRaw (raw) {
      // if (!this.websocket || this.websocket.state !== 1)
      //  return
      if (typeof raw === 'object') {
        raw = JSON.stringify(raw)
      }
      this.websocket.send(raw)
    },

    display (txt, type, color) {
      this.messages.push({ type: type, message: txt, color: color, time: this.getCurrentTime('HH:mm') })
    },
    onMessage (event) {
      var data = event.data
      if (data.length < 5) { return }
      data = decodeURIComponent(data)
      data = JSON.parse(data)
      data.type = data.type.toUpperCase()
      if (data.type === 'CONNECTED') {
        if (data.nickname.toLowerCase() !== this.login.toLowerCase()) {
          this.display('Dołączył/-a ' + data.nickname + '.', 'system', 'green')
        } else {
          this.display('Witamy na webinarze!', 'system')
        }
        // this.nextSlide()
        return
      }
      if (data.type === 'DISCONNECTED') {
        this.display(data.nickname + ' wylogował/-a się.', 'system', 'grey')
        return
      }
      if (data.type === 'VIDEO') {
        if (data.state.toLowerCase() === 'start') {
          this.playVideo()
        } else {
          this.stopVideo()
        }
        return
      }
      if (data.type === 'SLIDE') {
        this.synth.pause()
        this.synth.cancel()
        this.e1 = data.number
        if (this.e1 > 0 && this.e1 <= this.pages.length) { // powinno byc 0
          this.display('Temat: ' + this.pages[this.e1 - 1].title, 'system', 'brown')
        } else {
          this.e1 = 1
        }
        this.playVideo() // ##############################
        return
      }
      if (data.type === 'SPEECH') {
        if (data.state.toLowerCase() === 'start') {
          this.playVoice(data.number)
        }
        return
      }
      data.time = this.getCurrentTime('HH:mm')
      this.messages.push(data)
      var id = '#message' + (this.messages.length - 2)
      this.$vuetify.goTo(id, { offset: -500, duration: 1000, easing: 'easeInOutCubic', container: '#messages' })
    },
    onError (event) {
      this.display('Błąd połączenia.', 'system')
    },
    onClose (event) {
      this.sendRaw({ type: 'DISCONNECTED', nickname: this.login })
      this.display('Rozłączono.', 'system', 'red')
      this.controlsDisabled = true
      var self = this
      this.connectTimer = setInterval(() => { this.connect(); clearInterval(self.connectTimer) }, 10000)
    },
    onOpen (event) {
      this.display('Połączono.', 'system', 'green')
      this.sendRaw({ type: 'ATTACH', channel: this.channel, nickname: this.login })
      this.controlsDisabled = false
    //  this.display('Temat: ' + this.pagesMovies[0].title, 'system', 'brown')
    },
    connect () {
      if (this.websocket && this.websocket.state === 1) {
        return
      }
      this.display('Łączenie z serwerem...', 'system', 'grey')
      this.websocket = new WebSocket(this.server)
      this.websocket.onopen = this.onOpen
      this.websocket.onmessage = this.onMessage
      this.websocket.onerror = this.onError
      this.websocket.onclose = this.onClose
    }
  }
}
</script>
<style>
.message {
  background-color:#fff;
}
.messages {
  height:500px;overflow-y:scroll;padding:10px;
  border-radius:4px;
  border:2px solid #777;
  background-color:#fff;
}
.v-card__text img {
  max-height:300px;
}
.v-card__actions {
  background:#f5f5f5;
}
.v-stepper--vertical .v-stepper__step {
  padding:12px 12px 6px;
}
.v-stepper__step__step {
  width:20px;
  margin:0px;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>
