<template>
    <div class="pa-8">
    <h2>Upsss.. nie ma takiej strony!</h2>

    <choice-color :colors='colors' radius='10em' @updateColor='updateColor'></choice-color>
</div>
</template>
<script>
export default {
  colors: [
    '#1ba6cc',
    '#189ba7',
    '#98c6ae',
    '#45a270',
    '#7cb325',
    '#eb9826'
  ],
  data () {
    return {
      colors: colors,
      index: 0,
      color: '#1ba6cc'
    }
  },
  methods: {
    updateColor ({ index, color }) {
      this.index = index
      this.color = color
    }
  }

}

</script>
