import Vue from 'vue'
import store from './store'
import Router from 'vue-router'

import NotFound from './views/NotFound.vue'

import InService from './views/InService.vue'

import Dashboard from './views/Dashboard.vue'

import Companies from './views/Companies.vue'
import CustomerChange from './views/Customers/Change.vue'

import SigninComponent from './views/Signin.vue'

import Notices from './views/Notices.vue'

import Users from './views/Users.vue'
import UserNew from './views/Users/New.vue'
import UserChange from './views/Users/Change.vue'

import Courses from './views/Courses.vue'
import CourseNew from './views/Courses/New.vue'
import CourseChange from './views/Courses/Change.vue'

import Egzams from './views/Egzams.vue'
import EgzamsChange from './views/Egzams/Change.vue'

import Documents from './views/Documents.vue'
import DocumentView from './views/Documents/Document.vue'

import Course from './views/Course.vue'

import Webinars from './views/Webinars.vue'
import WebinarsChange from './views/Webinars/Change.vue'
import Webinar from './views/Webinar.vue'

import Ident from './views/Identify.vue'

import Egzam from './views/Egzam.vue'
import EgzamPeriod from './views/EgzamPeriod.vue'

import Submit from './views/Submit.vue'

// import AuthGuard from './components/AuthGuard'

Vue.use(Router)
var routes = [
  {
    path: '/',
    component: Ident,
    meta: { requireAuth: false }
  },
  {
    path: '/submit',
    component: Submit,
    meta: { requireAuth: false }
  },
  {
    path: '/service',
    component: InService,
    meta: { requireAuth: false }
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: { requireAuth: false }
  },
  {
    path: '/webinar/:id',
    component: Webinar,
    name: 'Uczestniczysz w Webinarze',
    meta: { requireAuth: false }
  },
  {
    path: '/webinars',
    component: Webinars,
    meta: { requireAuth: true }
  },
  {
    path: '/webinars/edit/:id',
    component: WebinarsChange,
    meta: { requireAuth: true }
  },
  {
    path: '/identify',
    component: Ident,
    meta: { requireAuth: false }
  },
  {
    path: '/notices',
    component: Notices,
    meta: { requireAuth: true }
  },
  {
    path: '/users',
    component: Users,
    meta: { requireAuth: true }
  },
  {
    path: '/users/edit/:id',
    component: UserChange,
    meta: { requireAuth: true }
  },
  {
    path: '/users/new',
    component: UserNew,
    meta: { requireAuth: true }
  },
  {
    path: '/e/:cid/:eid',
    component: EgzamPeriod,
    meta: { requireAuth: false }
  },
  {
    path: '/egzam/:token/:lang',
    component: Egzam,
    meta: { requireAuth: false }
  },
  {
    path: '/signin',
    name: 'Logowanie',
    component: SigninComponent,
    meta: { requireAuth: false }
  },
  {
    path: '/egzams',
    name: 'Egzaminy RODO',
    component: Egzams,
    meta: { requireAuth: true }
  },
  {
    path: '/egzams/edit/:id',
    name: 'Egzaminy RODO / Edycja',
    component: EgzamsChange,
    meta: { requireAuth: true }
  },
  {
    path: '/course/:id',
    component: Course,
    meta: { requireAuth: false }
  },
  {
    path: '/courses',
    component: Courses,
    meta: { requireAuth: true }
  },
  {
    path: '/document/view/:id',
    component: DocumentView,
    meta: { requireAuth: true }
  },
  {
    path: '/documents',
    component: Documents,
    meta: { requireAuth: true }
  },
  {
    path: '/courses/new',
    component: CourseNew,
    meta: { requireAuth: true }
  },
  {
    path: '/courses/edit/:id',
    component: CourseChange,
    meta: { requireAuth: true }
  },
  {
    path: '/customers',
    name: 'Klienci',
    component: Companies,
    meta: { requireAuth: true }
  },
  {
    path: '/customers/edit/:id',
    component: CustomerChange,
    meta: { requireAuth: true }
  },
  {
    path: '*',
    component: NotFound
  }
]

routes.forEach((e) => {
  if (typeof e.title === 'undefined') {
    e.title = ''
  }
})

const router = new Router({
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (!store.state.userCredentials.isAuth) {
      next({
        path: '/signin',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    if (store.state.userCredentials.isAuth && to.matched[0].path === '/signin') {
      next({
        path: '/customers'
      })
    } else {
      next() // make sure to always call next()!
    }
  }
})
export default router
