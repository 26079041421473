import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userCredentials: {
      email: '',
      name: '',
      sub: 0,
      prv: 'anonymous',
      isAuth: false,
      token: null
    },
    employerCredentials: {
      employer_id: 0,
      token: '',
      name: ''
    }
  },
  mutations: {

  },
  actions: {
  },
  getters: {

  },
  setters: {

  }
})
