<template>
    <v-layout v-model="showContainer">
      <v-flex>
  <v-toolbar dense height="28" flat color="blue-grey lighten-5">
      <v-toolbar-title>
        <v-tooltip right>
          <v-btn @click="$router.back()" class="grey mr-3" icon slot="activator"><v-icon color="white">arrow_back</v-icon></v-btn>
          <span>Powrót do poprzedniej strony</span>
          </v-tooltip><v-icon>description</v-icon> Wszystkie dokumenty</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
       <v-spacer></v-spacer>
      <v-tooltip left>
          <v-btn class="mb-2 success" @click="downloadfiles()" :loading="downloading" slot="activator" :disabled="selected.length === 0" icon><v-icon>print</v-icon></v-btn>
          <span>Pobierz zaznaczone dokumenty do druku</span>
      </v-tooltip>
    </v-toolbar>

        <!--vue-chartist :data="chartData" :options="options" type="Bar" class="pa-0 ma-0"></vue-chartist-->

    <v-card class="pa-0 mt-2 elevation-0">
      <v-card-text>
              <v-layout row wrap>
          <v-flex xs12 sm5 md5>
      <v-text-field
          class="mt-3 elevation-0"
          v-model="search"
          @change="selected = []"
          append-icon="search"
          label="Wyszukaj dokument po dowolnej kolumnie w tabeli..."
          :disabled="loadingItems"
          outline></v-text-field>
      </v-flex>
      <v-flex xs12 sm5 md5>
                 <v-select
          v-model="selected_types"
          :items="documents_types"
          :menu-props="{ maxHeight: '150' }"
          label="Rodzaje dokumentów"
          multiple
          item-text="state"
          item-value="abbr"
          :disabled="loadingItems"
          class="ml-5"
          @click="this.blur()"
          @change="initialize()"
          hint="Filtruj wyświetlanie dokumentów według ich rodzaju"
          persistent-hint></v-select>
      </v-flex>
      <v-flex xs12 sm2 md2>
        <v-btn @click="archive = !archive; initialize()" class="ml-3 mt-1" color="primary" :outline="!archive">Aktualne</v-btn>
        <v-btn @click="archive = !archive; initialize()" class="ml-3 mt-1" color="primary" :outline="archive"> Archiwum</v-btn>
      </v-flex>
    </v-layout>
      </v-card-text>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="clientsItems"
      select-all
      :loading="loadingItems"
      :search="search"
      :rows-per-page-items="[30,40,50,60,70,80,90,120,150,200]"
      rows-per-page-text="Liczba wierszy na stronę"
      :sort-by='created_at'
      :sort-desc=false
      item-key="documents_id"
      v-model="selected"
      class="elevation-1 mt-1"
    >
    <template slot="items" slot-scope="props">
      <tr :active="props.selected">
      <td>
       <v-checkbox
            v-model="props.selected" :input-value="props.selected" primary hide-details></v-checkbox>
        </td>
        <td class="text-xs-left table-row-nowrap body-2"><!--v-icon color="grey" small flat class="mr-1">description</v-icon-->
        <a :href="$ROOT_PATH + '/documents/pdf/' + props.item.documents_id" rel="noopener" target="_blank">{{ props.item.number }}</a></td>
        <td class="text-xs-left table-row-nowrap"><v-icon color="grey" class="mr-2" small flat>calendar_today</v-icon> {{ props.item.created_at }}
            <v-btn color="red" v-if="!archive && !loadingItems" small icon @click.prevent="deleteDocument(props.item.documents_id)" ><v-icon icon color="white" small>delete</v-icon></v-btn> </td>
        <td class="text-xs-left table-row-nowrap"><strong>{{ props.item.allowed_name.toUpperCase() }}</strong></td>
        <td class="text-xs-left">{{ props.item.allowed_scope }}</td>
        <td class="text-xs-left">
        <i><a :href="'/#/customers/edit/' + props.item.customers_id">{{ props.item.ado }}</a></i>

        </td>
        </tr>
      </template>
      <template slot="no-data">
      {{ this.loadingItemsText }}
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning" class="pa-1">
        Nie znaleziono dokumentu pasującego do frazy "{{ search }}"
      </v-alert>
      <template slot="pageText" slot-scope="props">
         {{ props.pageStart }} - {{ props.pageStop }} z {{ props.itemsLength }}
      </template>
    </v-data-table>
                 <v-tooltip left>
                <v-btn
                    v-model="offsetTop"
                    color="green"
                    @click="downloadfiles()"
                    dark fixed icon large bottom right
                    slot="activator"
                    :loading="downloading"
                    :disabled="selected.length === 0">
                    <v-icon>print</v-icon>
                </v-btn>
                <span>Pobierz zaznaczone dokumenty do druku</span>
             </v-tooltip>
    </v-flex>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.type"
      :timeout="4000"
    >
      {{ snackbar.text }}
      <v-btn
        dark
        flat
        @click="snackbar.show = false"
      >
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="showDocument"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable>

    <v-card dark>

      <v-card-text>
        <v-layout>
        <v-flex md10>

      </v-flex>
      <v-flex md2>
        <v-btn
        @click="showDocument = false"
        v-show="true"
        color="red"
        dark>
        <v-icon>close</v-icon>
        Zamknij podgląd dokumentu
      </v-btn>
      </v-flex>
    </v-layout>
        <iframe id="main-iframe" ref="mainiframe" style="width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 2; margin-top:50px; border: none;"></iframe>
      </v-card-text>
    </v-card>

    </v-dialog>
  </v-layout>
</template>

<script>

import axios from 'axios'
import VueChartist from 'v-chartist'

export default {
  data: () => ({
    loadingTime: '0s. 0ms.',
    showDocument: false,
    showContainer: false,
    search: '',
    snackbar: {
      text: '',
      show: false,
      color: 'info'
    },
    options: {
    },
    offsetTop: 0,
    selected_types: ['ZA', 'KL', 'UP'],
    documents_types: [
      { state: 'Zaświadczenia', abbr: 'ZA' },
      { state: 'Upoważnienia', abbr: 'UP' },
      { state: 'Klauzule', abbr: 'KL' }
    ],
    selected: [ 'Zaświadczenia' ],
    loadingItemsText: '',
    loadingItems: true,
    dialog: false,
    downloading: false,
    archive: false,
    headers: [

      {
        text: 'Numer dokumentu',
        align: 'left',
        value: 'number',
        sortable: false
      },
      {
        text: 'Wystawiony dnia',
        value: 'created_at',
        sortable: false

      },
      {
        text: 'Wystawiony dla',
        value: 'allowed_name',
        sortable: false
      },
      {
        text: 'Zakres szkolenia',
        value: 'scope',
        sortable: false
      },
      {
        text: 'Aministrator danych osobowych',
        value: 'ado',
        sortable: false
      }
    ],
    clientsItems: [],
    data: {
      labels: [],
      series: [],
      datasets: [
        {
          label: '',
          backgroundColor: '#f87979',
          data: []
        }
      ]
    },
    dsData: []
  }),
  created () {
    this.initialize()
  },
  mounted () {
    this.showContainer = true
  },
  components: {
    'vue-chartist': VueChartist
  },
  computed: {
    filteredItems () {
      return this.clientsItems.filter((i) => {
        return !this.type || (this.selected_types.indexOf(i.type) > 0)
      })
    },
    chartData: function () {
      var td = {
        labels: [],
        datasets: [],
        series: [[]]
      }
      var d = new Date()
      var passedList = this.clientsItems.filter((e) => e.type === 'UP')
      for (var i = 0; i < 90; i++) {
        d.setDate(d.getDate() - 1)
        var day = d.getDate()
        var month = d.getMonth() + 1
        day = String(parseInt(day) < 10 ? '0' : '') + day
        month = String(parseInt(month) < 10 ? '0' : '') + month
        var passedCount = passedList.filter(e => (e.created_at ? e.created_at.substr(5, 5) : '-1') === month + '-' + day).length
        td.labels.push(day)
        td.series[0].push(passedCount)
        td.datasets.push({
          label: day,
          backgroundColor: 'blue',
          data: passedCount
        })
      }
      return td
    }
  },
  methods: {
    showDocumentDlg (url) {
      this.$refs.mainiframe.src = url
      this.showDocument = true
    },
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.clientItems.slice()
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    translete_documents_type (type) {
      for (var i = 0; i < this.documents_types.length; i++) {
        if (this.documents_types[i].state === type) { return this.documents_types[i].abbr }
      }
    },
    check_documents_type_selected (type) {
      for (var i = 0; i < this.selected_types.length; i++) {
        if (this.selected_types[i] === type) { return true }
      }
      return false
    },
    deleteDocument (documentsId) {
  
      axios({
        url: this.$ROOT_PATH + '/document/delete/' + documentsId,
        method: 'get',
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      })
        .then(response => {    
          this.clientsItems = this.clientsItems.filter((i) => i.documents_id !== documentsId)
        })
        .catch(error => {
          console.log(error)
        })
    },
    showSnakbar (text, type) {
      this.snackbar = { show: false }
      this.snackbar = { show: true, text: text, type: type }
    },
    onScroll (e) {
      this.offsetTop = e.target.scrollTop
    },

    initialize () { // https://szkolenia.odosc.pl/v1/doc/KL,UP,ZA/0
      this.loadingItems = true
      this.clientItems = []
      axios({
        url: this.$ROOT_PATH + '/documents/' + this.selected_types.join(',') + '/' + (this.archive ? 'archive' : 'latest'),
        method: 'get',
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      }).then(response => {
        this.loadingItemsText = 'Brak danych do wyświetlenia'
        //        var pl_map = [ 'ęóąśłżźćńĘÓĄŚŁŻŹĆŃ', 'eoaslzxcnEOASLZXCN' ]
        //        {"an":"Kozo\u0144 \u0141ukasz","ap":"asystent","did":12217,"t":"UP","cid":89,"ca":"20230407","a":"f57d7","s":"198","tn":"RMdpPYDzQ4ibzY332WCJ0hqmH38EvKSpp3O","n":""},
        var ados = response.data.ados
        var scopes = response.data.scopes
        var data = response.data.documents
        // .replace('\r', '')
        /*        var rows = data.match(/\n/ig || []).length
          var ds = []
          var chunks = 300
          for (var c = 0; c < rows; c += chunks) {
            let ds_csv = String(data).split('\n', chunks)
            data = data.replace(ds_csv.join('\n'), '')
            console.log(ds_csv.length)
            for (let m = 0; m < ds_csv.length; m++) {
              console.log(String(ds_csv[m]))
              if (String(ds_csv[m]).length > 5) {
                let r = String(ds_csv[m]).split('$')
                ds.push({   an: r[0], ap: r[1], did: r[2],  t: r[3], cid: r[4],
                            ca: r[5],  a: r[6],   s: r[7], tn: r[8],   n: r[9]
                })
              }
            }
          } */
        var ds = data
        this.clientsItems = []
        // console.log(ds.length)
        for (var i = 0; i < ds.length; i++) {
          var d = ds[i].ca
          let scope = scopes.find((s) => s.h === ds[i].s)
          //let ado = ados.find((a) => a.h == ds[i].a)
          //console.log('Ado: ' + ado.a )
          //console.log(ds[i].an + ' ' + ds[i].did)
          this.clientsItems.push({
            number: ds[i].n === '' ? ds[i].t + '/' + ds[i].did : ds[i].n,
            allowed_name: ds[i].an,
            allowed_position: ds[i].ap,
            allowed_scope: (typeof scope.s !== 'undefined' ? scope.s : ''),
            ado: ds[i].ado, // (ado.a ? ado.a : ''),
            created_at: d.substr(6, 2) + '-' + d.substr(4, 2) + '-' + d.substr(0, 4),
            documents_id: ds[i].did,
            customers_id: ds[i].cid,
            token: (typeof ds[i].tn !== undefined ? ds[i].tn : '')
          })
        }
        // this.clientsItems = docs
      })
        .catch(error => {
          this.loadingItemsText = 'Błąd wczytywania danych'
          console.log(error)
        })
        .then(() => {
          this.loadingItems = false
        })
    },

    /* initialize () {  // https://szkolenia.odosc.pl/v1/doc/KL,UP,ZA/0
      this.loadingItems = true
      this.clientItems = []
      axios({
        url: this.$ROOT_PATH + '/documents/' + this.selected_types.join(',') + '/' + (this.archive ? 'archive' : 'latest'),
        method: 'get',
        headers: {
          'X-API-Token': this.$store.state.userCredentials.token
        }
      })
        .then(response => {
          this.loadingItemsText = 'Brak danych do wyświetlenia'
          this.clientsItems = response.data
        })
        .catch(error => {
          this.loadingItemsText = 'Błąd wczytywania danych'
          console.log(error)
        })
        .then(() => {
          this.loadingItems = false
        })
    }, */
    downloadfiles () {
      this.downloading = true
      this.showSnakbar('Pobieranie dokumentów...', 'success')
      var tokens = ''
      var c = 0
      for (var i = 0; i < this.selected.length; i++) {
        if (false && typeof this.selected[i].token !== 'undefined' && this.selected[i].token !== '') {
          tokens += (c > 0 ? ',' : '') + this.selected[i].token
          c++
        } else {
          tokens += (c > 0 ? ',' : '') + this.selected[i].documents_id
          c++
        }
      }
      axios({
        url: this.$ROOT_PATH + '/documents/pdf', // your url
        method: 'POST',
        responseType: 'blob', // important
        headers: {
          'Accept': 'application/octet-stream, application/json, text/plain, */*',
          'Access-Control-Allow-Origin': '*'
        },
        data: { token: tokens }
      }).then((response) => {
        var fileDownload = require('js-file-download')
        fileDownload(response.data, 'dokumenty_do_druku_' + (new Date()).toLocaleDateString() + '.pdf')
        this.downloading = false
      }).catch(error => {
        this.downloading = false
        console.log(error)
      })
    }
  }
}

</script>

<style scoped>
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke:#1E88E5 !important;
}.table-row-nowrap {
      white-space: nowrap;
}

</style>
