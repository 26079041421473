<template>

  <div>
    <v-snackbar
      v-model="snackbar.show"
      color="error"
      top
    > {{ snackbar.text }}
    </v-snackbar>
  <v-card
    class="mx-auto"
    max-width="500"
  >
      <div class="mx-auto" style="max-width:350px">
   <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kancelaria Bezpieczeństwa" width="350" />
    </div>
    <v-card-title class="title font-weight-regular grey--text justify-space-between text-xs-center">
      <span>{{ currentTitle }}</span>

    </v-card-title>
 <v-form ref="form" v-model="valid" lazy-validation>
    <v-window v-model="step">
      <v-window-item :value="0">
        <v-divider></v-divider>
      <v-card-text>
        <v-select
        v-model="language"
        :items="languagesItems"
        :label="translation.labelLanguage"
        item-text="state"
        item-value="abbr"
        prepend-icon="language"
      >
      <template slot="selection" slot-scope="{ item, parent, selected }">
        <v-list-tile-content>
            <span class="title">{{ item.state }}</span>
        </v-list-tile-content>
        <v-spacer></v-spacer>
        <country-flag :country="(item.abbr.toLowerCase() === 'en' ? 'gb' : item.abbr)" shadow class="ma-0" />
      </template>
      <template slot="item" slot-scope="{ index, item }">
        <v-list-tile-content>
            <span class="title">{{ item.state }}</span>
        </v-list-tile-content>
        <v-spacer></v-spacer>
        <country-flag :country="(item.abbr.toLowerCase() === 'en' ? 'gb' : item.abbr)" shadow class="ma-0" />
      </template>
    </v-select>
      </v-card-text>
    </v-window-item>
      <v-window-item :value="1">
          <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="employer.email"
            name="emp_email"
            :label="translation.labelLogin"
            value=""
            required
            prepend-icon="contact_mail"
          ></v-text-field>
          <span class="caption grey--text text--darken-1">
            {{ translation.hintEmail }}
          </span>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
          <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="employer.password"
            :label="translation.labelPassword"
            name="emp_password"
            :append-icon="passwordShow ? 'visibility_off' : 'visibility'"
            :rules="passwordRules"
            :type="passwordShow ? 'text' : 'password'"
            mask
            required
            prepend-icon="lock"
            @click:append="passwordShow = !passwordShow"
          ></v-text-field>
          <span class="caption grey--text text--darken-1">
            {{ translation.hintPassword }}
          </span>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="3">
          <v-divider></v-divider>
        <div class="pa-3 text-xs-center">
            <v-icon color="green darken-2" class="mb-3 large">check_circle</v-icon>
          <h3 class="title text--green mb-2">Autoryzacja powiodła się!</h3>
          <span class="caption">Za chwilę zostaniesz przekierowany/-a do szkolenia.</span>
        </div>
      </v-window-item>
    </v-window>
 </v-form>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="step === 0 || step === 3"
        flat @click="step--">{{ translation.returnBtn }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="step < 2"
        color="primary" depressed @click="nextStep"
      >{{ translation.forwardBtn }}
      </v-btn>

      <v-btn
        v-if="step === 2"
        color="primary" depressed
        @click="identifyEmployerWithPassword">
        {{ translation.confirmIdentBtn }}
      </v-btn>
    </v-card-actions>
  </v-card>
  </div>
</template>

<script>
import axios from 'axios'

var _paq = window._paq = window._paq || []
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(['trackPageView'])
_paq.push(['enableLinkTracking']);
(function () {
  var u = '//stats11.mydevil.net/'
  _paq.push(['setTrackerUrl', u + 'piwik.php'])
  _paq.push(['setSiteId', '359'])
  var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0]
  g.async = true; g.src = u + 'piwik.js'; s.parentNode.insertBefore(g, s)
})()

export default {
  data: () => ({
    languagesItems: [
      { state: 'Polski', abbr: 'PL' },
      { state: 'English', abbr: 'EN' }
    ],
    language: 'PL',
    step: 0,
    valid: false,
    employer: {
      email: '',
      password: ''
    },
    snackbar: {
      show: false,
      text: ''
    },

    i18n: {
      pl: {
        labelLanguage: 'Wersja językowa / Language',
        labelLogin: 'Adres e-mail',
        labelPassword: 'Hasło',
        hintEmail: 'Login osoby przystępującej do szkolenia (otrzymany od pracodawcy)',
        hintPassword: 'Hasło autoryzujące szkolenie (otrzymane od pracodawcy)',
        titleChooseLanguage: 'Wybierz język / Choose language',
        titleAttemptingPerson: 'Osoba przystępująca do szkolenia',
        titlePasswordRequired: 'Hasło autoryzujące',
        snackCredentialsRequired: 'Podaj adres e-mail oraz hasło.',
        snackNoCoursesAsigned: 'Aktualnie żadne szkolenie nie jest przypisane do tego adresu e-mail. Prosimy poinformować pracodowacę jeżeli podany adres e-mail jest prawidłowy.',
        snackWrongCredentials: 'Podano błędny login i/lub hasło',
        passwordRequired: 'Hasło jest wymagane do autoryzacji',
        passwordRequirments: 'Hasło powinno zawierać minimum 6 znaków, jedną literę (małą i dużą) oraz liczbę',
        returnBtn: 'Wstecz',
        forwardBtn: 'Dalej',
        confirmIdentBtn: 'Potwierdź tożsamość'
      },
      en: {
        labelLanguage: 'Language / Wersja językowa',
        labelLogin: 'E-mail address',
        labelPassword: 'Password',
        hintEmail: 'Login of person taking the training (given by employer)',
        hintPassword: 'Course authorization password (given by employer)',
        titleChooseLanguage: 'Choose language / Wybierz język',
        titleAttemptingPerson: 'Person who takes the training',
        titlePasswordRequired: 'Authorization password',
        snackCredentialsRequired: 'E-mail address and password is required',
        snackNoCoursesAsigned: 'Currently there is no training asigned to this e-mail address. If given e-mail address is correct please inform your employer about it.',
        snackWrongCredentials: 'Wrong e-mail address and/or password has been given',
        passwordRequired: 'Password is needed for authorization',
        passwordRequirments: 'Password should contain at least 6 characters, one letter (small and big), a number',
        returnBtn: 'Back',
        forwardBtn: 'Next',
        confirmIdentBtn: 'Confirm identity'
      }
    },

    passwordShow: false,
    passwordRules: [
      v => !!v || this.translation.passwordRequired,
      v => (/[0-9]+/.test(v) && /[a-z]+/.test(v) && /[A-Z]+/.test(v)) || this.translation.passwordRequirments
    ]
  }),

  computed: {
    currentTitle () {
      switch (this.step) {
        case 0: return this.translation.titleChooseLanguage
        case 1: return this.translation.titleAttemptingPerson
        case 2: return this.translation.titlePasswordRequired
        default: return 'Zakończono!'
      }
    },
    translation: function () {
      if (this.language.toLowerCase() === 'pl') {
        return this.i18n.pl
      } else {
        return this.i18n.en
      }
    }
  },

  methods: {

    identifyEmployerWithPassword: function () {
      axios({
        url: this.$ROOT_PATH + '/auth/ident',
        method: 'post',
        data: {
          email: this.employer.email,
          password: this.employer.password
        }
      })
        .then(response => {
          this.$store.state.employerCredentials = {
            employer_id: response.data.employer_id,
            customers_id: response.data.customers_id,
            token: response.data.token,
            name: response.data.name,
            courses_id: response.data.courses_id,
            courses_ext_id: response.data.courses_ext_id,
            language: this.language
          }
          this.$router.push('/course/' + response.data.courses_id)
        })
        .catch(error => {
          switch (error.response.status) {
            case 422:
              this.snackbar.text = this.translation.snackCredentialsRequired
              break
            case 410:
              this.snackbar.text = this.translation.snackNoCoursesAsigned
              break
            case 400:
              this.snackbar.text = this.translation.snackWrongCredentials
              break
            default:
              this.snackbar.text = error.response.status + ' - ' + error.response.statusText
              break
          }
          this.snackbar.show = true
        })
    },

    nextStep: function () {
      if (this.step === 2) {
        this.step = 0
      } else {
        this.step++
      }
    }
  }
}
</script>
