<template>
<v-layout fill-height column>
    <v-flex>
         <v-progress-linear v-if="loading"
        height="2"
      color="primary"
      indeterminate
    ></v-progress-linear>
 <div id="content-top">
<div style="text-align:center;margin-bottom:30px;">
      <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kancelaria Bezpieczeństwa" width="320" class="logo" />
</div>
<v-stepper v-model="e1" class="pa-0" vertical>
       <v-stepper-item v-for="n in steps" :key="n" :name="'n-'+ n" :id="'content-' + n" class="ma-0">
           <v-stepper-step
             :key="`${n}-step`"
             :step="n"
             :complete="e1 > n"
           >
            <span class="font-weight-bold"> {{ coursePagesFiltred[n-1].title  }} </span>  <v-divider></v-divider>
           </v-stepper-step>
           <v-divider></v-divider>
          <v-stepper-content
           :step="n"
           :key="`${n}-content`"
         >
        <v-card class="ma-0 mb-5 elevation-0" color="transparent">
        <v-card-title primary-title color="transparent">
                </v-card-title>
            <v-card-text class="course-content-text" style="text-align:justify;text-justify:inter-word;">
                <span class="font-weight-normal" v-html="coursePagesFiltred[n-1].text"></span>
                <br/>
                    <div v-for="claim in getPageClaims(coursePagesFiltred[n-1].courses_pages_id)" :key="claim.course_claim_id" >
                    <v-checkbox
                        class="ma-0 pa-0" hide-details @click.native="answer" :prepend-icon="courseAnswered ? (((typeof claim.answer === 'undefined' ? false : claim.answer) != claim.is_true) ? 'close' : 'check') : ''"
                        v-model="claim.answer"
                        :key="claim.course_claim_id"
                        :label="claim.claim"
                        :disabled="courseAnswered"
                        required
                        ></v-checkbox>
                    </div>

                    <div class="mt-2">
        <v-btn v-if="n < steps && (courseAnswered || getPageClaims(coursePagesFiltred[n-1].courses_pages_id).length == 0)" color="primary" @click="nextStep(n)" transition="slide-x-reverse-transition">{{ translation.nextPage }}<v-icon class="ml-2">play_circle_filled</v-icon></v-btn>
        <v-btn v-if="n <= steps && (!courseAnswered && getPageClaims(coursePagesFiltred[n-1].courses_pages_id).length > 0)" @click="checkAnswers" class="primary" transition="slide-x-reverse-transition"> {{ translation.checkAnswers }} <v-icon class="ml-2">question_answer</v-icon></v-btn>
        <v-btn v-if="n === steps && (courseAnswered || getPageClaims(coursePagesFiltred[n-1].courses_pages_id).length == 0) " color="primary" @click="checkResoult(); dialogFinish = true">{{ translation.finishCourse }}<v-icon class="ml-2">play_circle_filled</v-icon></v-btn>
                    </div>

                </v-card-text>
          </v-card>

         </v-stepper-content>
     </v-stepper-item>
</v-stepper>

      <v-dialog
        v-model="dialogWelcome"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
      <v-card  class="align-center justify-center courseIntroContent" v-if="this.$store.state.employerCredentials.language.toLowerCase() === 'pl'">

             <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kancelaria Bezpieczeństwa" width="350" class="logo" />

             <span class="headline mt-4">Witaj {{ $store.state.employerCredentials.name }} !</span>
             <p class="mt-4" style="max-width:600px;text-align:center">Przystępujesz do szkolenia z zakresu:<br/> <strong>{{ course.scope }}</strong>. <br/><Br/>Szkolenie zostanie zakończone egzaminem,
                 na podstawie którego zostanie wydane Tobie zaświadczenie ukończenia szkolenia
                  oraz upoważnienie do przetwarzania danych osobowych.<br/><Br/> Podczas szkolenia należy zaznaczyć poprawne twierdzenia sprawdzające. Możesz zaznaczyć więcej niż jedno twierdzenie. <br/><Br/>
                   Szkolenie możesz powtórzyć wielokrotnie przed przystąpieniem do egzaminu. <br/><Br/><strong>Czytaj uważnie!</strong>
                  </p>
                  <p style="max-width:600px;text-align:center" class="align-center text-align-right">
 <v-btn class="success" @click="dialogWelcome = false">Zaczynamy! <v-icon class="ml-2">play_circle_filled</v-icon></v-btn>
                      </p>

      </v-card>
      <v-card  class="align-center justify-center courseIntroContent" v-if="this.$store.state.employerCredentials.language.toLowerCase() !== 'pl'">

        <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kancelaria Bezpieczeństwa" width="350" class="logo" />

        <span class="headline mt-4">Hello {{ $store.state.employerCredentials.name }} !</span>
        <p class="mt-4" style="max-width:600px;text-align:center">You are starting training in:<br/> <strong>{{ course.scope }}</strong>. <br/><Br/>The training will be completed with an exam,
          on the basis of which a training completion certificate will be issued to you
           with authorization to process personal data.<br/><Br/> During the training, check the correct answers. You can tick more than one statement. <br/><br/>
            You can repeat the training many times before taking the exam. <br/><br/><strong>Read carefully!</strong>
           </p>
           <p style="max-width:600px;text-align:center" class="align-center text-align-right">
<v-btn class="success" @click="dialogWelcome = false">Let's go! <v-icon class="ml-2">play_circle_filled</v-icon></v-btn>
                 </p>

 </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFinish"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
      <v-card  class="align-center justify-center courseBackground">

        <img src="https://szkolenia.odosc.pl/assets/logo.2.png" alt="Kancelaria Bezpieczeństwa" width="350" class="mb-3 logo"/>

        <h1 class="headline mt-4">{{ translation.courseFinished }}</h1>
        <!--p class="mt-4" style="max-width:600px;text-align:center">Twoje szkolenie zostało zakończone</p-->
           <p v-if="typeof resoult != NaN" style="max-width:600px;text-align:center;color:green" class="align-center text-align-right mb-6">
              {{ translation.youGained }}<br/>
               <span style="" class="display-4">{{ resoult }}%</span> <br/>
              {{ translation.ofCorrectAnswers }}
           </p>
             <p style="max-width:600px;text-align:center" class="align-center text-align-right">
<!--vue-chartist :data="data" :options="options" type="Bar"></vue-chartist-->
<span v-if="this.$store.state.employerCredentials.courses_ext_id > 0 && this.$store.state.employerCredentials.courses_ext_id !== course.courses_id">
  {{ translation.extendedCourseInfo }}
</span><br/>
               <v-btn @click="repeatCourse(course.courses_id); dialogFinish=false; dialogWelcome = true" color="primary">{{ translation.repeatCourseBtn }}<v-icon class="ml-2">play_circle_filled</v-icon></v-btn>

              <v-btn v-if="(this.$store.state.employerCredentials.courses_ext_id < 1 || this.$store.state.employerCredentials.courses_ext_id === course.courses_id)"
               :to="'/egzam/' + this.$store.state.employerCredentials.token + '/' + this.$store.state.employerCredentials.language" color="success">{{ translation.startEgzamBtn }}<v-icon class="ml-2">play_circle_filled</v-icon></v-btn>
               <v-btn v-if="this.$store.state.employerCredentials.courses_ext_id > 0 && this.$store.state.employerCredentials.courses_ext_id !== course.courses_id"
                  @click="repeatCourse($store.state.employerCredentials.courses_ext_id); dialogFinish=false; dialogWelcome = true" color="success">{{ translation.startExtendedCourseBtn }}<v-icon class="ml-2">play_circle_filled</v-icon></v-btn>
                 </p>

 </v-card>
      </v-dialog>

  </div>
  <v-dialog
  v-model="loading"
  persistent
  width="300"
>
  <v-card
    color="primary"
    dark
  ><v-card-text>
      Ładowanie szkolenia... proszę czekać.
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      ></v-progress-linear>
    </v-card-text>
  </v-card>
</v-dialog>
    </v-flex>
</v-layout>

</template>

<script>

import axios from 'axios'
import VueChartist from 'v-chartist'
import '../../node_modules/chartist/dist/chartist.min.css'

export default {

  data: () => ({

    i18n: {
      en: {
        courseFinished: 'Course finished.',
        youGained: 'You gained',
        ofCorrectAnswers: 'of correct answers',
        extendedCourseInfo: 'Your base course is finished! Due to the industry of your employer, we also ask you to complete an extension course before taking the exam.',
        startEgzamBtn: 'Start egzam',
        repeatCourseBtn: 'Repeat course',
        startExtendedCourseBtn: 'Start extended course',
        nextPage: 'Next page',
        checkAnswers: 'Check answers',
        finishCourse: 'Finish course'
      },
      pl: {
        courseFinished: 'Szkolenie zakończone.',
        youGained: 'Uzyskałeś/-aś',
        ofCorrectAnswers: 'poprawnych odpowiedzi',
        extendedCourseInfo: 'Szkolenie podstawowe zostało ukończone! Ze względu na branżę Twojego pracodawcy prosimy również o odbycie szkolenia rozszerzającego przed przystąpieniem do egzaminu.',
        startEgzamBtn: 'Rozpocznij egzamin',
        repeatCourseBtn: 'Powtórz szkolenie',
        startExtendedCourseBtn: 'Rozpocznij szkolenie rozszerzające',
        nextPage: 'Przejdź dalej',
        checkAnswers: 'Sprawdź odpowiedzi',
        finishCourse: 'Zakończ szkolenie'
      }
    },

    resoult: 0,
    dialogWelcome: false,
    dialogFinish: false,
    courseDialog: false,
    course: { name: 'Ładowanie danych...', scope: 'Ładowanie danych...' },
    courseClaims: [],
    courseAnswered: false,
    coursePages: [],
    courseClaimsAll: [],

    e1: 1,
    steps: 10,
    courses_id: 0,
    loading: false
  }),
  mounted () {
    this.beginCourse(this.$route.params.id)
  },
  computed: {
    coursePagesFiltred: function () {
      return this.coursePages.filter((i) => i.language.toLowerCase() === this.$store.state.employerCredentials.language.toLowerCase())
    },
    translation: function () {
      if (this.$store.state.employerCredentials.language.toLowerCase() === 'pl') {
        return this.i18n.pl
      } else {
        return this.i18n.en
      }
    }
  },
  components: {
    'vue-chartist': VueChartist
  },
  methods: {
    beginCourse (course_id) {
      this.loading = true
      if (this.$store.state.employerCredentials.employer_id > 0) {
        this.dialogFinish = false
        this.dialogWelcome = true
        this.loadCoursePages(course_id)
      } else {
        this.$router.push('/identify')
      }
    },
    answer () {
      if (this.courseAnswered) {
        return false
      }
    },
    checkAnswers () {
      //  this.courseDialog = true
      this.courseAnswered = true
    },
    checkResoult () {
      let courseResoult = 0
      let allAnswers = 0
      this.coursePagesFiltred.forEach((p) => {
        this.courseClaimsAll.forEach((c) => {
          if (c.courses_pages_id === p.courses_pages_id) {
            courseResoult += (((typeof c.answer === 'undefined' ? false : c.answer) != c.is_true) ? 0 : 1)
            allAnswers++
          }
        })
      })
      this.resoult = parseInt(Math.floor((courseResoult / (allAnswers)) * 100))
    },

    loadCoursePages (courses_id) {
      this.loading = true
      console.log(this.loading)
      axios.all([
        axios({url: this.$ROOT_PATH + '/courses/id/' + courses_id, method: 'get' }),
        axios({url: this.$ROOT_PATH + '/courses/claims/' + courses_id, method: 'get' }),
        axios({url: this.$ROOT_PATH + '/courses/pages/' + courses_id, method: 'get' })])
        .then(axios.spread((firstResponse, secondResponse, thirdResponse) => {
          this.course = firstResponse.data
          this.courseClaimsAll = secondResponse.data
          this.coursePages = thirdResponse.data
          this.steps = this.coursePagesFiltred.length
          this.loading = false
          console.log(this.loading)
          this.stepChanged(this.e1)
        }))
        .catch(error => {
          this.loading = false
          this.showSnakbar('Błąd! Wczytanie szkolenia nie powiodło się.', 'error')
        })
    },
    nextStep (n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
      if (n > 0) { this.$scrollTo('#content-' + (n)) } else { this.$scrollTo('#content-top', { offset: -100 }) }
      this.stepChanged(this.e1)
    },
    getPageClaims (courses_pages_id) {
      return this.courseClaimsAll.filter((c) => c.courses_pages_id === courses_pages_id)
    },
    stepChanged (n) {
      let course = this.coursePages[n - 1]
      this.courseAnswered = false
      this.courseClaims = this.courseClaimsAll.filter((c) => c.courses_pages_id === course.courses_pages_id)
    },
    repeatCourse (courses_id) {
      this.loadCoursePages(courses_id)
      this.nextStep(0)
    }
  },

  watch: {
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    }
  }

}

</script>

<style>
body {
    overflow-y:hidden;
}
.logo {
    border:1px solid #eee;
/*    box-shadow:0px 0px 2px 3px #eee;
    padding:2px;*/
}

.container {
    padding:16px 0px !important;
}

.v-stepper {
    max-width:900px;
    margin:0 auto;
}
.courseIntroContent,
.courseBackground,
.v-stepper .v-stepper__content {
    margin:0px;
    padding:0px;
    background:url('https://a.odosc.pl/v1/assets/firm-paper2.png') top right no-repeat;background-size:100%;
}

.v-card__text p,
.course-content-text p {
    margin:0;
    padding:0;
}

@media only screen and (max-width: 600px) {
  .v-stepper .v-stepper__content img,
  .v-card__text p img,
  .course-content-text img {
      width:100% !important;
      max-width:900px !important;
  }
}

.v-stepper .v-stepper__content img,
.v-card__text p img,
.course-content-text img {
    margin:10px auto !important;
    border:1px solid #666 !important;
    max-width:800px;
}
.v-stepper__label {
    font-size:125%;
}

</style>
